import PropTypes from 'prop-types';
import { dataUnavailable, toNumber } from '@core/formatters';

export const ThreeDigitDecimalFormatter = ({ value, handleNegativeValues }) => {
  if (handleNegativeValues) {
    if (parseInt(value, 10) === -100) {
      return 'N/A';
    }
    if (parseInt(value, 10) === -101) {
      return 'N/A';
    }
  }
  return dataUnavailable(toNumber(value, 3));
};

ThreeDigitDecimalFormatter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleNegativeValues: PropTypes.bool,
};
