import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ChevronExpander } from '@common/chevron-expander';

import { SingleRadioButton } from '../single-radio-input';
import { isParentItem } from '../helpers';

import styles from './radio-group.module.scss';

export const RadioGroup = ({ name, option, value, onChange }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [groupAreaHeight, setGroupAreaHeight] = useState();
  const groupRef = useRef();

  useLayoutEffect(() => {
    const observer = new ResizeObserver((obj) => {
      const newHeight = obj[0].contentRect.height;
      setGroupAreaHeight(newHeight);
    });
    observer.observe(groupRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <div className={styles['parent']}>
        <ChevronExpander
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          label={option.label}
        />
      </div>
      <div
        className={styles['radio-group']}
        style={{ height: isExpanded ? groupAreaHeight : 0 }}
      >
        <div ref={groupRef} className={styles['group-area']}>
          {option.children.map((child) =>
            isParentItem(child) ? (
              <RadioGroup
                key={child.value}
                option={child}
                value={value}
                onChange={onChange}
              />
            ) : (
              <SingleRadioButton
                key={child.value}
                name={name}
                option={child}
                value={value}
                onChange={onChange}
              />
            )
          )}
        </div>
      </div>
    </>
  );
};

const optionPropTypes = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.array,
});

RadioGroup.propTypes = {
  name: PropTypes.string,
  option: optionPropTypes.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};
