import { paramsStorage } from '@core/browser-storage';
import * as types from '@redux/actions/action-types';
import initialState from './initial-state';

export default function paramsReducer(state = initialState.params, action) {
  let newState;
  switch (action.type) {
    case types.PARAMS_SET:
      newState = {
        ...state,
        ...action.params,
      };
      paramsStorage.setParams(newState);
      return newState;

    case types.PARAMS_CLEAN:
      paramsStorage.removeParams();
      return {
        ...initialState.params,
      };

    case types.FORM_PARAMS_SET:
      newState = {
        ...state,
        formParams: {
          ...action.formParams,
        },
      };
      paramsStorage.setParams(newState);
      return newState;

    case types.ACTIVE_COLUMNS_SET:
      newState = {
        ...state,
        activeCols: {
          ...action.activeCols,
        },
      };
      paramsStorage.setParams(newState);
      return newState;

    case types.COLUMN_ORDER_SET:
      newState = {
        ...state,
        columnOrder: [...action.columnOrder],
      };
      paramsStorage.setParams(newState);
      return newState;

    default:
      return state;
  }
}
