import { WINDOW_SIZES } from '@core/constants';
import { getWindowSize } from '@core/layout';

const windowSize = getWindowSize();

export default {
  companies: {
    byId: {},
  },

  programs: {
    byId: {},
  },

  request: {
    pendingRequests: [],
  },

  cache: {},

  cacheLoading: {},

  params: {
    formParams: {},
  },

  userData: {
    roles: [],
    savedFunds: [],
    savedSearches: [],
  },

  layout: {
    windowSize,
    sidebarCollapsed: windowSize === WINDOW_SIZES.XS,
  },
};
