import React from 'react';

import { InlineMessageBlock } from '@backstop/react-core';
import { Link } from 'react-router-dom';

import { useSearchData } from '~/api/search';
import { useSearchParams } from '@redux/search-params';
import { PAGE_SIZE_OPTIONS } from '@core/constants';
import { isEmpty } from '@core/validators';

import { Table } from '@common/table';
import { Loader } from '@common/loader';
import { SearchQueryChips } from '@common/search-query-chips';
import { PageHeader } from '~/components/headers/page-header';

import { useChipRemoveHandlers, usePagination, useSorting } from './hooks';
import { COLUMNS, defaultSortingState } from './columns';

import styles from './search-results.module.scss';

const DEFAULT_PAGE_SIZE = PAGE_SIZE_OPTIONS[50];

// Currently sorting only works for the name column
const allowSortingFor = [COLUMNS.name.value];

export const SearchResult = () => {
  // TODO: Account for new columns added and old ones removed relative to the saved columnOrder value
  // This might be achieved through the params sate versions
  const { formParams, columnOrder, setColumnOrder, activeColumns } =
    useSearchParams();

  const { handleChipRemove, handleCustomFilterChipRemove, handleRemoveAll } =
    useChipRemoveHandlers(formParams);

  const { pageSize, setPageSize, currentPage, setCurrentPage } =
    usePagination(DEFAULT_PAGE_SIZE);

  const { sortedCols, setColumnSorting } = useSorting({
    setCurrentPage,
    defaultSortingState,
  });

  const { data, totalElements, loading, error } = useSearchData({
    formParams,
    pageSize: pageSize.value,
    currentPage,
    sortedCols,
  });

  let tempCols = [];
  for (const [key, value] of Object.entries(activeColumns)) {
    tempCols.push(value);
  }

  return (
    <div className={styles['body']}>
      {loading && <Loader />}

      {error && (
        <InlineMessageBlock kind="error">
          Something went wrong! Please try to update search parameters.
        </InlineMessageBlock>
      )}

      <PageHeader title="Search Results">
        <div className={styles['link']}>
          <Link to="/search">Edit search criteria</Link>
        </div>
      </PageHeader>
      {!isEmpty(formParams) && (
        <SearchQueryChips
          params={formParams}
          onChipRemove={handleChipRemove}
          onCustomFilterChipRemove={handleCustomFilterChipRemove}
          onRemoveAll={handleRemoveAll}
        />
      )}
      <Table
        allowSortingFor={allowSortingFor}
        sortedCols={tempCols}
        setColumnSorting={setColumnSorting}
        rows={data}
        formParams={formParams}
        totalElements={totalElements}
        isLoading={loading}
        showPagination={true}
        pageSize={pageSize}
        setPageSize={setPageSize}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        showColumnSelector={true}
        columnOrder={columnOrder}
        setColumnOrder={setColumnOrder}
      />
    </div>
  );
};
