import { isEmpty } from '@core/validators';
import { COMP_LOCATION_OPTIONS } from '@core/select-options';
import { FILTER_TYPES } from '@core/constants';

const joinList = (list) => (list && list.length > 0 ? list.join(',') : null);

const getBasicList = (params, fieldId, filterKey) => {
  if (isEmpty(params[fieldId])) return {};
  return { [filterKey]: { [FILTER_TYPES.in]: joinList(params[fieldId]) } };
};

export const FILTERS = {
  basicFiltersInclusion: function (params) {
    return { BASIC: { [FILTER_TYPES.JOIN]: params[this.id] } };
  },

  detailedFiltersInclusion: function (params) {
    return { DETAIL: { [FILTER_TYPES.JOIN]: params[this.id] } };
  },

  customFiltersInclusion: function (params) {
    return { CUSTOM: { [FILTER_TYPES.JOIN]: params[this.id] } };
  },

  // Search by Type

  programType: function (params) {
    return { type: { [FILTER_TYPES.equal]: params[this.id] } };
  },

  // Search by Name

  searchQuery: function (params) {
    return {
      compName: { like_start: params[this.id] },
      name: { like_start: params[this.id] },
      name2: { like_start: params[this.id] },
      NAME: { [FILTER_TYPES.JOIN]: 'OR' },
    };
  },

  isin: function (params) {
    return { isin: { [FILTER_TYPES.equal]: params[this.id] } };
  },

  // Basic Filters

  compState: function (params) {
    const filterValue = params[this.id];
    //if none or if all options are selected, apply no filters
    if (
      isEmpty(filterValue) ||
      filterValue.length === COMP_LOCATION_OPTIONS.length
    ) {
      return {};
    }

    //is uk selected
    const optionUkSelected =
      filterValue.filter((option) => option === 'UK').length > 0;

    //is usa selected
    const optionUsSelected =
      filterValue.filter((option) => option === 'NY,NJ,CT,MA').length > 0;

    const optionOtherSelected = filterValue.some((option) => option === '');

    let returnObj = {};
    if (optionUkSelected === true) {
      returnObj = {
        ...returnObj,
        compCountry: {
          [FILTER_TYPES.in]: 'UK',
        },
      };
    } else if (optionOtherSelected === true) {
      returnObj = {
        ...returnObj,
        compCountry: {
          [FILTER_TYPES.notIn]: 'UK',
        },
      };
    }

    if (optionUsSelected === true) {
      returnObj = {
        ...returnObj,
        compState: {
          [FILTER_TYPES.in]: 'NY,NJ,CT,MA',
        },
      };
    } else if (optionOtherSelected === true) {
      returnObj = {
        ...returnObj,
        compState: {
          [FILTER_TYPES.notIn]: 'NY,NJ,CT,MA',
        },
      };
    }

    return returnObj;
  },

  us: function (params) {
    return getBasicList(params, this.id, 'us');
  },

  // Strategy Filters

  ctaStrategies: function (params) {
    const strategies = params[this.id];
    const filters = {};
    strategies.forEach((strategy) => {
      filters[strategy] = { [FILTER_TYPES.equal]: 1 };
    });
    return filters;
  },

  ctaInclusion: function (params) {
    return { CTA_STRATEGY: { [FILTER_TYPES.JOIN]: params[this.id] } };
  },

  excludeProprietaryTradingAccounts: function () {
    return { proprietaryAccount: { [FILTER_TYPES.notEqual]: 1 } };
  },

  hfStrategies: function (params) {
    const strategies = params[this.id];
    const filters = {};
    strategies.forEach((strategy) => {
      filters[strategy] = { [FILTER_TYPES.equal]: 1 };
    });
    return filters;
  },

  hfInclusion: function (params) {
    return { HF_STRATEGY: { [FILTER_TYPES.JOIN]: params[this.id] } };
  },

  excludeManagedAccounts: function () {
    return { managedAccount: { [FILTER_TYPES.notEqual]: 1 } };
  },

  sec13f: function () {
    return { sec13f: { [FILTER_TYPES.equal]: 1 } };
  },

  // Detailed Filters

  openClosed: function (params) {
    return { open: { [FILTER_TYPES.equal]: params[this.id] } };
  },

  listedOnExchange: function (params) {
    return getBasicList(params, this.id, 'exchangeListing');
  },

  highwater: function (params) {
    return { highwater: { [FILTER_TYPES.equal]: params[this.id] } };
  },

  denomination: function (params) {
    return getBasicList(params, this.id, 'denomination');
  },

  geoFocus: function (params) {
    const filterValue = params[this.id];
    if (isEmpty(filterValue)) return {};

    let filter = {};
    const filterKey = 'geoFocus';
    const otherOption = filterValue.find((option) => option === '');
    if (otherOption !== undefined) {
      filter = { [filterKey]: { [FILTER_TYPES.blank]: true } };
    }

    const joinedList = joinList(
      filterValue.filter((option) => option !== otherOption)
    );

    if (joinedList) {
      if (filter[filterKey]) filter[filterKey][FILTER_TYPES.in] = joinedList;
      else filter = { [filterKey]: { [FILTER_TYPES.in]: joinedList } };
    }

    return filter;
  },

  reportingBasis: function (params) {
    return getBasicList(params, this.id, 'returnType');
  },

  // Custom filters

  customFilters: function (params) {
    const customFilters = params[this.id];
    if (isEmpty(customFilters)) return {};

    let filters = {};

    customFilters.forEach((customFiler) => {
      const { filter1, filter2, comparisonMethod, value1, value2 } =
        customFiler;

      const filterKey = filter1.vs
        ? `${filter1.value}Vs${filter2.value}`
        : filter1.value;

      let filterValue;

      if (comparisonMethod.value === 'between') {
        filterValue = {
          [FILTER_TYPES.greatherThanOrEqual]: value1,
          [FILTER_TYPES.lessThanOrEqual]: value2,
        };
      } else {
        filterValue = {
          [comparisonMethod.value]: value1,
        };
      }

      if (filters[filterKey]) {
        filters[filterKey] = {
          ...filters[filterKey],
          ...filterValue,
        };
      } else {
        filters = {
          ...filters,
          [filterKey]: filterValue,
        };
      }
    });

    return filters;
  },
};
