export const PAGE_SIZE_OPTIONS = {
  50: { value: 50, label: '50' },
  100: { value: 100, label: '100' },
  150: { value: 150, label: '150' },
  200: { value: 200, label: '200' },
  250: { value: 250, label: '250' },
  300: { value: 300, label: '300' },
};

export const PROGRAM_TYPES = {
  HedgeFund: 'HedgeFund',
  CTA: 'CTA',
};

export const PROGRAM_TYPE_LABELS_SHORT = {
  HedgeFund: 'HF',
  CTA: 'CTA',
};

export const PROGRAM_TYPE_LABELS_FULL = {
  HedgeFund: 'Hedge Fund',
  CTA: 'Commodity Trading Advisor Profile',
};

export const FILTER_TYPES = {
  equal: 'eq',
  notEqual: 'neq',
  in: 'in',
  notIn: 'nin',
  blank: 'blank',
  lessThanOrEqual: 'le',
  greatherThanOrEqual: 'ge',
  JOIN: 'JOIN',
};


export const SORT_DIRECTIONS = {
  ASC: 'ASC',
  DESC: 'DESC',
  NO_SORT: 'NO_SORT',
};

export const WINDOW_SIZES = {
  LG: '4',
  MD: '3',
  SM: '2',
  XS: '1',
};

export const WINDOW_SIZE_BREAKPOINTS = {
  [WINDOW_SIZES.LG]: 1920,
  [WINDOW_SIZES.MD]: 1280,
  [WINDOW_SIZES.SM]: 848,
};
