import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@backstop/react-core';
import { SEARCH_FIELDS } from '@core/search-fields';
import { WINDOW_SIZES } from '@core/constants';
import { useLayoutData } from '@core/layout';
import styles from './chip.module.scss';
import classnames from 'classnames';

const delay = { show: 500, hide: 200 };

export const Chip = ({ fieldKey, index, params, onRemove }) => {
  const { windowSize } = useLayoutData();
  const targetRef = useRef();
  const field = SEARCH_FIELDS[fieldKey];
  const { label, value } = field.useFormatter(params);
  const { chipDeleteDisabled } = field;

  const labelClasses = classnames(styles['label'], {
    [styles['label--no-remove']]: chipDeleteDisabled,
    [styles['label--truncate-lg']]: windowSize === WINDOW_SIZES.LG,
    [styles['label--truncate-md']]: windowSize === WINDOW_SIZES.MD,
    [styles['label--truncate-sm']]: windowSize === WINDOW_SIZES.SM,
    [styles['label--truncate-xs']]: windowSize === WINDOW_SIZES.XS,
  });

  return (
    <>
      <div ref={targetRef} className={styles['body']}>
        <div className={labelClasses}>
          <span className={styles['filter']}>{label}</span>
          {value && ': ' + value}
        </div>
        {!chipDeleteDisabled && (
          <FontAwesomeIcon
            className={styles['remove-icon']}
            onClick={() => onRemove(fieldKey, index)}
            icon={'times'}
          />
        )}
      </div>

      <Tooltip
        target={() => targetRef.current}
        version="v3"
        delay={delay}
        orientation="bottom"
      >
        {label}
        {value && ': ' + value}
      </Tooltip>
    </>
  );
};

Chip.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  index: PropTypes.number,
  params: PropTypes.object.isRequired,
  onRemove: PropTypes.func,
};
