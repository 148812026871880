// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".body--GyxrU{border-collapse:collapse;width:100%;border:1px solid #ddd}", "",{"version":3,"sources":["webpack://./src/components/common/basic-table/basic-table.module.scss"],"names":[],"mappings":"AAEA,aACE,wBAAA,CACA,UAAA,CACA,qBAAA","sourcesContent":["@import './src/theme/_variables';\n\n.body {\n  border-collapse: collapse;\n  width: 100%;\n  border: 1px solid $color-border-basic-table;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "body--GyxrU"
};
export default ___CSS_LOADER_EXPORT___;
