import PropTypes from 'prop-types';
import {
  toCurrency,
  dataUnavailable,
  toNumber,
  toThousands,
} from '@core/formatters';
import { isEmpty, isNumeric } from '@core/validators';

export const MoneyThousandsFormatter = ({ value, row }) => {
  if (isEmpty(value) || !isNumeric(value)) return dataUnavailable(value);

  if (parseFloat(value) === 0) return toCurrency(value, row.denominat);

  let valueInThousands = value;

  if (valueInThousands < 1) {
    valueInThousands = '<1';
  } else {
    valueInThousands = toNumber(valueInThousands, 0);
  }

  return dataUnavailable(
    toThousands(toCurrency(valueInThousands, row.denominat))
  );
};

MoneyThousandsFormatter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  row: PropTypes.object.isRequired,
};
