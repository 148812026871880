import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@backstop/react-core';
import { SORT_DIRECTIONS } from '@core/constants';
import styles from './custom-table-header.module.scss';

const SortUpIconSelected = () => (
  <Icon
    name="arrow-left"
    size="md"
    screenReaderText="Sort up icon selected"
    version="v3"
    className={styles['sort-up-icon-selected']}
  />
);
const SortUpIcon = () => (
  <Icon name="caret-up" size="md" screenReaderText="Sort up" version="v3" />
);
const SortDownIconSelected = () => (
  <Icon
    name="arrow-left"
    size="md"
    screenReaderText="Sort down icon selected"
    version="v3"
    className={styles['sort-down-icon-selected']}
  />
);
const SortDownIcon = () => (
  <Icon name="caret-down" size="md" screenReaderText="Sort down" version="v3" />
);

export const CustomTableHeader = ({
  column,
  sortedCols,
  setColumnSorting,
  allowSortingFor,
}) => {
  const sortingAllowed =
    !allowSortingFor || allowSortingFor.includes(column.name);

  const columnSortState = sortedCols.find(
    (col) => col.columnName === column.name
  );

  const handleSortAscClick = () => {
    if (columnSortState?.direction === SORT_DIRECTIONS.ASC) {
      setColumnSorting(column.name, SORT_DIRECTIONS.NO_SORT);
    } else {
      setColumnSorting(column.name, SORT_DIRECTIONS.ASC);
    }
  };

  const handleSortDescClick = () => {
    if (columnSortState?.direction === SORT_DIRECTIONS.DESC) {
      setColumnSorting(column.name, SORT_DIRECTIONS.NO_SORT);
    } else {
      setColumnSorting(column.name, SORT_DIRECTIONS.DESC);
    }
  };

  function isActiveSort() {
    return !!columnSortState;
  }

  return (
    <div className={styles['body']}>
      {column.label}

      {sortingAllowed && (
        <div className={styles['sort']}>
          <div
            role="button"
            className={styles['button']}
            onClick={handleSortAscClick}
          >
            {isActiveSort() &&
            columnSortState.direction === SORT_DIRECTIONS.ASC ? (
              <SortUpIconSelected />
            ) : (
              <SortUpIcon />
            )}
          </div>
          <div
            role="button"
            className={styles['button']}
            onClick={handleSortDescClick}
          >
            {isActiveSort() &&
            columnSortState.direction === SORT_DIRECTIONS.DESC ? (
              <SortDownIconSelected />
            ) : (
              <SortDownIcon />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

CustomTableHeader.propTypes = {
  column: PropTypes.object.isRequired,
  sortedCols: PropTypes.arrayOf(
    PropTypes.shape({
      columnName: PropTypes.string.isRequired,
      direction: PropTypes.oneOf([SORT_DIRECTIONS.ASC, SORT_DIRECTIONS.DESC])
        .isRequired,
    })
  ),
  setColumnSorting: PropTypes.func,
  allowSortingFor: PropTypes.arrayOf(PropTypes.string),
};
