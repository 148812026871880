import { useEffect } from 'react';
import api from '@api';

export const useUsOptions = () => {
  const { getUsOptions, usOptions } = api.select.useUsOptions();

  useEffect(() => {
    getUsOptions();
  }, []);

  return { usOptions };
};
