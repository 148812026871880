import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@backstop/react-core/dist/esm/components/Tooltip';

import { SidebarLink } from '../sidebar-link/sidebar-link';

import classnames from 'classnames';
import styles from './sidebar-option.module.scss';

const delay = { show: 1000, hide: 500 };

export const SidebarOption = ({ option, isCollapsed }) => {
  const ref = useRef();

  const sidebarOptionClasses = classnames([styles['body']], {
    [styles['body--is-collapsed']]: isCollapsed,
  });

  return (
    <SidebarLink
      isOutsideLink={option.isOutsideLink}
      to={option.href}
      className={sidebarOptionClasses}
    >
      <img
        src={option.iconName}
        className={styles['icon']}
        alt={option.title}
        ref={ref}
      />
      <div className={styles['title']}>{option.title}</div>

      {isCollapsed && (
        <Tooltip
          target={() => ref.current}
          version="v3"
          delay={delay}
          orientation="top-end"
        >
          {option.title}
        </Tooltip>
      )}
    </SidebarLink>
  );
};

SidebarOption.propTypes = {
  option: PropTypes.shape({
    iconName: PropTypes.string,
    title: PropTypes.string,
    href: PropTypes.string,
    isOutsideLink: PropTypes.bool,
  }),
};
