import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import api from '@api';
import {
  userDataLoadingSet,
  userDataSet,
  userRolesSet,
} from '@redux/actions/user-data-actions';

export const useUserData = () => {
  const dispatch = useDispatch();
  const {
    getUserData,
    userData,
    loading: userDataLoading,
    isInitialLoad: userDataInitialLoad,
  } = api.user.useUserData();

  const { getRoles, roles } = api.user.useRoles();

  useEffect(() => {
    getUserData();
    getRoles();
  }, []);

  useEffect(() => {
    dispatch(userDataLoadingSet(userDataLoading || userDataInitialLoad));
  }, [userDataLoading, userDataInitialLoad]);

  useEffect(() => {
    dispatch(userDataSet(userData));
  }, [userData]);

  useEffect(() => {
    dispatch(userRolesSet(roles));
  }, [roles]);
};
