import React from 'react';
import { TabBase } from '../tab-base';

import styles from './requires-pro-access.module.scss';

export const RequiresProAccess = () => {
  return (
    <TabBase>
      <span className={styles['message']}>
        Available with FundFinder Pro Access. Click{' '}
        <a
          className={styles['link']}
          href={'https://www.barclayhedge.com/databases/global-database'}
          target="_blank"
        >
          HERE
        </a>{' '}
        for more info!
      </span>
    </TabBase>
  );
};
