import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useApiContext } from '@api';
import { loginFailure, loginSuccess } from '@redux/actions/login-actions';
import { useBasePost } from './base';

export const useLogin = () => {
  const { unauthorizedApiHttpClient } = useApiContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const endpointUrl = '/api/auth/signin';

  const login = useCallback(
    ({ email, password }) => {
      const body = {
        email,
        password,
      };

      setLoading(true);
      setError(null);

      unauthorizedApiHttpClient
        .post(endpointUrl, body)
        .then((response) => {
          dispatch(loginSuccess(response.data));
        })
        .catch((error) => {
          dispatch(loginFailure(error));
          setError(error);
        })
        .finally(() => setLoading(false));
    },
    [endpointUrl]
  );

  return {
    login,
    loading,
    error,
  };
};

export const useRequestPasswordReset = () => {
  const { unauthorizedApiHttpClient } = useApiContext();
  const [loading, setLoading] = useState(false);
  const [sentTo, setSentTo] = useState(false);
  const [error, setError] = useState(null);

  const endpointUrl = '/api/auth/user/resetPassword';

  const requestPasswordReset = useCallback(
    ({ email }) => {
      const body = {
        email,
        path: window.location.origin + '/reset-password/',
      };

      setLoading(true);
      setError(null);
      setSentTo(null);

      unauthorizedApiHttpClient
        .post(endpointUrl, body)
        .then(() => {
          setSentTo(email);
        })
        .catch((error) => {
          setError(error.message);
        })
        .finally(() => setLoading(false));
    },
    [endpointUrl]
  );

  return {
    requestPasswordReset,
    loading,
    error,
    sentTo,
  };
};

export const useResetPassword = () => {
  const { unauthorizedApiHttpClient } = useApiContext();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const endpointUrl = '/api/auth/user/changePassword';

  const resetPassword = useCallback(
    ({ token, newPassword }) => {
      const body = {
        token,
        newPassword,
      };

      setLoading(true);
      setError(null);
      setSuccess(false);

      unauthorizedApiHttpClient
        .post(endpointUrl, body)
        .then(() => {
          setSuccess(true);
        })
        .catch((error) => {
          setError(error.message);
        })
        .finally(() => setLoading(false));
    },
    [endpointUrl]
  );

  return {
    resetPassword,
    loading,
    success,
    error,
  };
};

export const useRegister = () => {
  const { unauthorizedApiHttpClient } = useApiContext();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const endpointUrl = '/api/auth/user/register';

  const register = useCallback(
    (data) => {
      const body = {
        ...data,
      };

      setLoading(true);
      setError(null);
      setSuccess(false);

      unauthorizedApiHttpClient
        .post(endpointUrl, body)
        .then(() => {
          setSuccess(true);
        })
        .catch((error) => {
          setError(error.message);
        })
        .finally(() => setLoading(false));
    },
    [endpointUrl]
  );

  return {
    register,
    loading,
    success,
    error,
  };
};

export const useLogout = () => {
  const { basePost, data, loading, ...rest } = useBasePost();

  const logout = () => {
    basePost('/api/auth/logout');
  };

  return { logout, data, loading, rest };
};
