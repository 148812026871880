import { useEffect } from 'react';
import api from '@api';

export const useCategories = () => {
  const { getCategories, categories } = api.select.useCategories();

  useEffect(() => {
    getCategories();
  }, []);

  return { categories };
};
