import React from 'react';
import styles from './footer.module.scss';

export const Footer = () => {
  return (
    <footer className={styles['body']}>
      <div className={styles['links']}>
        <a
          href="https://www.backstopsolutions.com/privacy-notice"
          target="_blank"
          className={styles['link']}
        >
          <div className={styles['title']}>Privacy Policy</div>
        </a>
        <a
          href="https://www.barclayhedge.com/terms-of-use"
          target="_blank"
          className={styles['link']}
        >
          <div className={styles['title']}>Terms of Use</div>
        </a>
      </div>
      <div
        className={styles['title']}
      >{`© ${new Date().getFullYear()} BarclayHedge`}</div>
    </footer>
  );
};
