// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".body--t3wSe{display:flex;margin-top:37px}.body--t3wSe .sort--R5gpe{display:flex;justify-content:space-between;margin-left:12px}.body--t3wSe .button--m4fGu{cursor:pointer;padding-left:.25em;padding-right:.25em}.body--t3wSe .sort-up-icon-selected--ZAM7t{transform:rotate(90deg)}.body--t3wSe .sort-down-icon-selected--xb5c7{transform:rotate(-90deg)}", "",{"version":3,"sources":["webpack://./src/components/common/table/custom-table-header/custom-table-header.module.scss"],"names":[],"mappings":"AAAA,aACE,YAAA,CACA,eAAA,CAEA,0BACE,YAAA,CACA,6BAAA,CACA,gBAAA,CAGF,4BACE,cAAA,CACA,kBAAA,CACA,mBAAA,CAGF,2CACE,uBAAA,CAGF,6CACE,wBAAA","sourcesContent":[".body {\n  display: flex;\n  margin-top: 37px;\n\n  .sort {\n    display: flex;\n    justify-content: space-between;\n    margin-left: 12px;\n  }\n\n  .button {\n    cursor: pointer;\n    padding-left: 0.25em;\n    padding-right: 0.25em;\n  }\n\n  .sort-up-icon-selected {\n    transform: rotate(90deg);\n  }\n\n  .sort-down-icon-selected {\n    transform: rotate(-90deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "body--t3wSe",
	"sort": "sort--R5gpe",
	"button": "button--m4fGu",
	"sort-up-icon-selected": "sort-up-icon-selected--ZAM7t",
	"sort-down-icon-selected": "sort-down-icon-selected--xb5c7"
};
export default ___CSS_LOADER_EXPORT___;
