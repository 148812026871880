import { useEffect } from 'react';
import api from '@api';

export const useTabData = (programData) => {
  const {
    data: instruments,
    loading: loadingInstruments,
    isInitialLoad: isInitialLoadInstruments,
  } = useInstruments(programData.id);

  const {
    data: holdings,
    loading: loadingHoldings,
    isInitialLoad: isInitialLoadHoldings,
  } = useHoldings(programData.id);

  const isDataLoading =
    isInitialLoadInstruments ||
    loadingInstruments ||
    isInitialLoadHoldings ||
    loadingHoldings;

  return {
    instruments,
    holdings,
    isDataLoading,
  };
};

const useInstruments = (id) => {
  const { getInstruments, data, loading, isInitialLoad, error } =
    api.program.useInstruments();

  useEffect(() => {
    getInstruments(id);
  }, [id]);

  return { data, loading, isInitialLoad, error };
};

const useHoldings = (id) => {
  const { getHoldings, data, loading, isInitialLoad, error } =
    api.program.useHoldings();

  useEffect(() => {
    getHoldings(id);
  }, [id]);

  return { data, loading, isInitialLoad, error };
};
