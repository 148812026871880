import React from 'react';
import { Modal } from '@backstop/react-core';
import Button from '../input/Button';
import PropTypes from 'prop-types';

const Confirm = ({
  confirmParam,
  confirmFunction,
  isOpen,
  toggleCancel,
  header,
  message,
  children,
}) => {
  return (
    <Modal
      version="v3"
      open={isOpen}
      toggle={toggleCancel}
      title={header}
      message={message}
      footerVisible={false}
    >
      <div>
        <div>{children}</div>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button kind="primary" onClick={() => confirmFunction(confirmParam)}>
            Confirm
          </Button>
          <Button kind="cancel" onClick={() => toggleCancel()}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

Confirm.propTypes = {
  confirmFunction: PropTypes.func.isRequired,
  confirmParam: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleCancel: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  message: PropTypes.string,
};

export default Confirm;
