// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".value-display--IA5jT{display:flex;max-width:calc(100% - 36px)}.value-display--IA5jT .value-list--L3hWH{max-width:100%;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.value-display--IA5jT .count--soz6b{margin-left:4px}", "",{"version":3,"sources":["webpack://./src/components/common/form-inputs/dropdown-filters/dropdown-multiselect-filter/dropdown-multiselect-filter.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,2BAAA,CAEA,yCACE,cAAA,CACA,eAAA,CACA,kBAAA,CACA,sBAAA,CAGF,oCACE,eAAA","sourcesContent":[".value-display {\n  display: flex;\n  max-width: calc(100% - 36px);\n\n  .value-list {\n    max-width: 100%;\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n  }\n\n  .count {\n    margin-left: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"value-display": "value-display--IA5jT",
	"value-list": "value-list--L3hWH",
	"count": "count--soz6b"
};
export default ___CSS_LOADER_EXPORT___;
