import React from 'react';
import PropTypes from 'prop-types';
import { BasicTable } from '@common/basic-table';
import { DecimalPercentageFormatter } from '@core/formatters';
import { PROGRAM_TYPES } from '@core/constants';
import { getDateObj } from '@core/date-helper';

export const CalendarYearReturns = ({ programData }) => {
  let currentYear = undefined;
  let convertedDate = undefined;
  if (programData.endDate) {
    if (programData.type === 'CTA') {
      convertedDate = getDateObj(programData.ctaDbEndDate);
    } else {
      convertedDate = getDateObj(programData.hfDbEndDate);
    }
    currentYear = convertedDate.getFullYear();
  } else {
    currentYear = new Date().getFullYear();
  }
  return (
    <BasicTable>
      <BasicTable.Title colSpan="6">Calendar Year Returns</BasicTable.Title>

      <BasicTable.Row>
        <BasicTable.Cell isBlank></BasicTable.Cell>
        <BasicTable.Header>{currentYear}</BasicTable.Header>
        <BasicTable.Header>{currentYear - 1}</BasicTable.Header>
        <BasicTable.Header>{currentYear - 2}</BasicTable.Header>
        <BasicTable.Header>{currentYear - 3}</BasicTable.Header>
        <BasicTable.Header>{currentYear - 4}</BasicTable.Header>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Manager/Advisor</BasicTable.Header>
        <BasicTable.Cell alignLeft>
          <DecimalPercentageFormatter
            handleNegVals
            onlyNegHundred
            value={programData.yearToDate}
          />
        </BasicTable.Cell>
        <BasicTable.Cell alignLeft>
          <DecimalPercentageFormatter
            handleNegVals
            onlyNegHundred
            value={programData.return1Year}
          />
        </BasicTable.Cell>
        <BasicTable.Cell alignLeft>
          <DecimalPercentageFormatter
            handleNegVals
            onlyNegHundred
            value={programData.return2Year}
          />
        </BasicTable.Cell>
        <BasicTable.Cell alignLeft>
          <DecimalPercentageFormatter
            handleNegVals
            onlyNegHundred
            value={programData.return3Year}
          />
        </BasicTable.Cell>
        <BasicTable.Cell alignLeft>
          <DecimalPercentageFormatter
            handleNegVals
            onlyNegHundred
            value={programData.return4Year}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>
          {programData.type === PROGRAM_TYPES.CTA
            ? 'Barclay CTA Index'
            : 'Barclay Hedge Fund Index'}
        </BasicTable.Header>
        <BasicTable.Cell alignLeft>
          <DecimalPercentageFormatter
            handleNegVals
            onlyNegHundred
            value={programData.indexYearToDate}
          />
        </BasicTable.Cell>
        <BasicTable.Cell alignLeft>
          <DecimalPercentageFormatter
            handleNegVals
            onlyNegHundred
            value={programData.indexReturn1Year}
          />
        </BasicTable.Cell>
        <BasicTable.Cell alignLeft>
          <DecimalPercentageFormatter
            handleNegVals
            onlyNegHundred
            value={programData.indexReturn2Year}
          />
        </BasicTable.Cell>
        <BasicTable.Cell alignLeft>
          <DecimalPercentageFormatter
            handleNegVals
            onlyNegHundred
            value={programData.indexReturn3Year}
          />
        </BasicTable.Cell>
        <BasicTable.Cell alignLeft>
          <DecimalPercentageFormatter
            handleNegVals
            onlyNegHundred
            value={programData.indexReturn4Year}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};

CalendarYearReturns.propTypes = {
  programData: PropTypes.object.isRequired,
};
