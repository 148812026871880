import React from 'react';

// TODO: This will have to come from backend

const noneOption = (
  <span>
    None: BarclayHedge data is only accessible to accredited investors. Academic
    and research users should please
    <a href="https://www.barclayhedge.com/contact/" target="_blank">
      &nbsp;contact a BarclayHedge team member&nbsp;
    </a>
    to request non-personalized data or product offerings.
  </span>
);

export const USER_STATUS_OPTIONS = [
  {
    value: '1',
    label: `Any bank as defined in section 3(a)(2) of the Act, or any savings and loan association or other institution as defined in section 3(a)(5)(A) of the Act whether acting in its individual or fiduciary capacity; any broker or dealer registered pursuant to section 15 of the Securities Exchange Act of 1934; any investment adviser registered pursuant to section 203 of the Investment Advisers Act of 194s0 [(the “Advisers Act”)] or registered pursuant to the laws of a state; any investment adviser relying on the exemption from registering with the Commission under section 203(l) or (m) of the [Advisers Act]; any insurance company as defined in section 2(a)(13) of the Act; any investment company registered under the Investment Company Act of 1940 or a business development company as defined in section 2(a)(48) of that act; any Small Business Investment Company licensed by the U.S. Small Business Administration under section 301(c) or (d) of the Small Business Investment Act of 1958; any Rural Business Investment Company as defined in section 384A of the Consolidated Farm and Rural Development Act; any plan established and maintained by a state, its political subdivisions, or any agency or instrumentality of a state or its political subdivisions, for the benefit of its employees, if such plan has total assets in excess of $5,000,000; any employee benefit plan within the meaning of the Employee Retirement Income Security Act of 1974 if the investment decision is made by a plan fiduciary, as defined in section 3(21) of such act, which is either a bank, savings and loan association, insurance company, or registered investment adviser, or if the employee benefit plan has total assets in excess of $5,000,000 or, if a self-directed plan, with investment decisions made solely by persons that are accredited investors`,
  },
  {
    value: '2',
    label: `Any private business development company as defined in section 202(a)(22) of the [Advisers Act]`,
  },
  {
    value: '3',
    label: `Any organization described in section 501(c)(3) of the Internal Revenue Code, corporation, Massachusetts or similar business trust, partnership, or limited liability company, not formed for the specific purpose of acquiring the securities offered, with total assets in excess of $5,000,000`,
  },
  {
    value: '4',
    label: `Any director, executive officer, or general partner of the issuer of the securities being offered or sold, or any director, executive officer, or general partner of a general partner of that issuer`,
  },
  {
    value: '5',
    label: `Any natural person whose individual net worth, or joint net worth with that person's spouse or spousal equivalent, exceeds $1,000,000`,
  },
  {
    value: '6',
    label: `Any natural person who had an individual income in excess of $200,000 in each of the two most recent years or joint income with that person's spouse or spousal equivalent in excess of $300,000 in each of those years and has a reasonable expectation of reaching the same income level in the current year`,
  },
  {
    value: '7',
    label: `Any trust, with total assets in excess of $5,000,000, not formed for the specific purpose of acquiring the securities offered, whose purchase is directed by a sophisticated person as described in § 230.506(b)(2)(ii)`,
  },
  {
    value: '8',
    label: `Any entity in which all of the equity owners are accredited investors`,
  },
  {
    value: '9',
    label: `Any entity, of a type not listed in paragraph (a)(1), (2), (3), (7), or (8), not formed for the specific purpose of acquiring the securities offered, owning investments in excess of $5,000,000`,
  },
  {
    value: '10',
    label: `Any natural person holding in good standing one or more professional certifications or designations or credentials from an accredited educational institution that the Commission has designated as qualifying an individual for accredited investor status`,
  },
  {
    value: '11',
    label: `Any natural person who is a “knowledgeable employee,” as defined in rule 3c-5(a)(4) under the Investment Company Act of 1940 (17 CFR 270.3c-5(a)(4)), of the issuer of the securities being offered or sold where the issuer would be an investment company, as defined in section 3 of such act, but for the exclusion provided by either section 3(c)(1) or section 3(c)(7) of such act`,
  },
  {
    value: '12',
    label: `Any “family office,” as defined in rule 202(a)(11)(G)-1 under the [Advisers Act] (17 CFR 275.202(a)(11)(G)-1): (i) with assets under management in excess of $5,000,000; (ii) that is not formed for the specific purpose of acquiring the securities offered; and (iii) whose prospective investment is directed by a person who has such knowledge and experience in financial and business matters that such family office is capable of evaluating the merits and risks of the prospective investment`,
  },
  {
    value: '13',
    label: `Any “family client,” as defined in rule 202(a)(11)(G)-1 under the [Advisers Act] (17 CFR 275.202(a)(11)(G)-1)), of a family office meeting the requirements in paragraph (a)(12) of this section and whose prospective investment in the issuer is directed by such family office pursuant to paragraph (a)(12)(iii)`,
  },
  {
    value: '0',
    label: noneOption,
  },
];
