import React from 'react';
import PropTypes from 'prop-types';
import { DecimalPercentageFormatter } from './decimal-percentage-formatter';

export const DecimalPercentageFormatterNegativeHandler = ({
  value,
  disableNegativeHandling,
}) => {
  return (
    <DecimalPercentageFormatter
      value={value}
      handleNegVals={!disableNegativeHandling}
    />
  );
};

DecimalPercentageFormatterNegativeHandler.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
