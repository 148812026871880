// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header--RACD6{padding:.75rem}.input-container--SdIyH{display:flex;flex-direction:row}.form-container--WgHeU{display:flex;justify-content:space-around}.flex-item--O4JhV{padding:.5rem}", "",{"version":3,"sources":["webpack://./src/components/awards/awards.module.scss"],"names":[],"mappings":"AAEA,eACI,cAAA,CAGJ,wBACI,YAAA,CACA,kBAAA,CAGJ,uBACI,YAAA,CACA,4BAAA,CAGJ,kBACI,aAAA","sourcesContent":["\n\n.header{\n    padding: 0.75rem;\n}\n\n.input-container{\n    display: flex;\n    flex-direction: row;\n}\n\n.form-container{\n    display: flex;\n    justify-content: space-around;\n}\n\n.flex-item{\n    padding: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "header--RACD6",
	"input-container": "input-container--SdIyH",
	"form-container": "form-container--WgHeU",
	"flex-item": "flex-item--O4JhV"
};
export default ___CSS_LOADER_EXPORT___;
