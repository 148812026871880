// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".not-a-member--ogLMp{display:block;margin-bottom:8px;font-size:14px}", "",{"version":3,"sources":["webpack://./src/components/auth/register-now/register-now.module.scss"],"names":[],"mappings":"AAAA,qBACE,aAAA,CACA,iBAAA,CACA,cAAA","sourcesContent":[".not-a-member {\n  display: block;\n  margin-bottom: 8px;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"not-a-member": "not-a-member--ogLMp"
};
export default ___CSS_LOADER_EXPORT___;
