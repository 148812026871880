import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { handleError } from './apiUtils';

const baseURL = process.env.REACT_APP_BARCLAYHEDGE_API_URL;

export const useApiHttpClient = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const expireTime = useSelector((state) => state.request.expire);

  const apiHttpClient = useMemo(() => {
    const instance = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      withCredentials: true,
    });

    instance.interceptors.request.use(async (req) => {
      const currentTime = Date.now();
      const expired = currentTime > expireTime;
      if (expired) throw { status: 401, message: 'Unauthorized' };

      return req;
    });

    instance.interceptors.response.use(
      (response) => response,
      (error) => handleError(error, dispatch, navigate)
    );

    return instance;
  }, [expireTime]);

  return { apiHttpClient };
};

export const useUnauthorizedApiHttpClient = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const unauthorizedApiHttpClient = useMemo(() => {
    const instance = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      withCredentials: true,
    });

    instance.interceptors.response.use(
      (response) => response,
      (error) => handleError(error, dispatch, navigate)
    );

    return instance;
  }, []);

  return { unauthorizedApiHttpClient };
};
