export const getTruncatedDisplayValue = (selectedList, maxLength) => {
  let result = '';

  for (let i = 0; i < selectedList.length; i++) {
    const label = selectedList[i];

    if (result.length + label.length + 2 < maxLength) {
      result += result ? ', ' : '';
      result += label;
    } else {
      if (i === 0) {
        result = label.substring(0, maxLength) + '...';
        if (selectedList.length > 1) {
          result += `, +${selectedList.length - 1}`;
        }
        return result;
      } else {
        result += `, +${selectedList.length - i}`;
        return result;
      }
    }
  }

  return result;
};
