import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Radio } from '@backstop/react-core';
import { FormControl } from '@common/form-control';
import { FilterDropdownContainer } from './filter-dropdown-container';
import {
  DropdownRadioFilter,
  getDisplayName,
} from './dropdown-filters/dropdown-radio-filter';

export const FormRadioInput = ({
  name,
  label,
  tooltipText,
  control,
  defaultValue,
  rules,
  options,
  placeholder = 'Select one',
  showConfirmationModal,
  formControlClassName,
  ...restProps
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={true}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => {
        const errorList = error ? [error.message] : null;
        return (
          <FormControl
            label={label}
            errors={errorList}
            tooltipText={tooltipText}
            className={formControlClassName}
          >
            {showConfirmationModal ? (
              <FilterDropdownContainer
                name={name}
                label={label}
                options={options}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                getDisplayName={getDisplayName}
                hasError={!!error}
              >
                <DropdownRadioFilter
                  name={name}
                  options={options}
                  {...restProps}
                />
              </FilterDropdownContainer>
            ) : (
              <Radio
                version="v3"
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                options={options}
                inputRef={ref}
                {...restProps}
              />
            )}
          </FormControl>
        );
      }}
    />
  );
};

FormRadioInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  tooltipText: PropTypes.string,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.any,
  rules: PropTypes.object,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  showConfirmationModal: PropTypes.bool,
  formControlClassName: PropTypes.string,
};
