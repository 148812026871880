import api from '@api';
import { useEffect } from 'react';

export const useLifetimeReturnData = (id) => {
  const { getLifetimeReturnChartData, data, loading } =
    api.program.useChartData();

  useEffect(() => {
    getLifetimeReturnChartData(id);
  }, [id]);

  return { data, loading };
};
