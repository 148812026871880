import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { formParamsSet } from '@redux/actions/params-actions';
import { getDefaultValues, SEARCH_FIELDS } from '@core/search-fields';
import { SORT_DIRECTIONS } from '@core/constants';

export const usePagination = (defaultPageSize) => {
  const [currentPage, setCurrentPage] = useState(1);

  const [pageSize, setPageSizeValue] = useState(defaultPageSize);

  const setPageSize = useCallback((value) => {
    setPageSizeValue(value);
    setCurrentPage(1);
  }, []);

  return {
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
  };
};

export const useSorting = ({ setCurrentPage, defaultSortingState }) => {
  const [sortedCols, setSortedCols] = useState(defaultSortingState);

  const addOrUpdateSortingState = (columnName, direction) => {
    let newState;
    const alreadyHasSortingState = sortedCols.some(
      (col) => col.columnName === columnName
    );

    if (alreadyHasSortingState) {
      newState = sortedCols.map((col) => {
        if (col.columnName === columnName) return { ...col, direction };
        return col;
      });
    } else {
      newState = [
        ...sortedCols,
        {
          columnName,
          direction,
        },
      ];
    }

    setSortedCols(newState);
  };

  const removeSortingState = (columnName) => {
    setSortedCols(sortedCols.filter((col) => col.columnName !== columnName));
  };

  const setColumnSorting = (columnName, direction) => {
    setCurrentPage(1);

    switch (direction) {
      case SORT_DIRECTIONS.ASC:
      case SORT_DIRECTIONS.DESC:
        addOrUpdateSortingState(columnName, direction);
        break;

      case SORT_DIRECTIONS.NO_SORT:
        removeSortingState(columnName);
        break;

      default:
        break;
    }
  };

  return { sortedCols, setColumnSorting };
};

export const useChipRemoveHandlers = (formParams) => {
  const dispatch = useDispatch();

  const handleChipRemove = (key) => {
    dispatch(
      formParamsSet({
        ...formParams,
        [key]: SEARCH_FIELDS[key].defaultValue,
      })
    );
  };

  const handleCustomFilterChipRemove = (key, index) => {
    dispatch(
      formParamsSet({
        ...formParams,
        [key]: formParams[key].filter((_, fieldIndex) => fieldIndex !== index),
      })
    );
  };

  const handleRemoveAll = () => {
    dispatch(formParamsSet(getDefaultValues()));
  };

  return {
    handleChipRemove,
    handleCustomFilterChipRemove,
    handleRemoveAll,
  };
};
