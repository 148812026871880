import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export const ProgramNameFormatter = ({ value, row }) => {
  const name2 = row.name2 ? ` (${row.name2})` : '';
  if (row.active == 1 && row.dataUsage == 'ALL') {
    return <Link to={`/program/${row.id}`}>{value + name2}</Link>;
  }
  return value + name2;
};

ProgramNameFormatter.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
};
