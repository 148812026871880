import React from 'react';
import PropTypes from 'prop-types';
import { CaptureChart } from '@core/charts/column';

export const Last10DownCaptureChartVsSp500 = ({
  progData,
  spData,
  progName,
}) => {
  return (
    <CaptureChart
      fundDataB={progData}
      fundDataA={spData}
      fundNameB={progName}
      fundNameA={'S&P 500'}
      chartTitle="Down Capture vs S&P 500"
    />
  );
};

Last10DownCaptureChartVsSp500.propTypes = {
  progData: PropTypes.object.isRequired,
  progName: PropTypes.string.isRequired,
  spData: PropTypes.object.isRequired,
};
