import { useCallback } from 'react';
import { useBaseGet } from './base';

export const useAwardsAndRanks = () => {
  const { baseGet, data, loading, ...rest } = useBaseGet();

  const getRanks = useCallback((sector, year, month) => {
    let url = `/awards?`;
    if (sector !== undefined) {
      url += `sectorId=${sector}&`;
    }
    if (year !== undefined && month !== undefined) {
      if (month === 'NA') {
        month = '12';
      }
      month = String(month).padStart(2, '0');
      url += `dstamp=${year}-${month}-01&`;
    }
    baseGet(url);
  }, []);

  return {
    getRanks,
    loading,
    data: data?.data,
    ...rest,
  };
};

export const useAwardFileDownloadToken = () => {
  const { baseGet, data, ...rest } = useBaseGet();

  const downloadToken = useCallback((awardFile) => {
    baseGet(`/awards/download/${awardFile}`);
  }, []);

  return {
    downloadToken,
    token: data?.data,
    ...rest,
  };
};

export const useProgramRanks = () => {
  const { baseGet, data, loading, ...rest } = useBaseGet();

  const getAwardsByProgram = useCallback((progId) => {
    let url = `/awards/${progId}`;
    baseGet(url);
  }, []);

  return {
    getAwardsByProgram,
    loading,
    data: data?.data,
    ...rest,
  };
};
