import React, { useEffect } from 'react';
import { DownloadAwardPdfButton } from './downloadAwardPdfButton';

export const AwardsDownload = ({}) => {
  return (
    <>
      <DownloadAwardPdfButton />
    </>
  );
};
