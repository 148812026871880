import React from 'react';
import styles from './disclaimer.module.scss';

export const Disclaimer = () => {
  return (
    <div className={styles['body']}>
      <h3 className={styles['title']}>
        Advisor, Investment, or Information Disclaimer
      </h3>
      <p className={styles['note']}>
        The funds are unregistered private investment funds or pools that employ
        different investment, hedging, leverage and arbitrage methodologies with
        exposure to many different securities and futures markets. The funds are
        not subject to the same regulatory requirements as mutual funds,
        including mutual funds requirements to provide certain periodic and
        standardized pricing and valuation information to investors. You should
        note the following:
      </p>
      <ul className={styles['note-list']}>
        <li>
          The funds represent speculative investments and involve a high degree
          of risk. An investor could lose all or a substantial portion of his or
          her investment.
        </li>
        <li>
          The funds represent speculative investments and involve a high degree
          of risk. An investor could lose all or a substantial portion of his or
          her investment.
        </li>
        <li>
          Any investment in the funds should be discretionary capital set aside
          strictly for speculative purposes.
        </li>
        <li>An investment in a fund is not suitable for all investors.</li>
        <li>
          The funds can be leveraged and a fund's performance can be volatile.
        </li>
        <li>
          Some funds may use a single advisor or employ a single strategy, which
          could mean a lack of diversification and higher risks.
        </li>
        <li>
          Some funds may execute a substantial portion of trades on foreign
          exchanges, which could mean higher risk.
        </li>
        <li>
          An investment in the funds may be illiquid and there are significant
          restrictions on transferring interests in a fund. There is no
          secondary market for an investor's investment in a fund and none is
          expected to develop.
        </li>
        <li>
          A fund's fees and expenses - which may be substantial regardless of
          any positive return - will offset the fund's trading profits.
        </li>
        <li>
          Some funds may involve complex tax structures and delays in
          distributing important tax information.
        </li>
        <li>
          This summary is not a complete list of the risks and other important
          disclosures involved in investing in the funds and is subject to the
          more complete disclosures contained in the fund's respective offering
          documents, which should be reviewed carefully.
        </li>
        <li>
          All performance information is believed to be net of applicable fees
          unless otherwise specifically noted.
        </li>
        <li>
          PAST PERFORMANCE IS NOT NECESSARILY INDICATIVE OF FUTURE TRADING
          RESULTS
        </li>
      </ul>
      <p className={styles['note']}>
        This material is provided for information purposes only as of the date
        hereof and is subject to change without notice. This material may not be
        suitable for all investors and is not intended to be an offer, or the
        solicitation of any offer, to buy or sell any securities. No offer or
        solicitation may be made prior to the delivery of appropriate offering
        documents to qualified investors. The information contained herein
        including investment returns, valuations, fund targets and strategies,
        has been supplied by the funds or their agents and although believed to
        be reliable, has not been independently verified and cannot be
        guaranteed. We make no representations or warranties as to the accuracy
        or completeness of such information. This material is not complete and
        is subject to the more complete disclosures and terms and conditions
        contained in a particular fund's offering documents, which may be
        obtained directly from the fund. Although we may provide our analysis,
        we do not provide due diligence on an investor's behalf and we are not
        responsible for an investor's decision. Past performance is no guarantee
        of future results.
      </p>
    </div>
  );
};
