import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@backstop/react-core';
import styles from './chevron-expander.module.scss';
import classNames from 'classnames';

export const ChevronExpander = ({ isExpanded, setIsExpanded, label }) => {
  const onClickExpandHeader = () => {
    setIsExpanded((state) => !state);
  };

  const buttonClasses = classNames(styles['body'], {
    [styles['body--full-width']]: !!label,
  });

  const iconClasses = classNames({
    [styles['icon-rotated']]: isExpanded,
  });

  return (
    <button
      type="button"
      onClick={() => onClickExpandHeader()}
      className={buttonClasses}
    >
      <div className={styles['icon-container']}>
        <Icon name="chevron-right" className={iconClasses} version="v3" />
      </div>
      {label && <span className={styles['label']}>{label}</span>}
    </button>
  );
};

ChevronExpander.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  setIsExpanded: PropTypes.func.isRequired,
  label: PropTypes.string,
};
