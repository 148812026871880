import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '@redux/actions/login-actions';

export const GuardedRoute = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  let loginRoute = '/login';
  //if the url has a redirect url for awards lets handle it
  if (location.pathname.includes('awards')) {
    //remove the initial '/' so it doens't get encoded and screw up the redirect
    loginRoute = `${loginRoute}/${encodeURIComponent(
      location.pathname.substring(1)
    )}`;
  }
  const loggedIn = useSelector((state) => !!state.request.expire);
  const expireTime = useSelector((state) => state.request.expire);
  const currentTime = Date.now();
  const expired = currentTime > expireTime;

  if (expired) {
    dispatch(logout());
  }

  return loggedIn && !expired ? children : <Navigate to={loginRoute} />;
};

GuardedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};
