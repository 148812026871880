import { useMemo, useState } from 'react';

export const useModalState = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModalState = () => {
    setModalOpen((modalOpen) => !modalOpen);
  };

  const closeModal = () => setModalOpen(false);

  return {
    modalOpen,
    toggleModalState,
    closeModal,
  };
};

export const useFieldPickerData = (columnOptions) => {
  return useMemo(
    () => [{ category: '', data: columnOptions }],
    [columnOptions]
  );
};
