import React from 'react';
import { Icon } from '@backstop/react-core';
import styles from './arrow-icon.module.scss';

export const ArrowIcon = () => {
  return (
    <div className={styles['body']}>
      <Icon name="caret-down" size="0.625rem" version="v3" />
    </div>
  );
};
