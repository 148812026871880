import { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { store } from '~/index';
import { useApiContext } from '@api';
import {
  cacheClean,
  cacheLoadingClean,
  cacheLoadingSet,
  cacheSet,
} from '@redux/actions/cache-actions';

export const useBaseGet = (options) => {
  const { noAuth = false, cacheConfig } = options || {};
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [error, setError] = useState(null);
  const abortController = useRef();
  const { apiHttpClient, unauthorizedApiHttpClient } = useApiContext();

  const dispatch = useDispatch();

  const cachedValue = useSelector(
    (state) => cacheConfig?.cacheKey && state.cache[cacheConfig.cacheKey]
  );

  const httpClient = noAuth ? unauthorizedApiHttpClient : apiHttpClient;

  const baseGet = useCallback(
    (url, config) => {
      if (cachedValue) {
        setIsInitialLoad(false);
        setLoading(false);
        setError(false);
        setData(cachedValue);
        return;
      }

      setLoading(true);
      setIsInitialLoad(false);
      setError(null);

      if (abortController.current) {
        abortController.current.abort();
      }

      const thisCallAbortController = new AbortController();
      abortController.current = thisCallAbortController;

      if (cacheConfig) {
        const cachedValueLoading =
          store.getState().cacheLoading[cacheConfig.cacheKey];

        if (cachedValueLoading) {
          return;
        } else {
          dispatch(cacheLoadingSet(cacheConfig.cacheKey));
        }
      }

      httpClient
        .get(url, {
          signal: abortController.current.signal,
          ...config,
        })
        .then((response) => {
          if (cacheConfig) {
            dispatch(cacheSet(cacheConfig.cacheKey, response.data));
          }
          setData(response.data);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            if (cacheConfig?.cacheKey) cacheClean(cacheConfig.cacheKey);
            setError(error);
          }
        })
        .finally(() => {
          if (
            abortController.current &&
            thisCallAbortController !== abortController.current
          ) {
            return;
          }

          if (thisCallAbortController === abortController.current) {
            abortController.current = null;
          }

          if (cacheConfig) {
            dispatch(cacheLoadingClean(cacheConfig.cacheKey));
          }

          setLoading(false);
        });
    },
    [cacheConfig, cachedValue]
  );

  return {
    baseGet,
    data: cacheConfig ? cachedValue : data,
    loading,
    isInitialLoad,
    error,
  };
};

export const useBasePost = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const abortController = useRef();
  const { apiHttpClient } = useApiContext();

  const basePost = useCallback((url, data, config) => {
    setLoading(true);
    setError(null);

    if (abortController.current) {
      abortController.current.abort();
    }

    const thisCallAbortController = new AbortController();
    abortController.current = thisCallAbortController;

    apiHttpClient
      .post(url, data, {
        signal: abortController.current.signal,
        ...config,
      })
      .then((response) => setData(response.data))
      .catch((error) => {
        if (!axios.isCancel(error)) setError(error);
      })
      .finally(() => {
        if (
          abortController.current &&
          thisCallAbortController !== abortController.current
        ) {
          return;
        }

        if (thisCallAbortController === abortController.current) {
          abortController.current = null;
        }

        setLoading(false);
      });
  }, []);

  return { basePost, data, loading, error };
};

export const useBaseDelete = () => {
  const abortController = useRef();
  const { noAuth = false, cacheConfig } = {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState();
  const { apiHttpClient } = useApiContext();

  const httpClient = noAuth ? unauthorizedApiHttpClient : apiHttpClient;

  const baseDelete = useCallback((url) => {
    setLoading(true);
    setError(null);

    if (abortController.current) {
      abortController.current.abort();
    }

    const thisCallAbortController = new AbortController();
    abortController.current = thisCallAbortController;

    httpClient
      .delete(url, {
        signal: abortController.current.signal,
      })
      .then((response) => {
        if (cacheConfig) {
          dispatch(cacheSet(cacheConfig.cacheKey, response.data));
        }
        setData(response.data);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          if (cacheConfig?.cacheKey) cacheClean(cacheConfig.cacheKey);
          setError(error);
        }
      })
      .finally(() => {
        if (
          abortController.current &&
          thisCallAbortController !== abortController.current
        ) {
          return;
        }

        if (thisCallAbortController === abortController.current) {
          abortController.current = null;
        }

        if (cacheConfig) {
          dispatch(cacheLoadingClean(cacheConfig.cacheKey));
        }

        setLoading(false);
      });
  }, []);

  return {
    baseDelete,
    loading,
    data,
    error,
  };
};

export const useBasePut = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const abortController = useRef();
  const { apiHttpClient } = useApiContext();

  const basePut = useCallback((url, data, config) => {
    setLoading(true);
    setError(null);

    if (abortController.current) {
      abortController.current.abort();
    }

    const thisCallAbortController = new AbortController();
    abortController.current = thisCallAbortController;

    apiHttpClient
      .put(url, data, {
        signal: abortController.current.signal,
        ...config,
      })
      .then((response) => setData(response.data))
      .catch((error) => {
        if (!axios.isCancel(error)) setError(error);
      })
      .finally(() => {
        if (
          abortController.current &&
          thisCallAbortController !== abortController.current
        ) {
          return;
        }

        if (thisCallAbortController === abortController.current) {
          abortController.current = null;
        }

        setLoading(false);
      });
  }, []);

  return { basePut, data, loading, error };
};
