import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { REGEX } from '@core/validators';
import styles from './password-requirements.module.scss';

const passwordRequirements = [
  {
    label: 'At least eight characters',
    check: (password, _) => password?.length >= 8,
  },
  {
    label: 'Lower-case letter',
    check: (password, _) => password?.toUpperCase() != password,
  },
  {
    label: 'Upper-case letter',
    check: (password, _) => password?.toLowerCase() != password,
  },
  {
    label: 'Number',
    check: (password, _) => password?.match(REGEX.digit),
  },
  {
    label: 'Special character',
    check: (password, _) => password?.match(REGEX.specialCharacter),
  },
  {
    label: 'Passwords must match',
    check: (password, confirmPassword) =>
      password && confirmPassword && password === confirmPassword,
  },
];

export const hasErrors = (password, confirmPassword) => {
  return passwordRequirements.some(
    (requirement) => !requirement.check(password, confirmPassword)
  );
};

export const PasswordRequirements = ({
  password,
  confirmPassword,
  setPasswordHasErrors,
}) => {
  if (setPasswordHasErrors) {
    useEffect(() => {
      const hasErrors = passwordRequirements.some((requirement) => {
        return !requirement.check(password?.trim(), confirmPassword?.trim());
      });

      setPasswordHasErrors(hasErrors);
    }, [password, confirmPassword]);
  }

  const getClassname = (check) => {
    if (check(password?.trim(), confirmPassword?.trim())) {
      return styles['pass'];
    } else {
      return styles['fail'];
    }
  };

  return (
    <div>
      <ul className={styles['requirements-list']}>
        {passwordRequirements.map((requirement) => (
          <li
            key={requirement.label}
            className={getClassname(requirement.check)}
          >
            {requirement.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

PasswordRequirements.propTypes = {
  password: PropTypes.string,
  confirmPassword: PropTypes.string,
  setPasswordHasErrors: PropTypes.func,
};
