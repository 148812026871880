import { useEffect } from 'react';
import api from '@api';

export const useExchangeListOptions = () => {
  const { getExchangeListOptions, exchangeListOptions } =
    api.select.useExchangeListOptions();

  useEffect(() => {
    getExchangeListOptions();
  }, []);

  return { exchangeListOptions };
};
