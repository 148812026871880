import React from 'react';
import PropTypes from 'prop-types';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

export const LineChart = ({ title, dataPoints }) => {
  const options = {
    title: {
      text: title,
      align: 'left',
    },
    credits: { enabled: false },
    series: dataPoints,
  };

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        constructorType={'stockChart'}
      />
    </>
  );
};

LineChart.propTypes = {
  title: PropTypes.string.isRequired,
  dataPoints: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
    .isRequired,
};
