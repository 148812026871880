import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from '@common/loader';
import { TabBase } from '../tab-base';
import { RequiresProAccess, hasProAccess } from '../requires-pro-access';
import { ContactInformation } from './tables/contact-information';
import { useCompanyContacts } from './hooks';
import styles from './contacts-info-tab.module.scss';

export const ContactsInfoTab = ({ programData }) => {
  if (!hasProAccess(programData)) return <RequiresProAccess />;

  const {
    data: companyContactsData,
    loading,
    isInitialLoad,
  } = useCompanyContacts(programData?.compId);

  const isDataLoading = loading || isInitialLoad;

  if (isDataLoading) return <Loader />;

  if (companyContactsData.length === 0)
    return <div className={styles['message']}>No contacts to display</div>;

  return (
    <TabBase>
      {companyContactsData.map((contact) => (
        <ContactInformation contact={contact} key={contact.id} />
      ))}
    </TabBase>
  );
};

ContactsInfoTab.propTypes = {
  programData: PropTypes.object.isRequired,
};
