import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from '@backstop/react-core';
import { useFilterDropdownContext } from '@common/form-inputs/filter-dropdown-container';
import { useApplyOnEnter } from '../hooks';

export const getDisplayName = (options, value) => {
  const selectedOption = options.find((option) => option.value === value);
  const displayValue = selectedOption ? selectedOption.label : undefined;
  return {
    displayValue,
    fullValueString: displayValue,
  };
};

export const DropdownRadioFilter = ({ name, options, ...restProps }) => {
  const { localValue, setLocalValue } = useFilterDropdownContext();
  useApplyOnEnter();

  return (
    <Radio
      version="v3"
      name={name}
      options={options}
      value={localValue}
      onChange={setLocalValue}
      {...restProps}
    />
  );
};

DropdownRadioFilter.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};
