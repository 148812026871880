import api from '@api';
import { useEffect } from 'react';

export const useVamiChart = (id) => {
  const { getVamiChartData, data, loading } = api.program.useChartData();

  useEffect(() => {
    getVamiChartData(id);
  }, [id]);

  return { data, loading };
};
