import React from 'react';
import PropTypes from 'prop-types';
import styles from './awards-image.module.scss';

export const AwardsImage = ({ size, rank, date, name, sector }) => {
  const dateObj = new Date(date);
  const month = dateObj.toLocaleString('default', { month: 'short' });
  const year = dateObj.toLocaleDateString('default', { year: 'numeric' });
  return (
    <>
      <div className={styles[`main-${size}`]}>
        <div className={styles[`title-${size}`]}>
          {/* //if they are top 3 they get the rank #, everyone else gets 'top 10' */}
          {rank > 3 ? 'Top 10' : `#${rank}`}
        </div>
        <div className={styles[`body-${size}`]}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>{name}</div>
            <div>
              Net Return For {month} {year}
            </div>
            <div>Sector : {sector}</div>
          </div>
        </div>
      </div>
    </>
  );
};

AwardsImage.propTypes = {
  size: PropTypes.string.isRequired,
  rank: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sector: PropTypes.string.isRequired,
};
