// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".body--Ny_K8{padding:24px}.link--MVznN{font-size:14px;margin-top:auto;margin-bottom:auto}", "",{"version":3,"sources":["webpack://./src/components/search-results/search-results.module.scss"],"names":[],"mappings":"AAAA,aACE,YAAA,CAGF,aACE,cAAA,CACA,eAAA,CACA,kBAAA","sourcesContent":[".body {\n  padding: 24px;\n}\n\n.link {\n  font-size: 14px;\n  margin-top: auto;\n  margin-bottom: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "body--Ny_K8",
	"link": "link--MVznN"
};
export default ___CSS_LOADER_EXPORT___;
