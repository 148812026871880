import PropTypes from 'prop-types';
import { isEmpty } from '@core/validators';
import { dataUnavailable, toDays, toPercent } from '@core/formatters';

export const PayoutFormatter = ({ row }) => {
  const { payoutPct, payoutDays } = row;

  if (isEmpty(payoutPct)) return dataUnavailable();
  const value = toPercent(payoutPct);

  if (!payoutDays) return dataUnavailable(value);
  const period = toDays(payoutDays);

  return `${value} within ${period}`;
};

PayoutFormatter.propTypes = {
  row: PropTypes.object.isRequired,
};
