import React from 'react';
import { Link } from 'react-router-dom';

import { AuthPageBase } from '@auth/auth-page-base';
import { RegisterNow } from '@auth/register-now';
import { LoginForm } from './login-form';

import styles from './login.module.scss';

export const Login = () => {
  return (
    <AuthPageBase>
      <LoginForm />

      <Link to="/forgot-password" className={styles['forgot-password']}>
        Forgot Password?
      </Link>

      <RegisterNow />
    </AuthPageBase>
  );
};
