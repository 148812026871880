import { useEffect } from 'react';
import api from '@api';

export const useRedemptions = () => {
  const { getRedemptions, redemptions } = api.select.useRedemptions();

  useEffect(() => {
    getRedemptions();
  }, []);

  return { redemptions };
};
