import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@backstop/react-core';
import styles from './multiselect-controls.module.scss';

export const MultiselectControls = ({
  selectAll,
  clearAll,
  selectedCount,
  totalOptionsCount,
  maxOptionsSelected,
}) => {
  return (
    <div className={styles['body']}>
      <div>
        <Icon
          className={styles['icon']}
          name="filter"
          size="0.625rem"
          version="v3"
        />
        <span className={styles['count']}>{selectedCount} Selected</span>
      </div>
      <div>
        <button
          type="button"
          onClick={selectAll}
          disabled={totalOptionsCount > maxOptionsSelected}
          className={styles['action-button']}
        >
          Select All
        </button>
        <button
          type="button"
          onClick={clearAll}
          className={styles['action-button']}
        >
          Clear All
        </button>
      </div>
    </div>
  );
};

MultiselectControls.propTypes = {
  selectAll: PropTypes.func.isRequired,
  clearAll: PropTypes.func.isRequired,
  selectedCount: PropTypes.number,
  totalOptionsCount: PropTypes.number,
  maxOptionsSelected: PropTypes.number,
};
