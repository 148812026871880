import React from 'react';
import PropTypes from 'prop-types';
import styles from './tab-base.module.scss';

export const TabBase = ({ children }) => {
  return <div className={styles['body']}>{children}</div>;
};

TabBase.propTypes = {
  children: PropTypes.node,
};
