import React from 'react';
import PropTypes from 'prop-types';
import {
  DateFormatter,
  DaysFormatter,
  DecimalPercentageFormatter,
  MoneyMillionsFormatter,
  MoneyThousandsFormatter,
  StrategiesFormatter,
} from '@core/formatters';
import { BasicTable } from '@common/basic-table';

export const AdministrativeInformation = ({ programData }) => {
  return (
    <BasicTable>
      <BasicTable.Title colSpan="2">
        Administrative Information
      </BasicTable.Title>

      <BasicTable.Row>
        <BasicTable.Header>Start Date</BasicTable.Header>
        <BasicTable.Cell>
          <DateFormatter value={programData.inceptionDate} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Last Reported RoR</BasicTable.Header>
        <BasicTable.Cell>
          <DateFormatter value={programData.endDate} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Minimum Account</BasicTable.Header>
        <BasicTable.Cell>
          <MoneyThousandsFormatter
            value={programData.minAccount}
            row={programData}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Management Fee</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatter value={programData.managementFee} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Incentive Fee</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatter value={programData.incentiveFee} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Lock Up</BasicTable.Header>
        <BasicTable.Cell>
          <DaysFormatter value={programData.lockup} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Fund AUM</BasicTable.Header>
        <BasicTable.Cell>
          <MoneyMillionsFormatter value={programData.aum} row={programData} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Strategy</BasicTable.Header>
        <BasicTable.Cell>
          <StrategiesFormatter row={programData} />
        </BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};

AdministrativeInformation.propTypes = {
  programData: PropTypes.object.isRequired,
};
