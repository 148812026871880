export const FORM_INCLUSION_OPTIONS = [
  {
    value: 'OR',
    label: 'Meets Any Property Selected',
  },
  {
    value: 'AND',
    label: 'Meets All Properties Selected',
  },
];
