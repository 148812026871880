import React from 'react';
import PropTypes from 'prop-types';
import { Row } from '../row';
import classNames from 'classnames';
import styles from './section.module.scss';

export const Section = ({ children, className, ...restProps }) => {
  const classes = classNames(styles['body'], className);

  return (
    <Row {...restProps} className={classes}>
      {children}
    </Row>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
