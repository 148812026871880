import React from 'react';
import { Modal } from '@backstop/react-core';
import Button from '../input/Button';
import PropTypes from 'prop-types';

const Delete = ({
  deleteFunction,
  elementToDelete,
  isOpen,
  toggleDelete,
  deleteHeader,
  deleteMessage,
}) => {
  return (
    <Modal
      version="v3"
      open={isOpen}
      toggle={toggleDelete}
      title={deleteHeader}
      footerVisible={false}
    >
      <div>
        <div>
          <p>{deleteMessage}</p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            kind="primary"
            onClick={() => deleteFunction(elementToDelete)}
          >
            Yes
          </Button>
          <Button kind="cancel" onClick={() => toggleDelete()}>
            No
          </Button>
        </div>
      </div>
    </Modal>
  );
};

Delete.propTypes = {
  deleteFunction: PropTypes.func.isRequired,
  elementToDelete: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleDelete: PropTypes.func.isRequired,
  deleteHeader: PropTypes.string.isRequired,
  deleteMessage: PropTypes.string.isRequired,
};

export default Delete;
