import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { FormControl } from '@common/form-control';
import { NestedRadio } from '@common/nested-radio';
import { InputWithTooltip } from '@common/input-with-tooltip';

export const FormNestedRadio = ({
  name,
  label,
  tooltipText,
  control,
  defaultValue,
  rules,
  placeholder = 'Select one',
  formControlClassName,
  ...restProps
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={true}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        const errorList = error ? [error.message] : null;
        const optionTooltipText = value?.tooltip;

        return (
          <FormControl
            label={label}
            errors={errorList}
            tooltipText={tooltipText}
            className={formControlClassName}
          >
            <InputWithTooltip tooltipText={optionTooltipText}>
              <NestedRadio
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                hasError={!!errorList}
                {...restProps}
              />
            </InputWithTooltip>
          </FormControl>
        );
      }}
    />
  );
};

FormNestedRadio.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  tooltipText: PropTypes.string,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.any,
  rules: PropTypes.object,
  placeholder: PropTypes.string,
  formControlClassName: PropTypes.string,
};
