import PropTypes from 'prop-types';
import { dataUnavailable } from '@core/formatters';

/**
 *
 * @param {} value the value to be translated
 * @param {} handleNegVals should we convert -1, -2 to No,Yes
 * @returns
 */
export const BasicNumberFormatter = ({ value, handleNegVals }) => {
  if (value == 'Yes' || value == 'No') {
    return value;
  }

  if (handleNegVals) {
    if (parseInt(value, 10) === -1) {
      return 'No';
    }
    if (parseInt(value, 10) === -2) {
      return 'Yes';
    }
  }

  return dataUnavailable(value);
};

BasicNumberFormatter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleNegVals: PropTypes.bool,
};
