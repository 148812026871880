import React from 'react';
import { ErrorPageBase } from './error-page-base';

export const InternalServerError = () => {
  return (
    <ErrorPageBase
      title={'Internal Server Error'}
      message={
        'It looks like the sever has encountered an internal error. Maybe try one of the links below?'
      }
    />
  );
};
