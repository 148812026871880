import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from './browser-storage';

const TOKEN_EXP = 'access-token-exp';

export const setTokenExpTime = (value) => setLocalStorage(TOKEN_EXP, value);
export const removeTokenExpTime = () => removeLocalStorage(TOKEN_EXP);
export const getTokenExpTime = () => getLocalStorage(TOKEN_EXP);
