// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".return-to-login--Vkaa8{display:block;margin-top:8px;margin-bottom:24px;font-size:14px}", "",{"version":3,"sources":["webpack://./src/components/auth/forgot-password/forgot-password.module.scss"],"names":[],"mappings":"AAAA,wBACE,aAAA,CACA,cAAA,CACA,kBAAA,CACA,cAAA","sourcesContent":[".return-to-login {\n  display: block;\n  margin-top: 8px;\n  margin-bottom: 24px;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"return-to-login": "return-to-login--Vkaa8"
};
export default ___CSS_LOADER_EXPORT___;
