import { useUserData } from '@core/user';
import React from 'react';
import styles from './tagline.module.scss';

export const Tagline = () => {
  const { isSubscriber } = useUserData();
  return (
    <div className={styles['body']}>
      <div className={styles['title']}>
        {isSubscriber ? 'FundFinder Pro' : 'FundFinder'}
      </div>
    </div>
  );
};
