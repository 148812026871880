// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#custom-filter--logBg{width:710px}@media only screen and (max-width: 750px){#custom-filter--logBg{width:100%}}", "",{"version":3,"sources":["webpack://./src/components/main-search/custom-filters/custom-filter-modal/custom-filter-modal.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CAGF,0CACE,sBACE,UAAA,CAAA","sourcesContent":["#custom-filter {\n  width: 710px;\n}\n\n@media only screen and (max-width: 750px) {\n  #custom-filter {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-filter": "custom-filter--logBg"
};
export default ___CSS_LOADER_EXPORT___;
