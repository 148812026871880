import React from 'react';
import PropTypes from 'prop-types';
import { List, Item } from '@backstop/react-core';
import { useFilterDropdownContext } from '@common/form-inputs/filter-dropdown-container';
import { useApplyOnEnter } from '../hooks';
import styles from './dropdown-select-filter.module.scss';

export const getDisplayName = (options, value) => {
  const selectedOption = options.find((option) => option.value === value);
  const displayValue = selectedOption ? selectedOption.label : undefined;
  return {
    displayValue,
    fullValueString: displayValue,
  };
};

export const DropdownSelectFilter = ({ options }) => {
  const { localValue, setLocalValue } = useFilterDropdownContext();
  useApplyOnEnter();

  return (
    <List className={styles['body']}>
      {options.map((option) => (
        <Item
          key={option.value}
          onClick={() => setLocalValue(option.value)}
          active={option.value === localValue}
          className={styles['item']}
          disabled={option.disabled}
        >
          {option.label}
        </Item>
      ))}
    </List>
  );
};

DropdownSelectFilter.propTypes = {
  options: PropTypes.array.isRequired,
};
