import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { DatePicker } from '@backstop/react-core';
import { FormControl } from '@common/form-control';

export const FormDatePicker = ({
  name,
  label,
  tooltipText,
  control,
  defaultValue,
  rules,
  formControlClassName,
  ...restProps
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={true}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        const errorList = error ? [error.message] : null;
        return (
          <FormControl
            label={label}
            errors={errorList}
            tooltipText={tooltipText}
            className={formControlClassName}
          >
            <DatePicker
              version="v3"
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              hasError={!!errorList}
              {...restProps}
            />
          </FormControl>
        );
      }}
    />
  );
};

FormDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  tooltipText: PropTypes.string,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.any,
  rules: PropTypes.object,
  formControlClassName: PropTypes.string,
};
