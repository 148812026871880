import { useMemo, useState } from 'react';
import { getSelectedLabels } from '../checkbox-list';
import { getTruncatedDisplayValue } from './helpers';

export const useDropdownState = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdownState = () => {
    setDropdownOpen((dropdownOpen) => !dropdownOpen);
  };

  const closeDropdown = () => setDropdownOpen(false);

  return {
    dropdownOpen,
    toggleDropdownState,
    closeDropdown,
  };
};

export const useSelectedLables = (selectedItems, options, maxLength) => {
  return useMemo(() => {
    const selectedLabels = getSelectedLabels(selectedItems, options);
    const fullLabelsList = selectedLabels.join(', ');
    const truncatedLabelsList = getTruncatedDisplayValue(
      selectedLabels,
      maxLength
    );

    return {
      fullLabelsList,
      truncatedLabelsList,
    };
  }, [selectedItems, options, maxLength]);
};
