export const CTA_STRATEGY_OPTIONS = [
  {
    id: 'technical',
    optionLabel: 'Sectors Traded',
    children: [
      {
        id: 'strategyAgri',
        optionLabel: 'Agriculture',
      },
      {
        id: 'strategyCrypto',
        optionLabel: 'Cryptocurrency',
      },
      {
        id: 'strategyCurrency',
        optionLabel: 'Currency',
      },
      {
        id: 'strategyDivers',
        optionLabel: 'Diversified',
      },
      {
        id: 'strategyEnergy',
        optionLabel: 'Energy',
      },
      {
        id: 'strategyFinMetal',
        optionLabel: 'Financial/Metal',
      },
      {
        id: 'strategyIntRate',
        optionLabel: 'Interest Rates',
      },
      {
        id: 'options',
        optionLabel: 'Option Strategies',
      },
      {
        id: 'stockIndex',
        optionLabel: 'Stock Index',
      },
      {
        id: 'strategyVolatility',
        optionLabel: 'Volatility',
      },
    ],
  },
  {
    id: 'tradingStyle',
    optionLabel: 'Trading Style',
    children: [
      {
        id: 'arbitrage',
        optionLabel: 'Arbitrage',
      },
      {
        id: 'discretionary',
        optionLabel: 'Discretionary',
      },
      {
        id: 'shortTerm',
        optionLabel: 'Short-Term',
      },
      {
        id: 'systematic',
        optionLabel: 'Systematic',
      },
    ],
  },
  {
    id: 'multiAdvisor',
    optionLabel: 'Multi-Advisor Fund',
  },
  {
    id: 'noCategoryCta',
    optionLabel: 'No Category',
  },
  {
    id: 'ctaFund',
    optionLabel: 'Single-Advisor Fund',
  },
];
