import { useEffect } from 'react';
import api from '@api';

export const useCompanyContacts = (id) => {
  const { getCompanyContactsData, data, loading, isInitialLoad, error } =
    api.company.useCompanyContacts();

  useEffect(() => {
    getCompanyContactsData(id);
  }, [id]);

  return { data, loading, isInitialLoad, error };
};
