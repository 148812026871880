// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".body--HBuvM{margin-top:100px;height:100%;padding-bottom:150px}.body--HBuvM .wrapper--xefWi{gap:20px;display:flex;justify-content:center;text-align:center}.body--HBuvM .title--xoZRk{text-align:center;font-size:48px;margin-bottom:50px}.body--HBuvM .icon--nekf3{height:50px;transform:rotate(-15deg)}.body--HBuvM .message--gJAKf{font-size:24px;text-align:center}", "",{"version":3,"sources":["webpack://./src/components/coming-soon-page/coming-soon-page.module.scss"],"names":[],"mappings":"AAEA,aACE,gBAAA,CACA,WAAA,CACA,oBAAA,CAEA,6BACE,QAAA,CACA,YAAA,CACA,sBAAA,CACA,iBAAA,CAGF,2BACE,iBAAA,CACA,cAAA,CACA,kBAAA,CAGF,0BACE,WAAA,CACA,wBAAA,CAGF,6BACE,cAAA,CACA,iBAAA","sourcesContent":["@import './src/theme/_variables';\n\n.body {\n  margin-top: 100px;\n  height: 100%;\n  padding-bottom: 150px;\n\n  .wrapper {\n    gap: 20px;\n    display: flex;\n    justify-content: center;\n    text-align: center;\n  }\n\n  .title {\n    text-align: center;\n    font-size: 48px;\n    margin-bottom: 50px;\n  }\n\n  .icon {\n    height: 50px;\n    transform: rotate(-15deg);\n  }\n\n  .message {\n    font-size: 24px;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "body--HBuvM",
	"wrapper": "wrapper--xefWi",
	"title": "title--xoZRk",
	"icon": "icon--nekf3",
	"message": "message--gJAKf"
};
export default ___CSS_LOADER_EXPORT___;
