import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@backstop/react-core';

import { useCallbackOnEnter } from '@core/keyboard-helpers';
import { ConfirmationModal } from './confirmation-modal';

export const ButtonWithConfirmation = ({
  iconButtonType,
  buttonText,
  confirmButtonText,
  confirmationTitle,
  confirmationMessage,
  onConfirm,
  closeOnConfirm = true,
  ...rest
}) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const toggle = () => setConfirmationModalOpen((state) => !state);

  const handleConfirm = () => {
    onConfirm();
    if (closeOnConfirm) toggle();
  };

  const handleEnterCallbackRef = useRef();
  handleEnterCallbackRef.current = () => {
    if (confirmationModalOpen) handleConfirm();
  };
  useCallbackOnEnter(handleEnterCallbackRef);

  return (
    <>
      {iconButtonType ? (
        <Button.EditIconButton
          version="v3"
          type={iconButtonType}
          onClick={() => setConfirmationModalOpen(true)}
          {...rest}
        />
      ) : (
        <Button
          version="v3"
          onClick={() => setConfirmationModalOpen(true)}
          {...rest}
        >
          {buttonText}
        </Button>
      )}
      <ConfirmationModal
        open={confirmationModalOpen}
        toggle={toggle}
        onConfirm={handleConfirm}
        text={confirmationMessage}
        title={confirmationTitle}
        confirmButtonText={confirmButtonText}
      />
    </>
  );
};

ButtonWithConfirmation.propTypes = {
  iconButtonType: PropTypes.string,
  buttonText: PropTypes.node,
  confirmButtonText: PropTypes.node,
  confirmationTitle: PropTypes.node,
  confirmationMessage: PropTypes.node.isRequired,
  onConfirm: PropTypes.func.isRequired,
  closeOnConfirm: PropTypes.bool,
};
