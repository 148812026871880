import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageSizeSelector } from './page-size-selector';
import classNames from 'classnames';
import './paginator.css';

/**
 * Paginator component
 * @returns
 */
export const Paginator = ({
  pageSize,
  setPageSize,
  currentPage,
  setCurrentPage,
  totalElements,
  isTableLoading,
}) => {
  const totalPages = Math.ceil(totalElements / pageSize.value) || 1;

  const clickPage = (page) => setCurrentPage(page);
  const clickNext = () => setCurrentPage(currentPage + 1);
  const clickPrev = () => setCurrentPage(currentPage - 1);

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  const rangeStart = totalElements && (currentPage - 1) * pageSize.value + 1;
  const rangeEnd = Math.min(currentPage * pageSize.value, totalElements);
  const rangeString = `${rangeStart}-${rangeEnd} of ${totalElements}`;

  const showFirstPage = currentPage > 3;
  const showLeftEllipsis = currentPage > 4;

  const showLastPage = totalPages > 3 && currentPage + 2 < totalPages;
  const showRightEllipsis = totalPages > 3 && currentPage + 3 < totalPages;

  const shownPages = [...Array(totalPages + 1).keys()].filter(
    (number) =>
      number > 0 && number > currentPage - 3 && number < currentPage + 3
  );

  const handleSetPageSize = (value) => !isTableLoading && setPageSize(value);

  return (
    <div className="paginator">
      <div className="paginator__page-size-selector">
        <PageSizeSelector pageSize={pageSize} setPageSize={handleSetPageSize} />
      </div>

      <div className="paginator__shown-range">{rangeString}</div>

      <div className="paginator__page-selector">
        <button
          type="button"
          className="paginator__arrow"
          onClick={clickPrev}
          disabled={isFirstPage || isTableLoading}
        >
          <FontAwesomeIcon icon={'arrow-left'} />
        </button>

        {showFirstPage && (
          <button
            type="button"
            className="paginator__button"
            onClick={() => clickPage(1)}
          >
            1
          </button>
        )}

        {showLeftEllipsis && <span>...</span>}

        {shownPages.map((page) => (
          <button
            key={page}
            type="button"
            className={classNames('paginator__button', {
              'paginator__button--selected': page === currentPage,
            })}
            onClick={() => clickPage(page)}
            disabled={page === currentPage || isTableLoading}
          >
            {page}
          </button>
        ))}

        {showRightEllipsis && <span>...</span>}

        {showLastPage && (
          <button
            type="button"
            className="paginator__button"
            onClick={() => clickPage(totalPages)}
          >
            {totalPages}
          </button>
        )}

        <button
          className="paginator__arrow"
          onClick={clickNext}
          disabled={isLastPage || isTableLoading}
        >
          <FontAwesomeIcon icon={'arrow-right'} />
        </button>
      </div>
    </div>
  );
};

Paginator.propTypes = {
  pageSize: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  setPageSize: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  totalElements: PropTypes.number.isRequired,
  isTableLoading: PropTypes.bool.isRequired,
};
