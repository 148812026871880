import React from 'react';
import PropTypes from 'prop-types';
import { SEARCH_FIELDS } from '@core/search-fields';
import { useUsOptions, COMP_LOCATION_OPTIONS } from '@core/select-options';
import { FormMultiselect } from '@common/form-inputs';
import { FiltersContainer } from '../filters-container';
import styles from './basic-filters.module.scss';

export const BasicFilters = ({ control }) => {
  const { usOptions } = useUsOptions();

  return (
    <FiltersContainer
      title="Basic Filters"
      filterInclusionKey={SEARCH_FIELDS.basicFiltersInclusion.id}
      defaultValue={SEARCH_FIELDS.basicFiltersInclusion.defaultValue}
      control={control}
    >
      <div className={styles['row']}>
        <div className={styles['col']}>
          <FormMultiselect
            name={SEARCH_FIELDS.compState.id}
            label={SEARCH_FIELDS.compState.label}
            control={control}
            options={COMP_LOCATION_OPTIONS}
            defaultValue={SEARCH_FIELDS.compState.defaultValue}
            showConfirmationModal
          />

          <FormMultiselect
            name={SEARCH_FIELDS.us.id}
            label={SEARCH_FIELDS.us.label}
            control={control}
            options={usOptions}
            defaultValue={SEARCH_FIELDS.us.defaultValue}
            showConfirmationModal
          />
        </div>
      </div>
    </FiltersContainer>
  );
};

BasicFilters.propTypes = {
  control: PropTypes.object.isRequired,
};
