import { useState } from 'react';

export const useDropdownState = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdownState = () => {
    setDropdownOpen((dropdownOpen) => !dropdownOpen);
  };

  const closeDropdown = () => setDropdownOpen(false);

  return {
    dropdownOpen,
    toggleDropdownState,
    closeDropdown,
  };
};
