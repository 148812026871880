import React, { useEffect, useState } from 'react';

import { InlineMessageBlock } from '@backstop/react-core';

import SelectInput from '@common/input/SelectInput';
import Button from '@common/input/Button';
import { PageHeader } from '../headers/page-header';
import { useAwardSelectOptions } from './select-options';
import { searchRanks, useAwardSectorOptions } from './hooks';
import { AwardsTable } from './table';
import { Loader } from '@common/loader';

import styles from './awards.module.scss';

export const Awards = () => {
  const { ranks, getRanks, loading: loadingRanks } = searchRanks();

  const [rankData, setRanks] = useState([]);

  const {
    sectors,
    monthVal,
    entityVal,
    yearVal,
    sectorVal,
    setEntityVal,
    setYearVal,
    setMonthVal,
    setSectorVal,
    submitSearch,
    loading,
    submitError,
  } = useAwardSectorOptions(getRanks, setRanks);

  const {
    getMonthOptions,
    getYearOptions,
    getEntityOptions,
    getDefaultYearValue,
  } = useAwardSelectOptions();

  useEffect(() => {
    setRanks(ranks);
  }, [ranks]);

  const handleOnEntityChange = (val) => {
    setEntityVal(val);
    setMonthVal(undefined);
  };

  return (
    <div>
      <div className={styles['header']}>
        <PageHeader title="Preliminary Rankings">
          <div className={styles['form-container']}>
            <div className={styles['input-container']}>
              <div className={styles['flex-item']}>
                <p>Entity Type</p>
                <SelectInput
                  name="entityType"
                  placeholder="Choose one..."
                  options={getEntityOptions()}
                  onChange={handleOnEntityChange}
                  required={true}
                />
              </div>
              <div className={styles['flex-item']}>
                <p>Year</p>
                <SelectInput
                  name="year"
                  options={getYearOptions()}
                  defaultValue={getDefaultYearValue()}
                  onChange={setYearVal}
                  required={true}
                />
              </div>
              <div className={styles['flex-item']}>
                <p>Month</p>
                <SelectInput
                  name="month"
                  options={getMonthOptions(yearVal?.value, entityVal?.value)}
                  onChange={setMonthVal}
                  required={true}
                  value={monthVal}
                />
              </div>
              <div className={styles['flex-item']}>
                <p>Sector</p>
                <SelectInput
                  name="sector"
                  options={
                    sectors.length > 0
                      ? sectors
                      : [{ label: 'None', value: '' }]
                  }
                  onChange={setSectorVal}
                  required={true}
                  value={sectorVal}
                />
              </div>
            </div>
            <Button type="submit" onClick={submitSearch}>
              Search
            </Button>
          </div>
        </PageHeader>
        {loading || loadingRanks ? <Loader /> : null}
        {submitError ? (
          <InlineMessageBlock kind="error">
            You must fill out all fields
          </InlineMessageBlock>
        ) : null}
        <AwardsTable ranks={rankData} type={entityVal?.value} />
      </div>
    </div>
  );
};
