import React, { useState } from 'react';
import { useSearchParams } from '@redux/search-params';
import { useNavigate } from 'react-router-dom';
import { Button } from '@backstop/react-core';
import Delete from '@common/modals/Delete';
import { BasicTable } from '@common/basic-table';
import { Loader } from '@common/loader';
import { useSavedSearch } from './hooks';
import { useSelector } from 'react-redux';

export const SavedSearch = ({ setShowNameChange }) => {
  const navigate = useNavigate();
  const userDataState = useSelector((state) => state.userData.savedSearches);
  const { deleteSavedSearch, loading } = useSavedSearch();

  const { setFormParams, setActiveColumns } = useSearchParams();

  const [showDelete, setShowDelete] = useState(-1);

  const deleteAndClose = () => {
    setShowDelete(-1);
    deleteSavedSearch(showDelete);
  };

  const runSearch = (query) => {
    let jsonQ = JSON.parse(query);
    setActiveColumns(jsonQ.activeCols);
    setFormParams(jsonQ.formParams);
    navigate('/results');
  };

  if (loading) {
    return <Loader />;
  }

  if (userDataState?.length == 0) {
    return (
      <BasicTable.Row>
        <BasicTable.Cell alignLeft>No Saved Searches</BasicTable.Cell>
      </BasicTable.Row>
    );
  }

  return (
    <>
      <BasicTable id="test-table-id">
        {userDataState?.map((search, index) => (
          <BasicTable.Row key={index}>
            <BasicTable.Cell alignLeft>{search?.searchName}</BasicTable.Cell>
            <BasicTable.Cell alignRight>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button.EditIconButton
                  style={{ margin: '0rem 0.5rem' }}
                  version="v3"
                  type="save"
                  onClick={() => runSearch(search?.searchQuery)}
                />
                <Button.EditIconButton
                  style={{ margin: '0rem 0.5rem' }}
                  version="v3"
                  type="edit"
                  onClick={() =>
                    setShowNameChange(true, search?.searchName, search?.id)
                  }
                />
                <Button.EditIconButton
                  style={{ margin: '0rem 0.5rem' }}
                  version="v3"
                  type="cancel"
                  onClick={() => setShowDelete(search.id)}
                />
                {showDelete !== -1 ? (
                  <Delete
                    isOpen={showDelete}
                    toggleDelete={setShowDelete}
                    elementToDelete={search.id}
                    deleteFunction={deleteAndClose}
                    deleteHeader="Delete saved search?"
                    deleteMessage="Would you like to delete this saved search?"
                  />
                ) : null}
              </div>
            </BasicTable.Cell>
          </BasicTable.Row>
        ))}
      </BasicTable>
    </>
  );
};
