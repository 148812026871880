// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".icon--HN8HJ{margin-top:10px;margin-right:8px}", "",{"version":3,"sources":["webpack://./src/components/subscriber/download-button.module.scss"],"names":[],"mappings":"AAAA,aACE,eAAA,CACA,gBAAA","sourcesContent":[".icon {\n  margin-top: 10px;\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "icon--HN8HJ"
};
export default ___CSS_LOADER_EXPORT___;
