export const COMPARISON_METHOD_OPTIONS = [
  {
    value: 'between',
    label: 'Between',
  },
  {
    value: 'gt',
    label: 'Greater than',
  },
  {
    value: 'ge',
    label: 'Greater than or equal to',
  },
  {
    value: 'lt',
    label: 'Less than',
  },
  {
    value: 'le',
    label: 'Less than or equal to',
  },
];

export const DATATYPES = {
  date: 'date',
  decimal: 'decimal',
  money: 'money',
  percentage: 'percentage',
};
