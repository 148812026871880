import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useLifetimeReturnData } from './hooks';
import { Loader } from '@common/loader';

export const LifeTimeReturnsChart = ({ programData }) => {
  const { data, loading } = useLifetimeReturnData(programData?.id);

  if (loading) {
    return <Loader />;
  }

  if (data?.length == 0) {
    return <p>No returns found</p>;
  }
  let chartData = Array(21).fill(0);

  for (let i = 0; i < data?.length; i++) {
    let val = parseFloat(data[i].ror);
    if (val < -50) {
      val = -50;
    }
    if (val > 50) {
      val = 50;
    }
    //the chart libarary ends up ignoring index 0 so we need to shift all the buckets over one
    chartData[Math.floor((val + 50) / 5) + 1]++;
  }

  const options = {
    title: {
      text: 'Distribution Of Monthly Returns',
      align: 'left',
    },
    chart: { type: 'column' },
    yAxis: { title: { text: 'Number Of Months' } },
    xAxis: {
      type: 'categories',
      categories: [
        '<= -50%',
        '-50% To -45%',
        '-45% To -40%',
        '-40% To -35%',
        '-35% To -30%',
        '-30% To -25%',
        '-25% To -20%',
        '-20% To -15%',
        '-15% To -10%',
        '-10% To -5%',
        '-5% To 0%',
        '0% To 5%',
        '5% To 10%',
        '10% To 15%',
        '15% To 20%',
        '20% To 25%',
        '25% To 30%',
        '30% To 35%',
        '35% To 40%',
        '40% To 45%',
        '45% To 50%',
        '<= 50%',
      ],
    },

    credits: { enabled: false },

    series: [{ name: programData.name, data: chartData }],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

LifeTimeReturnsChart.propTypes = {
  programData: PropTypes.object.isRequired,
};
