import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@backstop/react-core';
import { FormCheckbox } from '@common/form-inputs';
import { AGREEMENT_TEXT } from './constants';

import styles from './terms-and-conditions.module.scss';

const titleText = 'Terms Of Service';

export const TermsAndConditions = ({ control, loading }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [agreementHasBeenOpened, setAgreementHasBeenOpened] = useState(false);
  const toggle = () => {
    if (!modalOpen && !agreementHasBeenOpened) {
      setAgreementHasBeenOpened(true);
    }
    setModalOpen((state) => !state);
  };

  const title = <div className={styles['title']}>{titleText}</div>;

  const footer = (
    <div className={styles['footer']}>
      <Button kind="cancel" version="v3" onClick={toggle}>
        Close
      </Button>
    </div>
  );

  return (
    <>
      <Modal
        version="v3"
        title={title}
        contentLabel={titleText}
        open={modalOpen}
        toggle={toggle}
        onRequestClose={toggle}
        closeOnOverlayClick={true}
        closeOnEscape={true}
        footer={footer}
      >
        <div dangerouslySetInnerHTML={{ __html: AGREEMENT_TEXT }} />
      </Modal>

      <Button version="v3" onClick={toggle}>
        Read the Agreement
      </Button>

      <FormCheckbox
        name="termsAndConditions"
        label=""
        control={control}
        checkboxLabel={
          <span>
            <strong>I've read and I Accept</strong> the privacy policy risk
            disclosure, terms of service and membership agreement
          </span>
        }
        defaultValue={false}
        rules={{
          required:
            'You need to accept the privacy policy risk disclosure, terms of service and membership agreement if you want to proceed',
        }}
        disabled={loading || !agreementHasBeenOpened}
      />
    </>
  );
};

TermsAndConditions.propTypes = {
  control: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};
