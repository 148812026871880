import * as types from './action-types';

export function userFavoriteFundsSet(data) {
  return {
    type: types.USER_FAVORITE_FUNDS_SET,
    data,
  };
}

export function userFavoriteFundsDelete(deleteId) {
  return {
    type: types.USER_FAVORITE_FUNDS_DELETE,
    deleteId,
  };
}

export function userFavoriteFundsAdd(progData) {
  return {
    type: types.USER_FAVORITE_FUNDS_ADD,
    data: progData,
  };
}

export function userSavedSearchesSet(data) {
  return {
    type: types.USER_SAVE_SEARCH_SET,
    data,
  };
}

export function userSavedSearchesAdd(searchData) {
  return {
    type: types.USER_SAVE_SEARCH_ADD,
    data: searchData,
  };
}

export function userSavedSearchesDelete(searchId) {
  return {
    type: types.USER_SAVE_SEARCH_DELETE,
    searchId,
  };
}

export function userSavedSearchUpdate(searchId, searchData) {
  return {
    type: types.USER_SAVE_SEARCH_UPDATE,
    id: searchId,
    data: searchData,
  };
}

export function userDataSet(data) {
  return {
    type: types.USER_DATA_SET,
    data,
  };
}

export function userDataLoadingSet(loading) {
  return {
    type: types.USER_DATA_LOADING_SET,
    loading,
  };
}

export function userRolesSet(roles) {
  return {
    type: types.USER_ROLES_SET,
    roles,
  };
}

export function userDataClean() {
  return {
    type: types.USER_DATA_CLEAN,
  };
}
