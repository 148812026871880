import React from 'react';
import PropTypes from 'prop-types';
import { DecimalPercentageFormatterNegativeHandler } from '@core/formatters';
import { BasicTable } from '@common/basic-table';

export const Instruments = ({ instruments }) => {
  return (
    <BasicTable>
      <BasicTable.Title colSpan="2">Instruments</BasicTable.Title>

      <BasicTable.Row>
        <BasicTable.Header>Instrument Name</BasicTable.Header>
        <BasicTable.Header>Average Holdings</BasicTable.Header>
      </BasicTable.Row>

      {instruments.map((instrument) => (
        <BasicTable.Row key={instrument.id}>
          <BasicTable.Cell alignLeft>{instrument.name}</BasicTable.Cell>
          <BasicTable.Cell alignLeft>
            <DecimalPercentageFormatterNegativeHandler
              value={instrument.value}
            />
          </BasicTable.Cell>
        </BasicTable.Row>
      ))}
    </BasicTable>
  );
};

Instruments.propTypes = {
  instruments: PropTypes.array.isRequired,
};
