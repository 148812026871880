import React, { useState } from 'react';
import { TextInput } from '@backstop/react-core';
import { SavedSearch } from '@common/table/save-search/saved-search';
import Confirm from '@common/modals/Confirm';
import { useSavedSearch } from '@common/table/save-search/hooks';

export const SaveSearchActions = () => {
  const { updateSavedSearch } = useSavedSearch();

  const [showNameChange, setShowNameChange] = useState(false);
  const [value, setValue] = useState('');
  const [editId, setEditId] = useState(-1);

  const setShowChangeNameValueId = (showName, value, id) => {
    setShowNameChange(showName);
    setValue(value);
    setEditId(id);
  };

  const onChange = (change) => {
    setValue(change);
  };

  const changeNameAndClose = (data) => {
    if (editId !== -1) {
      updateSavedSearch(editId, data);
    }
    setShowChangeNameValueId(false, '', -1);
  };

  return (
    <>
      <SavedSearch setShowNameChange={setShowChangeNameValueId} />
      <Confirm
        confirmParam={{
          searchName: value,
        }}
        confirmFunction={changeNameAndClose}
        isOpen={showNameChange}
        toggleCancel={() => setShowChangeNameValueId(false, '', -1)}
        header="Save Search"
      >
        <TextInput
          id="test-input-id"
          version="v3"
          name="searchName"
          placeholder="Search Name"
          onChange={onChange}
          value={value}
        />
      </Confirm>
    </>
  );
};
