import React from 'react';
import PropTypes from 'prop-types';
import { FormInclusion } from '@common/form-inputs';
import styles from './filters-container.module.scss';

export const FiltersContainer = ({
  children,
  title,
  filterInclusionKey,
  defaultValue,
  control,
}) => {
  return (
    <div className={styles['body']}>
      <div className={styles['header']}>
        <h2 className={styles['heading']}>{title}</h2>

        {filterInclusionKey && (
          <FormInclusion
            name={filterInclusionKey}
            control={control}
            defaultValue={defaultValue}
          />
        )}
      </div>
      {children}
    </div>
  );
};

FiltersContainer.propTypes = {
  title: PropTypes.string.isRequired,
  filterInclusionKey: PropTypes.string,
  defaultValue: PropTypes.string,
  control: PropTypes.object,
};
