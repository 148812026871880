import React from 'react';
import PropTypes from 'prop-types';
import { BasicTable } from '@common/basic-table';

export const AuditorInformation = ({ programData }) => {
  return (
    <BasicTable>
      <BasicTable.Title colSpan="2">Auditor Information</BasicTable.Title>

      <BasicTable.Row>
        <BasicTable.Header>Auditor</BasicTable.Header>
        <BasicTable.Cell>{programData.auditFirm}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Auditor Contact</BasicTable.Header>
        <BasicTable.Cell>{programData.auditContact}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Auditor Phone</BasicTable.Header>
        <BasicTable.Cell>{programData.auditContactPhone}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Audit Month</BasicTable.Header>
        <BasicTable.Cell>{programData.auditMonth}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Last Audit</BasicTable.Header>
        <BasicTable.Cell>{programData.lastAudit}</BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};

AuditorInformation.propTypes = {
  programData: PropTypes.object.isRequired,
};
