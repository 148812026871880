import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { paramsStorage, tokenStorage } from '@core/browser-storage';
import configureStore from '@redux/configure-store';
import { APIContextProvider } from '@api';
import { Login } from '@auth/login';
import { Register } from '@auth/register';
import { ForgotPassword } from '@auth/forgot-password';
import { ResetPassword } from '@auth/reset-password';

import { MainSearch } from '~/components/main-search';
import { Dashboard } from './components/dashboard';
import { Awards } from './components/awards/awards';
import { SearchResult } from '~/components/search-results';
import { Program } from './components/program';
import { GuardedRoute } from '~/components/auth/guarded-route';
import { AwardsDownload } from './components/awardsDownloads';
import { ComingSoonPage } from './components/coming-soon-page';
import {
  InternalServerError,
  PageNotFoundError,
  AifmdRestricted,
} from './components/error-pages';
import { Subscriber } from './components/subscriber/subscriber';
import { WindowSizeTracker } from './components/window-size-tracker';

import reportWebVitals from './reportWebVitals';
import App from './app';
import './index.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTrashAlt,
  faTimesCircle,
  faTimes,
  faUserEdit,
  faEye,
  faChevronDown,
  faChevronUp,
  faCaretDown,
  faCaretUp,
  faArrowRight,
  faArrowLeft,
  faSearch,
  faFlag,
  faStar,
  faOutdent,
  faUser,
  faBullhorn,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faTrashAlt,
  faTimesCircle,
  faTimes,
  faUserEdit,
  faChevronDown,
  faChevronUp,
  faCaretDown,
  faCaretUp,
  faEye,
  faArrowRight,
  faArrowLeft,
  faSearch,
  faFlag,
  faStar,
  faOutdent,
  faUser,
  faBullhorn
);

export const store = configureStore({
  request: {
    expire: tokenStorage.getTokenExpTime(),
    pendingRequests: [],
  },
  params: {
    ...paramsStorage.getParams(),
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <WindowSizeTracker />
      <BrowserRouter>
        <APIContextProvider>
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route path="/login/:redirectUrl" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route
              exact
              path="/reset-password/:token"
              element={<ResetPassword />}
            />
            <Route
              path="/"
              element={
                <GuardedRoute>
                  <App />
                </GuardedRoute>
              }
            >
              <Route path="/" element={<Navigate to="/search" replace />} />
              <Route path="dashboard" element={<ComingSoonPage />} />
              <Route path="search" element={<MainSearch />} />
              <Route path="results" element={<SearchResult />} />
              <Route path="awards" element={<Awards />} />
              <Route path="subscriber" element={<Subscriber />} />
              <Route exact path="/program/:id" element={<Program />} />
              <Route
                path="/awards/download/:downloadId"
                element={<AwardsDownload />}
              />
            </Route>
            <Route path="500" element={<InternalServerError />} />
            <Route path="aifmd" element={<AifmdRestricted />} />
            <Route path="*" element={<PageNotFoundError />} />
          </Routes>
        </APIContextProvider>
      </BrowserRouter>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
