import React from 'react';
import PropTypes from 'prop-types';
import { BasicTable } from '@common/basic-table';

export const Correlations = ({ programData }) => {
  return (
    <BasicTable>
      <BasicTable.Title colSpan="2">Correlations</BasicTable.Title>
      <BasicTable.Row>
        <BasicTable.Header>Barclay Hedge Fund Index</BasicTable.Header>
        <BasicTable.Cell>{programData.corrVsHf}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Barclay CTA Index</BasicTable.Header>
        <BasicTable.Cell>{programData.corrVsCta}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Barclay Fund of Funds Index</BasicTable.Header>
        <BasicTable.Cell>{programData.corrVsFof}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Barclay Equity Long/Short Index</BasicTable.Header>
        <BasicTable.Cell>{programData.corrVsEquity}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Dow Jones Industrial Average</BasicTable.Header>
        <BasicTable.Cell>{programData.corrVsDow}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>NASDAQ Composite Index</BasicTable.Header>
        <BasicTable.Cell>{programData.corrVsNasdaq}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>S&P 500 Total Return Index</BasicTable.Header>
        <BasicTable.Cell>{programData.corrVsSp500}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Russell 2000 Index</BasicTable.Header>
        <BasicTable.Cell>{programData.corrVsRussel}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>FTSE 100 Index</BasicTable.Header>
        <BasicTable.Cell>{programData.corrVsFeSe}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Nikkei 225 Index</BasicTable.Header>
        <BasicTable.Cell>{programData.corrVsNikkei}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>JP Morgan World Gov't Bond Index</BasicTable.Header>
        <BasicTable.Cell>{programData.corrVsJpMorgan}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>MSCI EAFE Index (USD)</BasicTable.Header>
        <BasicTable.Cell>{programData.corrVsEaFe}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>MSCI World Index (USD)</BasicTable.Header>
        <BasicTable.Cell>{programData.corrVsMsciWorld}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>US Treasury Bills</BasicTable.Header>
        <BasicTable.Cell>{programData.corrVsUsTreasury}</BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};

Correlations.propTypes = {
  programData: PropTypes.object.isRequired,
};
