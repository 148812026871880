import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sidebarCollapsedToggle } from '@redux/actions/layout-actions';

export const useLayoutData = () => {
  const dispatch = useDispatch();
  const { windowSize, sidebarCollapsed } = useSelector((state) => state.layout);

  const toggleSidebar = useCallback(
    () => dispatch(sidebarCollapsedToggle()),
    []
  );

  const layoutData = useMemo(
    () => ({
      windowSize,
      sidebarCollapsed,
      toggleSidebar,
    }),
    [windowSize, sidebarCollapsed, toggleSidebar]
  );

  return layoutData;
};
