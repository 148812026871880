import { useDispatch, useSelector } from 'react-redux';
import {
  columnOrderSet,
  formParamsSet,
  paramsSet,
  activeColumnsSet,
} from '@redux/actions/params-actions';

export const useSearchParams = () => {
  const dispatch = useDispatch();
  const params = useSelector((state) => state.params);

  const setParams = (value) => dispatch(paramsSet(value));
  const setFormParams = (value) => dispatch(formParamsSet(value));
  const setColumnOrder = (value) => dispatch(columnOrderSet(value));
  const setActiveColumns = (value) => dispatch(activeColumnsSet(value));

  return {
    params,
    formParams: params.formParams,
    columnOrder: params.columnOrder,
    activeColumns: params.activeCols,
    setParams,
    setFormParams,
    setColumnOrder,
    setActiveColumns,
  };
};
