import React from 'react';
import PropTypes from 'prop-types';
import { SEARCH_FIELDS } from '@core/search-fields';
import {
  useDenominationOptions,
  useExchangeListOptions,
  OPEN_CLOSED_OPTIONS,
  HIGH_WATER_MARK_OPTIONS,
  GEO_FOCUS_OPTIONS,
  REPORTING_BASIS_OPTIONS,
} from '@core/select-options';
import { WINDOW_SIZES } from '@core/constants';
import { useLayoutData } from '@core/layout';
import { FormMultiselect, FormSelect } from '@common/form-inputs';
import { FiltersContainer } from '../filters-container';
import { DENOMINATION_HELP_TEXT, OPEN_CLOSED_HELP_TEXT } from './constants';
import styles from './detailed-filters.module.scss';
import classnames from 'classnames';

export const DetailedFilters = ({ control }) => {
  const { windowSize } = useLayoutData();
  const { denominations } = useDenominationOptions();
  const { exchangeListOptions } = useExchangeListOptions();

  const rowStyles = classnames(styles['row'], {
    [styles['row--collapsed']]: windowSize < WINDOW_SIZES.MD,
  });

  return (
    <FiltersContainer
      title="Detailed Filters"
      filterInclusionKey={SEARCH_FIELDS.detailedFiltersInclusion.id}
      defaultValue={SEARCH_FIELDS.detailedFiltersInclusion.defaultValue}
      control={control}
    >
      <div className={rowStyles}>
        <div className={styles['col']}>
          <FormSelect
            name={SEARCH_FIELDS.openClosed.id}
            label={SEARCH_FIELDS.openClosed.label}
            tooltipText={OPEN_CLOSED_HELP_TEXT}
            control={control}
            defaultValue={SEARCH_FIELDS.openClosed.defaultValue}
            options={OPEN_CLOSED_OPTIONS}
            showConfirmationModal
          />

          <FormMultiselect
            name={SEARCH_FIELDS.listedOnExchange.id}
            label={SEARCH_FIELDS.listedOnExchange.label}
            control={control}
            options={exchangeListOptions}
            defaultValue={SEARCH_FIELDS.listedOnExchange.defaultValue}
            showConfirmationModal
          />

          <FormSelect
            name={SEARCH_FIELDS.highwater.id}
            label={SEARCH_FIELDS.highwater.label}
            control={control}
            defaultValue={SEARCH_FIELDS.highwater.defaultValue}
            options={HIGH_WATER_MARK_OPTIONS}
            showConfirmationModal
          />
        </div>
        <div className={styles['col']}>
          <FormMultiselect
            name={SEARCH_FIELDS.denomination.id}
            label={SEARCH_FIELDS.denomination.label}
            tooltipText={DENOMINATION_HELP_TEXT}
            control={control}
            options={denominations}
            defaultValue={SEARCH_FIELDS.denomination.defaultValue}
            showConfirmationModal
          />

          <FormMultiselect
            name={SEARCH_FIELDS.geoFocus.id}
            label={SEARCH_FIELDS.geoFocus.label}
            control={control}
            options={GEO_FOCUS_OPTIONS}
            defaultValue={SEARCH_FIELDS.geoFocus.defaultValue}
            showConfirmationModal
          />

          <FormMultiselect
            name={SEARCH_FIELDS.reportingBasis.id}
            label={SEARCH_FIELDS.reportingBasis.label}
            control={control}
            options={REPORTING_BASIS_OPTIONS}
            defaultValue={SEARCH_FIELDS.reportingBasis.defaultValue}
            showConfirmationModal
          />
        </div>
      </div>
    </FiltersContainer>
  );
};

DetailedFilters.propTypes = {
  control: PropTypes.object.isRequired,
};
