import PropTypes from 'prop-types';
import { dataUnavailable, toDays } from '@core/formatters';

export const DaysFormatter = ({ value }) => {
  return dataUnavailable(toDays(value));
};

DaysFormatter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
