import { useEffect } from 'react';
import api from '@api';

export const useCompanyData = (id) => {
  const { getCompanyData, data, loading, isInitialLoad, error } =
    api.company.useCompanyData();

  useEffect(() => {
    getCompanyData(id);
  }, [id]);

  return { data, loading, isInitialLoad, error };
};
