import { useEffect } from 'react';
import api from '@api';

export const useRegistration = (handleSubmit) => {
  const { register, loading, success, error } = api.auth.useRegister();

  const getRegistrationHandler = () => {
    const handleRgistration = handleSubmit((data) => {
      register({
        ...data,
        country: data.country.value,
        database: data.database.value,
        professionalField: data.professionalField.value,
        path: window.location.origin + '/reset-password/',
      });
    });

    return (e) => {
      if (loading) {
        e.preventDefault();
        return;
      } else {
        handleRgistration(e);
      }
    };
  };

  return { getRegistrationHandler, loading, success, error };
};

export const useDbSampleOptions = () => {
  const { getDbSampleOptions, dbSampleOptions } =
    api.select.useFreeDatabaseSampleOptions();

  useEffect(() => {
    getDbSampleOptions();
  }, []);

  return { dbSampleOptions };
};

export const useProfessionalFieldOptions = () => {
  const { getProfessionalFieldOptions, professionalFieldOptions } =
    api.select.useProfessionalFieldOptions();

  useEffect(() => {
    getProfessionalFieldOptions();
  }, []);

  return { professionalFieldOptions };
};

export const useCountryOptions = () => {
  const { getCountryOptions, countryOptions } = api.select.useCountryOptions();

  useEffect(() => {
    getCountryOptions();
  }, []);

  return { countryOptions };
};
