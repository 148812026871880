export const OPEN_CLOSED_OPTIONS = [
  {
    value: '',
    label: 'All- Open and Closed',
  },
  {
    value: 'Open',
    label: 'Open Funds',
  },
  {
    value: 'Closed',
    label: 'Closed Funds',
  },
];
