import React from 'react';
import PropTypes from 'prop-types';
import { BasicTable } from '@common/basic-table';

export const LegalInformation = ({ programData }) => {
  return (
    <BasicTable>
      <BasicTable.Title colSpan="2">Legal Information</BasicTable.Title>

      <BasicTable.Row>
        <BasicTable.Header>Firm</BasicTable.Header>
        <BasicTable.Cell>{programData.legalFirm}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Legal Contact</BasicTable.Header>
        <BasicTable.Cell>{programData.legalContact}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Legal Phone</BasicTable.Header>
        <BasicTable.Cell>{programData.legalContactPhone}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Legal Email</BasicTable.Header>
        <BasicTable.Cell>{programData.legalContactEmail}</BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};

LegalInformation.propTypes = {
  programData: PropTypes.object.isRequired,
};
