export const requiredFieldMessage = 'Field is required';

export const VALIDATORS = {
  getRequiredOptionValidator:
    (message = requiredFieldMessage) =>
    (option) =>
      !option || option.value === undefined || option.value === null
        ? message
        : null,
};

export const REGEX = {
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  digit: /[0-9]/,
  specialCharacter: /[`~!@#$^&*()\-_+=[\]{}:;"'|\\<>,.\/?]/,
};

export const isNumeric = (value) => {
  return !isNaN(parseFloat(value)) && isFinite(value);
};

export const isString = (value) =>
  Object.prototype.toString.call(value) === '[object String]';

export const isObject = (value) => {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
};

export const hasValue = (value) => value !== undefined && value !== null;

export const isEmpty = (value) =>
  !hasValue(value) ||
  value === '' ||
  value === 'null' ||
  (Array.isArray(value) && value.length === 0) ||
  (isObject(value) && Object.keys(value).length === 0);

export const isDate = (value) => Date.parse(value) !== NaN;
