import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@backstop/react-core';

import { useUserData } from '@core/user';
import styles from './footer.module.scss';

const goToSubscriptions = () => {
  window.open(
    'https://www.barclayhedge.com/databases/global-database',
    '_blank'
  );
};

export const Footer = ({ onApply, onCancel }) => {
  const { isFreeUser } = useUserData();

  return (
    <>
      {isFreeUser ? (
        <Button
          type="button"
          kind="primary"
          version="v3"
          onClick={goToSubscriptions}
        >
          Upgrade to PRO to apply filter
        </Button>
      ) : (
        <Button kind="primary" version="v3" onClick={onApply}>
          Apply
        </Button>
      )}

      <Button
        kind="cancel"
        version="v3"
        onClick={onCancel}
        className={styles['button']}
      >
        Cancel
      </Button>
    </>
  );
};

Footer.propTypes = {
  onApply: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
