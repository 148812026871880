import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { InlineMessageBlock } from '@backstop/react-core';

import { AuthPageBase } from '@auth/auth-page-base';
import { RegisterNow } from '@auth/register-now';
import { ForgotPasswordForm } from './forgot-password-form';

import styles from './forgot-password.module.scss';

export const ForgotPassword = () => {
  const [emailSentTo, setEmailSentTo] = useState('');

  return (
    <AuthPageBase>
      {!emailSentTo ? (
        <ForgotPasswordForm setEmail={setEmailSentTo} />
      ) : (
        <InlineMessageBlock kind="success">
          The password reset link has been sent to{' '}
          <strong>{emailSentTo}</strong>
        </InlineMessageBlock>
      )}

      <Link to="/login" className={styles['return-to-login']}>
        Return to login
      </Link>

      <RegisterNow />
    </AuthPageBase>
  );
};
