// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".body--ZDzMx{display:flex}.body--ZDzMx .tooltip-icon--OkXNs{margin-top:10px;margin-left:12px;margin-right:10px}", "",{"version":3,"sources":["webpack://./src/components/common/input-with-tooltip/input-with-tooltip.module.scss"],"names":[],"mappings":"AAAA,aACE,YAAA,CAEA,kCACE,eAAA,CACA,gBAAA,CACA,iBAAA","sourcesContent":[".body {\n  display: flex;\n\n  .tooltip-icon {\n    margin-top: 10px;\n    margin-left: 12px;\n    margin-right: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "body--ZDzMx",
	"tooltip-icon": "tooltip-icon--OkXNs"
};
export default ___CSS_LOADER_EXPORT___;
