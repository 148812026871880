import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { InlineMessageBlock } from '@backstop/react-core';

import { AuthPageBase } from '@auth/auth-page-base';
import { ResetPasswordForm } from './reset-password-form';

export const ResetPassword = () => {
  const [success, setSuccess] = useState(false);

  return (
    <AuthPageBase>
      {success ? (
        <InlineMessageBlock kind="success">
          Your password has been reset successfully.
          <Link to="/login"> Go to login</Link>
        </InlineMessageBlock>
      ) : (
        <ResetPasswordForm setSuccess={setSuccess} />
      )}
    </AuthPageBase>
  );
};
