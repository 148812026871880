import React from 'react';
import PropTypes from 'prop-types';
import { BasicTable } from '@common/basic-table';

const totalCols = 6;
const widths = Array(totalCols).fill(`${100 / totalCols}%`);

export const ContactInformation = ({ contact }) => {
  return (
    <>
      <BasicTable widths={widths}>
        <BasicTable.Title colSpan={totalCols}>{contact.name}</BasicTable.Title>

        <BasicTable.Row>
          <BasicTable.Header>Email</BasicTable.Header>
          <BasicTable.Cell hasBorderRight alignLeft>
            {contact.email}
          </BasicTable.Cell>
          <BasicTable.Header>Phone</BasicTable.Header>
          <BasicTable.Cell hasBorderRight alignLeft>
            {contact.phone}
          </BasicTable.Cell>
          <BasicTable.Header>Fax</BasicTable.Header>
          <BasicTable.Cell hasBorderRight alignLeft>
            {contact.fax}
          </BasicTable.Cell>
        </BasicTable.Row>

        <BasicTable.Row>
          <BasicTable.Header>Position</BasicTable.Header>
          <BasicTable.Cell hasBorderRight alignLeft>
            {contact.position}
          </BasicTable.Cell>
          <BasicTable.Header>Age</BasicTable.Header>
          <BasicTable.Cell hasBorderRight alignLeft>
            {contact.age}
          </BasicTable.Cell>
          <BasicTable.Header>Education</BasicTable.Header>
          <BasicTable.Cell hasBorderRight alignLeft>
            {contact.education}
          </BasicTable.Cell>
        </BasicTable.Row>

        <BasicTable.Row>
          <BasicTable.Header>Bio</BasicTable.Header>
          <BasicTable.Cell colSpan={totalCols - 1} alignLeft>
            {contact.bio}
          </BasicTable.Cell>
        </BasicTable.Row>
      </BasicTable>
    </>
  );
};

ContactInformation.propTypes = {
  contact: PropTypes.object.isRequired,
};
