import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@backstop/react-core';
import { PAGE_SIZE_OPTIONS } from '@core/constants';
import { isKeyEnter } from '@core/keyboard-helpers';
import './page-size-selector.css';

const options = [
  PAGE_SIZE_OPTIONS[50],
  PAGE_SIZE_OPTIONS[100],
  PAGE_SIZE_OPTIONS[150],
  PAGE_SIZE_OPTIONS[200],
  PAGE_SIZE_OPTIONS[250],
  PAGE_SIZE_OPTIONS[300],
];

export const PageSizeSelector = ({ pageSize, setPageSize }) => {
  const handleKeyDown = (e, option) => isKeyEnter(e) && setPageSize(option);

  return (
    <div className="page-size-selector">
      <span className="page-size-selector__label">Items displayed</span>
      <Button.DropdownButton
        label={`${pageSize.value}`}
        kind="secondary"
        size="small"
      >
        {options.map((option) => (
          <Button.MenuOption
            key={option.value}
            onClick={() => setPageSize(option)}
            onKeyDown={(e) => handleKeyDown(e, option)}
          >
            {option.label}
          </Button.MenuOption>
        ))}
      </Button.DropdownButton>
    </div>
  );
};

PageSizeSelector.propTypes = {
  pageSize: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  setPageSize: PropTypes.func.isRequired,
};
