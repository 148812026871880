import PropTypes from 'prop-types';
import { PROGRAM_TYPES } from '@core/constants';
import { dataUnavailable } from '@core/formatters';
import {
  CTA_STRATEGY_OPTIONS,
  HEDGE_FUND_STRATEGY_OPTIONS,
} from '@core/select-options';
import { getFlatList } from '@common/multiselect-dropdown';

const getStrategyLabels = (data, options) => {
  const strategies = [];
  getFlatList(options).forEach((option) => {
    if (data[option.id] && Number(data[option.id]) === 1) {
      strategies.push(option.optionLabel);
    }
  });

  return strategies.join(', ');
};

export const StrategiesFormatter = ({ row }) => {
  const isHedgeFund = row.type === PROGRAM_TYPES.HedgeFund;
  const options = isHedgeFund
    ? HEDGE_FUND_STRATEGY_OPTIONS
    : CTA_STRATEGY_OPTIONS;
  const strategies = getStrategyLabels(row, options);
  return dataUnavailable(strategies);
};

StrategiesFormatter.propTypes = {
  row: PropTypes.object.isRequired,
};
