import React from 'react';
import PropTypes from 'prop-types';
import { Disclaimer } from '../../disclaimer';
import { hasProAccess } from '../requires-pro-access';
import { TabBase } from '../tab-base';
import { VamiChart } from './charts';
import { AccountVehicleInformation } from './tables/account-vehicle-information';
import { PerformanceAnalysis } from './tables/performance-analysis';
import { TradingMethodStrategy } from './tables/trading-method-strategy';
import { AdministrativeInformation } from './tables/administrative-information';
import { YearAnnualReturnsFum } from './tables/year-annual-returns-fum';
import { CorrelationAnalysis } from './tables/correlation-analysis';
import { CalendarYearReturns } from '../performance-tab/tables/calendar-year-returns';
import { ProPerformanceAnalysis } from './tables/pro-performance-analysis';
import styles from './snapshot-tab.module.scss';

export const SnapshotTab = ({ programData }) => {
  return (
    <TabBase>
      {!hasProAccess(programData) ? (
        <>
          {/* Snapshot Essentials */}
          <TradingMethodStrategy programData={programData} />

          <div className={styles['row']}>
            <AdministrativeInformation programData={programData} />
            <YearAnnualReturnsFum programData={programData} />
          </div>

          <div className={styles['row']}>
            <PerformanceAnalysis programData={programData} />
            <CorrelationAnalysis programData={programData} />
          </div>
        </>
      ) : (
        <>
          {/* Snapshot PRO */}
          <CalendarYearReturns programData={programData} />

          <div className={styles['row']}>
            <ProPerformanceAnalysis programData={programData} />
            <AccountVehicleInformation programData={programData} />
          </div>

          <VamiChart programData={programData} />

          <TradingMethodStrategy programData={programData} />
        </>
      )}

      <Disclaimer />
    </TabBase>
  );
};

SnapshotTab.propTypes = {
  programData: PropTypes.object.isRequired,
};
