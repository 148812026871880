import { useCallback } from 'react';
import { useBaseGet } from './base';

export const useUserData = () => {
  const { baseGet, data, ...rest } = useBaseGet();

  const getUserData = useCallback(() => {
    baseGet(`/api/auth/self`);
  }, []);

  return {
    getUserData,
    userData: data?.data,
    ...rest,
  };
};

export const useRoles = () => {
  const { baseGet, data, ...rest } = useBaseGet();

  const getRoles = useCallback(() => {
    baseGet(`/api/auth/roles`);
  }, []);

  return {
    getRoles,
    roles: data?.data || [],
    ...rest,
  };
};
