import React from 'react';
import PropTypes from 'prop-types';
import {
  DateFormatter,
  DecimalPercentageFormatter,
  DataUnavailableFormatter,
  DecimalFormatter,
} from '@core/formatters';
import { BasicTable } from '@common/basic-table';

export const ProPerformanceAnalysis = ({ programData }) => {
  return (
    <BasicTable>
      <BasicTable.Title colSpan="2">Performance analysis</BasicTable.Title>

      <BasicTable.Row>
        <BasicTable.Header>Start Date</BasicTable.Header>
        <BasicTable.Cell>
          <DateFormatter value={programData.inceptionDate} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Last Reported RoR</BasicTable.Header>
        <BasicTable.Cell>
          <DateFormatter value={programData.dynamicEndDate} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Total Return Since Inception</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatter value={programData.totalReturn} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Compound Annual Return</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatter
            value={programData.compoundAnnualReturn}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Skewness</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalFormatter value={programData.skewness} />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Avg. Gain</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatter value={programData.avgGain} />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Avg. Loss</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatter value={programData.avgLoss} />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Winning Months</BasicTable.Header>
        <BasicTable.Cell>
          <DataUnavailableFormatter value={programData.winMonths} />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Losing Months</BasicTable.Header>
        <BasicTable.Cell>
          <DataUnavailableFormatter value={programData.loseMonths} />
        </BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};

ProPerformanceAnalysis.propTypes = {
  programData: PropTypes.object.isRequired,
};
