import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { DropdownMenu, ItemDivider } from '@backstop/react-core';
import { FilterDropdownContextProvider } from './filter-dropdown-context';
import { ArrowIcon } from './arrow-icon';
import { Placeholder } from './placeholder';
import { ButtonControls } from './button-controls';
import { useDisplayValue, useDropdownState } from './hooks';
import styles from './filter-dropdown-container.module.scss';
import classNames from 'classnames';

export const FilterDropdownContainer = ({
  label,
  children,
  name,
  className,
  getDisplayName,
  options,
  value,
  onChange,
  onBlur,
  placeholder,
  hideArrow,
  mountInline,
  hasError,
}) => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => setLocalValue(value), [value]);

  const { dropdownOpen, openDropdown, closeDropdown } =
    useDropdownState(onBlur);

  const handleDropdownClose = () => {
    setLocalValue(value);
    closeDropdown();
  };

  const toggleDropdownState = () => {
    if (dropdownOpen) {
      handleDropdownClose();
    } else {
      openDropdown();
    }
  };

  const { displayValue, fullValueString } = useDisplayValue({
    options,
    value,
    getDisplayName,
  });

  const handleApply = () => {
    onChange(localValue);
    closeDropdown();
  };

  const handleCancel = () => {
    handleDropdownClose();
  };

  const buttonRef = useRef(null);
  const getDropdownMinWidth = () => {
    if (buttonRef.current) return buttonRef.current.clientWidth;
    else return 'unset';
  };

  const context = {
    localValue,
    setLocalValue,
    handleApply,
  };

  const buttonClasses = classNames(
    styles['button'],
    {
      [styles['button--has-error']]: hasError,
    },
    className
  );

  return (
    <Fragment>
      <button
        version="v3"
        type="button"
        name={name}
        ref={buttonRef}
        aria-haspopup={true}
        aria-expanded={dropdownOpen}
        onClick={toggleDropdownState}
        onBlur={onBlur}
        className={buttonClasses}
        title={fullValueString}
      >
        {displayValue || <Placeholder value={placeholder} />}
        {!hideArrow && <ArrowIcon />}
      </button>
      <DropdownMenu
        version="v3"
        className={styles['filter-dropdown__menu']}
        onClose={handleDropdownClose}
        open={dropdownOpen}
        target={buttonRef}
        minWidth={getDropdownMinWidth()}
        style={{ paddingBottom: '0' }}
        container={mountInline && 'inline'}
      >
        <div className={styles['filter-dropdown__content-wrapper']}>
          <h3 className={styles['filter-dropdown__label']}>{label}</h3>

          <FilterDropdownContextProvider value={context}>
            {children}
          </FilterDropdownContextProvider>
          <div className={styles['filter-dropdown__controls']}>
            <ItemDivider />
            <ButtonControls
              handleApply={handleApply}
              handleCancel={handleCancel}
            />
          </div>
        </div>
      </DropdownMenu>
    </Fragment>
  );
};

FilterDropdownContainer.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  getDisplayName: PropTypes.func.isRequired,
  options: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  hideArrow: PropTypes.bool,
  mountInline: PropTypes.bool,
  hasError: PropTypes.bool,
};
