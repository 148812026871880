import React from 'react';
import PropTypes from 'prop-types';
import { TabBase } from '../tab-base';
import { RequiresProAccess, hasProAccess } from '../requires-pro-access';
import { DrawdownReport } from './tables/drawdown-report';
import { PerformanceAnalysis } from './tables/performance-analysis';
import { RelativeVolatility } from './tables/relative-volatility';
import { RiskRewardRatios } from './tables/risk-reward-ratios';
import { TimeWindows } from './tables/time-windows';
import { Correlations } from './tables/correlations';
import styles from './statistics-risk-tab.module.scss';
import { Last10DownCaptureChartVsSp500 } from './charts/down-capture';
import { Last10UpCaptureChartVsSp500 } from './charts/up-capture';
import { useLayoutData } from '@core/layout';
import { WINDOW_SIZES } from '@core/constants';
import classnames from 'classnames';

export const StatisticsRiskTab = ({ programData }) => {
  const { windowSize } = useLayoutData();

  const rowStyles = classnames(styles['row'], {
    [styles['row--collapsed']]: windowSize < WINDOW_SIZES.MD,
  });
  if (!hasProAccess(programData)) return <RequiresProAccess />;

  return (
    <TabBase>
      <div className={rowStyles}>
        <PerformanceAnalysis programData={programData} />
        <TimeWindows programData={programData} />
      </div>
      <div className={rowStyles}>
        <div className={styles['col']}>
          <Correlations programData={programData} />
        </div>
        <div className={styles['col']}>
          <RiskRewardRatios programData={programData} />
        </div>
      </div>
      {/* <DrawdownReport programData={programData} /> */}
      {/* <RelativeVolatility programData={programData} /> */}

      <Last10UpCaptureChartVsSp500
        progData={programData?.last10UpCapVsSp500Chart.progVsSp500UpCap}
        spData={programData?.last10UpCapVsSp500Chart.sp500Last10UpCap}
        progName={programData?.name}
      />

      <Last10DownCaptureChartVsSp500
        progData={programData?.last10DownCapVsSp500Chart.progVsSp500DownCap}
        spData={programData?.last10DownCapVsSp500Chart.sp500Last10DownCap}
        progName={programData?.name}
      />
    </TabBase>
  );
};

StatisticsRiskTab.propTypes = {
  programData: PropTypes.object.isRequired,
};
