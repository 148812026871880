import React, {
  Fragment,
  useState,
  useRef,
  useLayoutEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import { Checkbox, CheckboxGroup } from '@backstop/react-core';
import { ChevronExpander } from '@common/chevron-expander';

import { useSelectedCountContext } from './selected-count-context';
import {
  applyChildChange,
  applyParentUpdate,
  getCheckedChildren,
  checkAllDescendants,
  getIsParentChecked,
  isItemChecked,
  isParentItem,
  getFlatList,
} from './helpers';

import styles from './checkbox-parent.module.scss';

export const CheckBoxParent = ({
  item,
  selectedItemsScoped,
  onChange,
  limitReached,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [groupAreaHeight, setGroupAreaHeight] = useState();
  const groupRef = useRef();
  const setCheckedItems = (checkedItems) => onChange(item.id, checkedItems);

  useLayoutEffect(() => {
    const observer = new ResizeObserver((obj) => {
      const newHeight = obj[0].contentRect.height;
      setGroupAreaHeight(newHeight);
    });
    observer.observe(groupRef.current);

    return () => observer.disconnect();
  }, []);

  const handleParentChange = (id, checkedChildren) => {
    const newSelectedItems = applyParentUpdate({
      parentId: id,
      checkedChildren,
      selectedItems: selectedItemsScoped,
      parentItem: item,
    });

    setCheckedItems(newSelectedItems);
  };

  const handleChildChange = (e, clickedItem) => {
    const isChecked = e.target.checked;
    const newSelectedItems = applyChildChange({
      id: clickedItem.id,
      isChecked,
      selectedItems: selectedItemsScoped,
      items: item.children,
    });

    setCheckedItems(newSelectedItems);
  };

  const handleCheckAll = (e) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      const selected = checkAllDescendants(item);
      setCheckedItems(selected);
    } else {
      setCheckedItems([]);
    }
  };

  const { selectedCount, maxOptionsSelected } = useSelectedCountContext();
  const childrenCount = useMemo(
    () => getFlatList(item.children).length,
    [item.children]
  );
  const wouldBeOverLimit = selectedCount + childrenCount > maxOptionsSelected;

  return (
    <Fragment>
      <div className={styles['parent-checkbox']}>
        <ChevronExpander
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
        />
        <Checkbox
          label={item.optionLabel}
          version="v3"
          checked={getIsParentChecked(selectedItemsScoped, item)}
          onChange={(e) => handleCheckAll(e)}
          className={styles['checkbox']}
          disabled={wouldBeOverLimit}
        />
      </div>
      <CheckboxGroup
        alignment="vertical"
        className={styles['checkbox-group']}
        style={{ height: isExpanded ? groupAreaHeight : 0 }}
      >
        <div ref={groupRef} className={styles['group-area']}>
          {item.children.map((child) =>
            isParentItem(child) ? (
              <CheckBoxParent
                key={child.id}
                item={child}
                selectedItemsScoped={getCheckedChildren(
                  selectedItemsScoped,
                  child.id
                )}
                onChange={handleParentChange}
                limitReached={limitReached}
              />
            ) : (
              <Checkbox
                key={child.id}
                checked={isItemChecked(selectedItemsScoped, child.id)}
                name={child.optionLabel}
                label={child.optionLabel}
                version="v3"
                onChange={(e) => handleChildChange(e, child)}
                className={styles['child-item']}
                disabled={
                  limitReached && !isItemChecked(selectedItemsScoped, child.id)
                }
              />
            )
          )}
        </div>
      </CheckboxGroup>
    </Fragment>
  );
};

const itemPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  optionLabel: PropTypes.string.isRequired,
  children: PropTypes.array,
});

const selectedItemsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    children: PropTypes.array,
  })
);

CheckBoxParent.propTypes = {
  item: itemPropTypes,
  selectedItemsScoped: selectedItemsPropTypes,
  onChange: PropTypes.func.isRequired,
  limitReached: PropTypes.bool,
};
