// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filter-type-selectors--d2WtJ{display:flex;gap:15px}.filter-type-selectors--d2WtJ .form-control--cGBll{display:flex;width:100%}.filter-type-selectors--d2WtJ .input--DihNP{width:100%}.filter-type-selectors--d2WtJ .vs--kFMyo{line-height:36px;margin-top:18px}", "",{"version":3,"sources":["webpack://./src/components/main-search/custom-filters/custom-filter-modal/filter-type-selectors/filter-type-selectors.module.scss"],"names":[],"mappings":"AAAA,8BACE,YAAA,CACA,QAAA,CAEA,mDACE,YAAA,CACA,UAAA,CAGF,4CACE,UAAA,CAGF,yCACE,gBAAA,CACA,eAAA","sourcesContent":[".filter-type-selectors {\n  display: flex;\n  gap: 15px;\n\n  .form-control {\n    display: flex;\n    width: 100%;\n  }\n\n  .input {\n    width: 100%;\n  }\n\n  .vs {\n    line-height: 36px;\n    margin-top: 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filter-type-selectors": "filter-type-selectors--d2WtJ",
	"form-control": "form-control--cGBll",
	"input": "input--DihNP",
	"vs": "vs--kFMyo"
};
export default ___CSS_LOADER_EXPORT___;
