import React from 'react';
import PropTypes from 'prop-types';
import logoLarge from 'assets/logos/logo-large-white.svg';

import styles from './auth-page-base.module.scss';
import classnames from 'classnames';

export const AuthPageBase = ({ children, className }) => {
  const classes = classnames(styles['container'], className);
  return (
    <div className={styles['body']}>
      <div className={classes}>
        <div className={styles['header']}>
          <a href="https://www.barclayhedge.com">
            <img loading="eager" src={logoLarge} alt="Barclay Hedge logo" width={150} height={80} />
          </a>
        </div>
        <div className={styles['content']}>{children}</div>
      </div>
    </div>
  );
};

AuthPageBase.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
