import { DATATYPES } from '../constants';

export const COMPARISON_OPTIONS = [
  {
    value: 'alpha',
    label: 'Alpha',
    datatype: DATATYPES.percentage,
    vs: true,
  },
  {
    value: 'beta',
    label: 'Beta',
    datatype: DATATYPES.percentage,
    vs: true,
  },
  {
    value: 'corr',
    label: 'Correlation',
    datatype: DATATYPES.percentage,
    vs: true,
  },
  {
    value: 'rSq',
    label: 'R^2',
    datatype: DATATYPES.percentage,
    vs: true,
  },
  {
    value: 'downCap',
    label: 'Down Capture',
    datatype: DATATYPES.percentage,
    vs: true,
  },
  {
    value: 'upCap',
    label: 'Up Capture',
    datatype: DATATYPES.percentage,
    vs: true,
  },
];
