import { useCallback } from 'react';
import { useBaseGet } from './base';

export const useProducts = () => {
  const { baseGet, data, ...rest } = useBaseGet();

  const getProducts = useCallback(() => {
    baseGet(`/products`);
  }, []);

  return {
    getProducts,
    data: data?.data,
    ...rest,
  };
};

export const useFileDownloadToken = () => {
  const { baseGet, data, ...rest } = useBaseGet();

  const downloadToken = useCallback((productName) => {
    baseGet(`/products/download/${productName}`);
  }, []);

  return {
    downloadToken,
    token: data?.data,
    ...rest,
  };
};
