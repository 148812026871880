import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Footer } from '~/components/footer';
import styles from './error-page-base.module.scss';

export const ErrorPageBase = ({ title, message }) => {
  return (
    <div className={styles['body']}>
      <div className={styles['content-wrapper']}>
        <h2 className={styles['title']}>{title}</h2>
        <hr />

        <h3 className={styles['message']}>{message}</h3>

        <ul className={styles['links']}>
          <li className={styles['link-wrapper']}>
            Return to{' '}
            <Link to="/" className={styles['link']}>
              FundFinder
            </Link>
          </li>
          <li>
            Return to{' '}
            <a href="https://www.barclayhedge.com/" className={styles['link']}>
              Home Page
            </a>
          </li>
        </ul>
      </div>
      <div className={styles['footer']}>
        <Footer />
      </div>
    </div>
  );
};

ErrorPageBase.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};
