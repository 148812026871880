import { DATATYPES } from '../constants';

const currentYear = new Date().getFullYear();

const ANNUALIZED_RETURNS = [
  {
    value: 'compoundAnnualReturn3year',
    label: '3 Year',
    fullLabel: 'Annualized returns for 3 Years',
    datatype: DATATYPES.percentage,
  },
  {
    value: 'compoundAnnualReturn5year',
    label: '5 Year',
    fullLabel: 'Annualized returns for 5 Years',
    datatype: DATATYPES.percentage,
  },
  {
    value: 'compoundAnnualReturn7year',
    label: '7 Year',
    fullLabel: 'Annualized returns for 7 Years',
    datatype: DATATYPES.percentage,
  },
  {
    value: 'compoundAnnualReturn10year',
    label: '10 Year',
    fullLabel: 'Annualized returns for 10 Years',
    datatype: DATATYPES.percentage,
  },
  {
    value: 'compoundAnnualReturn',
    label: 'Compound Annual Return',
    fullLabel: 'Comp. Ann. Return',
    datatype: DATATYPES.percentage,
  },
];

const COMPOUNDED_RETURNS = [
  {
    value: 'yearToDate',
    label: 'Year to Date (YTD)',
    fullLabel: 'Compounded return for Year to Date',
    datatype: DATATYPES.percentage,
  },
  {
    value: 'return1Year',
    label: `${currentYear - 1} Calendar Year Return`,
    fullLabel: `Compounded return for Calendar Year ${currentYear - 1}`,
    datatype: DATATYPES.percentage,
  },
  {
    value: 'return2Year',
    label: `${currentYear - 2} Calendar Year Return`,
    fullLabel: `Compounded return for Calendar Year ${currentYear - 2}`,
    datatype: DATATYPES.percentage,
  },
  {
    value: 'return3Year',
    label: `${currentYear - 3} Calendar Year Return`,
    fullLabel: `Compounded return for Calendar Year ${currentYear - 3}`,
    datatype: DATATYPES.percentage,
  },
  {
    value: 'return4Year',
    label: `${currentYear - 4} Calendar Year Return`,
    fullLabel: `Compounded return for Calendar Year ${currentYear - 4}`,
    datatype: DATATYPES.percentage,
  },
  {
    value: 'return5Year',
    label: `${currentYear - 5} Calendar Year Return`,
    fullLabel: `Compounded return for Calendar Year ${currentYear - 5}`,
    datatype: DATATYPES.percentage,
  },
  {
    value: 'totalReturn',
    label: 'Total Return',
    fullLabel: 'Total Return',
    datatype: DATATYPES.percentage,
  },
];

export const RETURN_OPTIONS = [
  {
    value: 'annualized',
    label: 'Annualized Return',
    children: ANNUALIZED_RETURNS,
  },
  {
    value: 'compounded',
    label: 'Compounded Return',
    children: COMPOUNDED_RETURNS,
  },
  {
    value: 'averageMonthlyReturn',
    label: 'Average Monthly Return',
    datatype: DATATYPES.percentage,
  },
];
