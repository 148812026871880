import { useEffect } from 'react';
import { useFilterDropdownContext } from '../filter-dropdown-container';

export const useApplyOnEnter = () => {
  const { localValue, handleApply } = useFilterDropdownContext();

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleApply();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [localValue]);
};
