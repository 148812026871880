import React from 'react';
import PropTypes from 'prop-types';
import { BasicTable } from '@common/basic-table';
import { DecimalPercentageFormatter } from '@core/formatters';
import { getDateObj } from '@core/date-helper';

export const CumulativeReturns = ({ programData }) => {
  let currentYear = undefined;
  if (programData.endDate) {
    let convertedDate = getDateObj(programData.endDate);
    currentYear = convertedDate.getFullYear();
  } else {
    currentYear = new Date().getFullYear();
  }
  return (
    <BasicTable>
      <BasicTable.Title colSpan="2">Cumulative Returns</BasicTable.Title>

      <BasicTable.Row>
        <BasicTable.Header>YTD</BasicTable.Header>
        <BasicTable.Cell>
          {currentYear == new Date().getFullYear() ? (
            <DecimalPercentageFormatter
              handleNegVals
              onlyNegHundred
              value={programData.yearToDate}
            />
          ) : null}
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>1 Year</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatter
            handleNegVals
            onlyNegHundred
            value={programData.return12Month}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>3 Years</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatter
            handleNegVals
            onlyNegHundred
            value={programData.return36Month}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>5 Years</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatter
            handleNegVals
            onlyNegHundred
            value={programData.return60Month}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>10 Years</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatter
            handleNegVals
            onlyNegHundred
            value={programData.return120Month}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Total Return</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatter value={programData.totalReturn} />
        </BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};

CumulativeReturns.propTypes = {
  programData: PropTypes.object.isRequired,
};
