import { useEffect } from 'react';
import api from '@api';

export const useSubscriptions = () => {
  const { getSubscriptions, subscriptions } = api.select.useSubscriptions();

  useEffect(() => {
    getSubscriptions();
  }, []);

  return { subscriptions };
};
