import { tokenStorage, paramsStorage } from '@core/browser-storage';
import initialState from './initial-state';
import * as types from '../actions/action-types';

export default function requestReducer(state = initialState.request, action) {
  if (action.type.endsWith('_FAILURE')) {
    let failStr = action.type.replace(/_FAILURE/g, '');
    return {
      ...state,
      pendingRequests: state.pendingRequests.filter(
        (element) => element !== failStr
      ),
    };
  } else if (action.type.endsWith('_SUCCESS')) {
    let sucStr = action.type.replace(/_SUCCESS/g, '');
    return {
      ...state,
      pendingRequests: state.pendingRequests.filter(
        (element) => element !== sucStr
      ),
    };
  } else if (action.type.endsWith('_REQUEST')) {
    let requestString = action.type.replace(/_REQUEST/g, '');
    return {
      ...state,
      pendingRequests: [...state.pendingRequests, requestString],
    };
  }

  switch (action.type) {
    case types.LOGIN_PASS:
      let currentTime = Date.now();
      currentTime += Number(action.data.expireMs);
      let timeOfExpire = new Date(currentTime);
      tokenStorage.setTokenExpTime(timeOfExpire.getTime());

      return {
        ...state,
        expire: timeOfExpire.getTime(),
        roles: action.data.roles,
        pendingRequests: [],
      };

    case types.LOGIN_FAIL:
      tokenStorage.removeTokenExpTime();
      return {
        ...state,
        expire: undefined,
        roles: undefined,
        error: action.data,
      };

    case types.LOGOUT:
      tokenStorage.removeTokenExpTime();
      paramsStorage.removeParams();
      return {
        ...state,
        expire: undefined,
        roles: undefined,
      };

    default:
      return state;
  }
}
