import React from 'react';
import PropTypes from 'prop-types';
import { BasicTable } from '@common/basic-table';

export const Benchmarks = ({ programData }) => {
  return (
    <BasicTable>
      <BasicTable.Title colSpan="2">Benchmarks</BasicTable.Title>

      <BasicTable.Row>
        <BasicTable.Header>Primary Benchmark</BasicTable.Header>
        <BasicTable.Cell>
          {/* // TODO: Confirm that the API key is correct */}
          {programData.primaryBenchmark}
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Secondary Benchmark</BasicTable.Header>
        <BasicTable.Cell>
          {/* // TODO: Confirm that the API key is correct */}
          {programData.secondaryBenchmark}
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Exchange Listing</BasicTable.Header>
        <BasicTable.Cell>{programData.exchangeListing}</BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};

Benchmarks.propTypes = {
  programData: PropTypes.object.isRequired,
};
