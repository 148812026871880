import { WINDOW_SIZES } from '@core/constants';
import initialState from './initial-state';
import * as types from '../actions/action-types';

export default function layoutReducer(state = initialState.layout, action) {
  switch (action.type) {
    case types.WINDOW_SIZE_SET:
      let sidebarCollapsed = state.sidebarCollapsed;
      if (action.value === WINDOW_SIZES.XS) sidebarCollapsed = true;
      else if (
        state.windowSize === WINDOW_SIZES.XS &&
        sidebarCollapsed === true
      )
        sidebarCollapsed = false;

      return {
        ...state,
        windowSize: action.value,
        sidebarCollapsed,
      };

    case types.SIDEBAR_COLLAPSED_SET:
      return {
        ...state,
        sidebarCollapsed: action.value,
      };

    case types.SIDEBAR_COLLAPSED_TOGGLE:
      return {
        ...state,
        sidebarCollapsed: !state.sidebarCollapsed,
      };

    default:
      return state;
  }
}
