import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tabs, Button, Tab, TabList, TabPanel } from '@backstop/react-core';

import SaveFund from '@common/table/save-fund';
import { ProfileActions } from './profile-actions';
import { ProgramsCount } from './programs-count';
import styles from './admin-toolbar.module.scss';
import classNames from 'classnames';
import { SaveSearchActions } from './save-search-actions';

export const AdminToolbar = ({ onClick, isSidebarCollapsed }) => {
  const location = useLocation();

  const buttonClasses = classNames([styles['button']], {
    [styles['button--is-collapsed']]: !isSidebarCollapsed,
  });

  const buttonRef = useRef(null);

  return (
    <div className={styles['body']}>
      <div className={styles['left-side']}>
        <button type="button" onClick={onClick} className={buttonClasses}>
          <FontAwesomeIcon icon="outdent" />
        </button>

        <div>{location.pathname === '/search' && <ProgramsCount />}</div>
      </div>
      <div style={{ display: 'flex' }}>
        <Button.DropdownButton
          ref={buttonRef}
          label="Toolbox"
          kind="secondary"
          dropdownProps={{
            width: '500px',
            //keep mounted allows us to use the forms rendered as children from here
            keepMounted: true,
          }}
        >
          <Tabs version="v3">
            <div>
              <TabList>
                <Tab>Save Funds</Tab>
                <Tab>Save Search</Tab>
              </TabList>
            </div>
            <TabPanel>
              <SaveFund />
            </TabPanel>
            <TabPanel>
              <SaveSearchActions />
            </TabPanel>
          </Tabs>
        </Button.DropdownButton>
        <ProfileActions />
      </div>
    </div>
  );
};

AdminToolbar.propTypes = {
  onClick: PropTypes.func.isRequired,
  isSidebarCollapsed: PropTypes.bool.isRequired,
};
