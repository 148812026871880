import api from '@api';
import { useEffect } from 'react';

export const useSavedSearch = () => {
  const { getSavedSearches, deleteSearch, updateSearch, loading } =
    api.saveSearch.useSaveSearch();

  useEffect(() => {
    getSavedSearches();
  }, []);

  const deleteSavedSearch = (itemId) => {
    deleteSearch(itemId);
  };

  const updateSavedSearch = (itemId, data) => {
    updateSearch({ id: itemId, data });
  };

  return {
    deleteSavedSearch,
    updateSavedSearch,
    loading,
  };
};
