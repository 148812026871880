import React from 'react';
import PropTypes from 'prop-types';
import { PROGRAM_TYPES } from '@core/constants';
import { TabBase } from '../tab-base';
import { RequiresProAccess, hasProAccess } from '../requires-pro-access';
import { AdministratorInformation } from './tables/administrator-information';
import { CustodianInformation } from './tables/custodian-information';
import { AuditorInformation } from './tables/auditor-information';
import { PrimeBrokerInformation } from './tables/prime-broker-information';
import { TransferAgentInformation } from './tables/transfer-agent-information';
import { AdvisorInformation } from './tables/advisor-information';
import { LegalInformation } from './tables/legal-information';
import styles from './admin-tab.module.scss';

export const AdminTab = ({ programData }) => {
  if (!hasProAccess(programData)) return <RequiresProAccess />;

  return (
    <TabBase>
      <div className={styles['row']}>
        <div className={styles['col']}>
          <AdministratorInformation programData={programData} />
          <PrimeBrokerInformation programData={programData} />

          {programData.type === PROGRAM_TYPES.CTA ? (
            <TransferAgentInformation programData={programData} />
          ) : (
            <CustodianInformation programData={programData} />
          )}
        </div>

        <div className={styles['col']}>
          <AuditorInformation programData={programData} />
          <AdvisorInformation programData={programData} />
          <LegalInformation programData={programData} />
        </div>
      </div>
    </TabBase>
  );
};

AdminTab.propTypes = {
  programData: PropTypes.object.isRequired,
};
