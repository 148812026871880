export const BENCHMARK_OPTIONS = [
  {
    value: 'UsTreasury',
    label: '3m US T-Bills',
  },
  {
    value: 'Cta',
    label: 'BarclayHedge CTA Index',
  },
  {
    value: 'Equity',
    label: 'BarclayHedge Equity L/S Index',
  },
  {
    value: 'Fof',
    label: 'BarclayHedge FoF Index',
  },
  {
    value: 'Hf',
    label: 'BarclayHedge HF Index',
  },
  {
    value: 'Dow',
    label: 'Dow Jones Industrial Average',
  },
  {
    value: 'FtSe',
    label: 'FTSE 100 Index',
  },
  {
    value: 'JpMorgan',
    label: 'JPMorgan World Government Bond Index',
  },
  {
    value: 'Eafe',
    label: 'MSCI EAFE Index',
  },
  {
    value: 'MsciWorld',
    label: 'MSCI World',
  },
  {
    value: 'Nasdaq',
    label: 'NASDAQ',
  },
  {
    value: 'Nikkei',
    label: 'Nikkei 225 Index',
  },
  {
    value: 'Russel',
    label: 'Russell 2000',
  },
  {
    value: 'Sp500',
    label: 'S&P 500',
  },
];
