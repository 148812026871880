import api from '@api';
import { useEffect } from 'react';

export const useYearlyReturnData = (id) => {
  const { getYearlyReturnChartData, data, loading } =
    api.program.useChartData();

  useEffect(() => {
    getYearlyReturnChartData(id);
  }, [id]);

  return { data, loading };
};
