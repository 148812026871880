import React from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '@backstop/react-core';
import { useFilterDropdownContext } from '@common/form-inputs/filter-dropdown-container';
import { useApplyOnEnter } from '../hooks';

export const getDisplayName = (_, value) => {
  return {
    displayValue: value || undefined,
    fullValueString: value || undefined,
  };
};

export const DropdownTextFilter = ({ name, placeholder, ...restProps }) => {
  const { localValue, setLocalValue } = useFilterDropdownContext();
  useApplyOnEnter();

  return (
    <TextInput
      version="v3"
      name={name}
      onChange={setLocalValue}
      value={localValue}
      placeholder={placeholder}
      autoFocus
      {...restProps}
    />
  );
};

DropdownTextFilter.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};
