import React from 'react';
import PropTypes from 'prop-types';
import { CaptureChart } from '@core/charts/column';

export const Last10UpCaptureChartVsSp500 = ({ progData, spData, progName }) => {
  return (
    <CaptureChart
      fundDataA={spData}
      fundNameA="S&P 500"
      fundDataB={progData}
      fundNameB={progName}
      chartTitle="Up Capture vs S&P 500"
    />
  );
};

Last10UpCaptureChartVsSp500.propTypes = {
  progData: PropTypes.object.isRequired,
  progName: PropTypes.string.isRequired,
  spData: PropTypes.object.isRequired,
};
