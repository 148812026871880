import React, { useEffect, useState } from 'react';
import { useSaveFund } from './hooks';
import { ProgramNameFormatter } from '@core/formatters';
import { Button } from '@backstop/react-core';
import { Loader } from '@common/loader';
import { BasicTable } from '@common/basic-table';
import Delete from '@common/modals/Delete';
import { useSelector } from 'react-redux';

export const SaveFund = () => {
  const { loading, deleteSavedFund } = useSaveFund();
  const userDataState = useSelector((state) => state.userData.savedFunds);

  const [showDelete, setShowDelete] = useState(-1);

  const deleteAndClose = () => {
    setShowDelete(-1);
    deleteSavedFund(showDelete);
  };

  if (loading) {
    return <Loader />;
  }

  if (userDataState?.length == 0) {
    return (
      <BasicTable.Row>
        <BasicTable.Cell alignLeft>No Saved Funds</BasicTable.Cell>
      </BasicTable.Row>
    );
  }
  //the name formatter is looking for item.id, but in this case that would be the ID of the savedFund not the progId that we want
  const mappedData = userDataState.map((fund) => ({
    ...fund,
    id: fund.fundId,
  }));
  return (
    <BasicTable>
      {mappedData?.map((item, index) => (
        <BasicTable.Row key={item.id}>
          <BasicTable.Cell alignLeft>
            <ProgramNameFormatter value={item.name} row={item} />
          </BasicTable.Cell>
          <BasicTable.Cell alignRight>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button.EditIconButton
                version="v3"
                type="cancel"
                onClick={() => setShowDelete(item.fundId)}
              />

              {showDelete !== -1 ? (
                <Delete
                  isOpen={showDelete}
                  toggleDelete={setShowDelete}
                  elementToDelete={item.fundId}
                  deleteFunction={deleteAndClose}
                  deleteHeader="Delete saved fund?"
                  deleteMessage="Would you like to delete this saved fund?"
                />
              ) : null}
            </div>
          </BasicTable.Cell>
        </BasicTable.Row>
      ))}
    </BasicTable>
  );
};
