import React from 'react';
import PropTypes from 'prop-types';
import { BasicTable } from '@common/basic-table';

export const Holdings = ({ holdings }) => {
  return (
    <BasicTable widths={['40%', '30%', '30%']}>
      <BasicTable.Title colSpan="3">Holdings</BasicTable.Title>

      <BasicTable.Row>
        <BasicTable.Header>Holding Name</BasicTable.Header>
        <BasicTable.Header>Average Holdings</BasicTable.Header>
        <BasicTable.Header>Strategy</BasicTable.Header>
      </BasicTable.Row>

      {holdings.map((holding) => (
        <BasicTable.Row key={holding.id}>
          <BasicTable.Cell alignLeft>{holding.name}</BasicTable.Cell>
          <BasicTable.Cell alignLeft>{holding.value}</BasicTable.Cell>
          <BasicTable.Cell alignLeft>{holding.strategy}</BasicTable.Cell>
        </BasicTable.Row>
      ))}
    </BasicTable>
  );
};

Holdings.propTypes = {
  holdings: PropTypes.array.isRequired,
};
