import React from 'react';
import { BasicNumberFormatter } from './basic-number-formatter';

export const BasicNumberFormatterNegativeHandler = ({
  value,
  disableNegativeHandling,
}) => {
  return (
    <BasicNumberFormatter
      value={value}
      handleNegVals={!disableNegativeHandling}
    />
  );
};
