import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { DropdownMenu, Icon } from '@backstop/react-core';
import { CheckBoxList } from '../checkbox-list';
import { useDropdownState, useSelectedLables } from './hooks';
import styles from './multiselect-dropdown.module.scss';
import classNames from 'classnames';

const Placeholder = () => {
  return <div className={styles['placeholder']}>Select</div>;
};

const ArrowIcon = () => {
  return (
    <div className={styles['arrow']}>
      <Icon name="caret-down" size="0.625rem" version="v3" />
    </div>
  );
};

export const MultiselectDropdown = (props) => {
  const {
    name,
    options,
    selectedItems,
    onChange,
    onBlur,
    maxLength,
    className,
    showMultiselectControls,
  } = props;
  const buttonRef = useRef(null);
  const buttonClasses = classNames(styles['button'], className);

  const { dropdownOpen, toggleDropdownState, closeDropdown } =
    useDropdownState();

  const { fullLabelsList, truncatedLabelsList } = useSelectedLables(
    selectedItems,
    options,
    maxLength
  );

  const getDropdownMinWidth = () => {
    if (buttonRef.current) return buttonRef.current.clientWidth;
    else return 'unset';
  };

  return (
    <>
      <button
        version="v3"
        type="button"
        name={name}
        ref={buttonRef}
        aria-haspopup={true}
        aria-expanded={dropdownOpen}
        onClick={toggleDropdownState}
        onBlur={onBlur}
        className={buttonClasses}
        title={fullLabelsList}
      >
        {truncatedLabelsList || <Placeholder />}
        <ArrowIcon />
      </button>
      <DropdownMenu
        version="v3"
        open={dropdownOpen}
        target={buttonRef}
        onClose={closeDropdown}
        minWidth={getDropdownMinWidth()}
      >
        <CheckBoxList
          items={options}
          selectedItems={selectedItems}
          onChange={onChange}
          showMultiselectControls={showMultiselectControls}
        />
      </DropdownMenu>
    </>
  );
};

MultiselectDropdown.defaultProps = {
  selectedItems: [],
};

const optionsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    optionLabel: PropTypes.string.isRequired,
    children: PropTypes.array,
  })
);

const selectedItemsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    children: PropTypes.array,
  })
);

MultiselectDropdown.propTypes = {
  name: PropTypes.string,
  options: optionsPropTypes.isRequired,
  selectedItems: selectedItemsPropTypes,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  maxLength: PropTypes.number.isRequired,
  className: PropTypes.string,
  showMultiselectControls: PropTypes.bool,
};
