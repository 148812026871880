import React, { useContext } from 'react';
import {
  useApiHttpClient,
  useUnauthorizedApiHttpClient,
} from './api-http-client';

export const APIContext = React.createContext();

export const useApiContext = () => {
  return useContext(APIContext);
};

export const APIContextProvider = ({ children }) => {
  const { apiHttpClient } = useApiHttpClient();
  const { unauthorizedApiHttpClient } = useUnauthorizedApiHttpClient();
  const contextValue = {
    apiHttpClient,
    unauthorizedApiHttpClient,
  };

  return (
    <APIContext.Provider value={contextValue}>{children}</APIContext.Provider>
  );
};
