import { useEffect } from 'react';

// Check for [Enter] key press
export const isKeyEnter = (event) => {
  return event.key === 'Enter' || event.keyCode === 13;
};

export const useCallbackOnEnter = (callbackRef) => {
  if (!callbackRef) return;

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (isKeyEnter(event)) {
        event.preventDefault();
        callbackRef.current();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);
};
