import PropTypes from 'prop-types';
import { dataUnavailable, toDecimal } from '@core/formatters';

export const DecimalFormatter = ({ value, handleNegativeValues }) => {
  if (handleNegativeValues) {
    if (parseInt(value, 10) === -1) {
      return 'N/A';
    }
    if (parseInt(value, 10) === -2) {
      return 'Yes';
    }
  }
  return dataUnavailable(toDecimal(value));
};

DecimalFormatter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleNegativeValues: PropTypes.bool,
};
