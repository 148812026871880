import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useSearchParams } from '@redux/search-params';
import { SEARCH_FIELDS, getDefaultValues } from '@core/search-fields';
import Button from '@common/input/Button';
import { SearchQueryChips } from '@common/search-query-chips';
import { PageHeader } from '~/components/headers/page-header';
import { TypeFilter } from './type-filter';
import { BasicFilters } from './basic-filters';
import { NameFilter } from './name-filter';
import { StrategyFilters } from './strategy-filters';
import { DetailedFilters } from './detailed-filters';
import { CustomFilters } from './custom-filters';

import styles from './main-search.module.scss';
import { visibleByDefault } from '../search-results/columns';

export const MainSearch = () => {
  const navigate = useNavigate();
  const {
    formParams: defaultFormParams,
    setFormParams,
    setActiveColumns,
  } = useSearchParams();

  const { handleSubmit, control, getValues, setValue, reset, watch } = useForm({
    defaultValues: defaultFormParams,
  });

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: SEARCH_FIELDS.customFilters.id,
  });

  const formParams = useWatch({
    control,
  });

  useEffect(() => setActiveColumns(visibleByDefault), []);
  useEffect(() => setFormParams(formParams), [formParams]);
  useEffect(() => setFormParams(getValues()), []);

  const runSearch = () => {
    navigate('/results');
  };

  const handleChipRemove = (key) => {
    setValue(key, SEARCH_FIELDS[key].defaultValue);
  };

  const handleCustomFilterChipRemove = (_, index) => {
    remove(index);
  };

  const handleRemoveAll = () => {
    reset(getDefaultValues());
  };

  return (
    <div className={styles['body']}>
      <SearchQueryChips
        params={formParams}
        onChipRemove={handleChipRemove}
        onCustomFilterChipRemove={handleCustomFilterChipRemove}
        onRemoveAll={handleRemoveAll}
      />
      <PageHeader title="Search Criteria">
        <Button
          type="submit"
          form="search-form"
          className={styles['search-button']}
        >
          Search
        </Button>
      </PageHeader>
      <form id="search-form" onSubmit={handleSubmit((data) => runSearch(data))}>
        <div className={styles['container']}>
          <div className={styles['side-wrapper']}>
            <TypeFilter control={control} />

            <NameFilter control={control} />

            <BasicFilters control={control} />
          </div>
          <div className={styles['main-wrapper']}>
            <StrategyFilters control={control} watch={watch} />

            <DetailedFilters control={control} />

            <CustomFilters
              control={control}
              fields={fields}
              append={append}
              update={update}
              remove={remove}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
