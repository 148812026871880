// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".body--jKIz6{border-bottom:1px solid #ddd;margin:24px 0}", "",{"version":3,"sources":["webpack://./src/components/main-search/filters-container/divider.module.scss"],"names":[],"mappings":"AAEA,aACE,4BAAA,CACA,aAAA","sourcesContent":["@import './src/theme/_variables';\n\n.body {\n  border-bottom: 1px solid $color-border-light;\n  margin: 24px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "body--jKIz6"
};
export default ___CSS_LOADER_EXPORT___;
