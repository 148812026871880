// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".body--gdmqX{padding:8px 0 18px 16px}.body--gdmqX .cancel-button--k86Rr{margin-left:12px}.body--gdmqX .apply-button--mhcze button:disabled{cursor:not-allowed;opacity:.5}", "",{"version":3,"sources":["webpack://./src/components/common/form-inputs/filter-dropdown-container/button-controls/button-controls.module.scss"],"names":[],"mappings":"AAAA,aACE,uBAAA,CAEA,mCACE,gBAAA,CAKE,kDACE,kBAAA,CACA,UAAA","sourcesContent":[".body {\n  padding: 8px 0 18px 16px;\n\n  .cancel-button {\n    margin-left: 12px;\n  }\n\n  .apply-button {\n    button {\n      &:disabled {\n        cursor: not-allowed;\n        opacity: 0.5;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "body--gdmqX",
	"cancel-button": "cancel-button--k86Rr",
	"apply-button": "apply-button--mhcze"
};
export default ___CSS_LOADER_EXPORT___;
