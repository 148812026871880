import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@backstop/react-core';

import { SEARCH_FIELDS } from '@core/search-fields';
import { FiltersContainer } from '../filters-container';
import { CustomFilterModal } from './custom-filter-modal';
import { CustomFilter } from './custom-filter';

import styles from './custom-filters.module.scss';

export const CustomFilters = ({ control, fields, append, update, remove }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [filterToEdit, setFilterToEdit] = useState();
  const [indexToEdit, setIndexToEdit] = useState();

  const handleAdd = (data) => {
    append({ ...data, id: new Date().getTime() });
    setModalOpen(false);
  };

  const handleEdit = (index, field) => {
    setFilterToEdit(field);
    setIndexToEdit(index);
    setModalOpen(true);
  };

  const handleCancel = () => {
    setIndexToEdit(undefined);
    setFilterToEdit(undefined);
    setModalOpen(false);
  };

  const handleUpdate = (data) => {
    update(indexToEdit, data);
    setIndexToEdit(undefined);
    setFilterToEdit(undefined);
    setModalOpen(false);
  };

  return (
    <FiltersContainer
      title="Custom Filters"
      filterInclusionKey={SEARCH_FIELDS.customFiltersInclusion.id}
      defaultValue={SEARCH_FIELDS.customFiltersInclusion.defaultValue}
      control={control}
    >
      {fields.map((field, index) => (
        <CustomFilter
          key={field.id}
          control={control}
          index={index}
          field={field}
          onEdit={handleEdit}
          onRemove={remove}
        />
      ))}

      <Button
        kind="secondary"
        version="v3"
        onClick={() => setModalOpen(true)}
        className={styles['add-filter']}
      >
        <Button.Icon>
          <Icon name="add" />
        </Button.Icon>
        Add Filter
      </Button>

      {modalOpen && (
        <CustomFilterModal
          open={true}
          onApply={handleAdd}
          onUpdate={handleUpdate}
          onClose={handleCancel}
          filterToEdit={filterToEdit}
        />
      )}
    </FiltersContainer>
  );
};

CustomFilters.propTypes = {
  control: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  append: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
};
