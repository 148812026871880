import React from 'react';
import PropTypes from 'prop-types';

import { Title } from './title';
import { Section } from './section';
import { Header } from './header';
import { Row } from './row';
import { Cell } from './cell';

import classNames from 'classnames';
import styles from './basic-table.module.scss';

export const BasicTable = ({ children, className, widths }) => {
  const classes = classNames(styles['body'], className);

  return (
    <table className={classes}>
      {widths && widths.length && (
        <colgroup>
          {/* I know it is a bad practice, but there is no unique key for this */}
          {widths.map((width, index) => (
            <col key={index} colSpan="1" style={{ width }} />
          ))}
        </colgroup>
      )}
      {children}
    </table>
  );
};

BasicTable.Title = Title;
BasicTable.Section = Section;
BasicTable.Header = Header;
BasicTable.Row = Row;
BasicTable.Cell = Cell;

BasicTable.propTypes = {
  children: PropTypes.node.isRequired,
  widths: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
};
