import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Modal } from '@backstop/react-core';

import { useUserData } from '@core/user';
import { useCallbackOnEnter } from '@core/keyboard-helpers';
import { FilterTypeSelectors } from './filter-type-selectors';
import { FilterValueSelectors } from './filter-value-selectors';
import { Footer } from './footer';

import styles from './custom-filter-modal.module.scss';

export const CustomFilterModal = ({
  open,
  onApply,
  onUpdate,
  onClose,
  filterToEdit,
}) => {
  const { handleSubmit, control } = useForm();
  const { isFreeUser } = useUserData();

  const handleCloseRequest = () => {
    onClose();
  };

  const handleApplyRef = useRef();
  const handleApply = handleSubmit((formData) => {
    if (isFreeUser) return;

    if (filterToEdit) {
      onUpdate(formData);
    } else {
      onApply(formData);
    }
  });
  handleApplyRef.current = handleApply;
  useCallbackOnEnter(handleApplyRef);

  const footer = <Footer onApply={handleApply} onCancel={handleCloseRequest} />;

  return (
    <Modal
      id={styles['custom-filter']}
      title="Custom Filter"
      version="v3"
      open={open}
      toggle={handleCloseRequest}
      onRequestClose={handleCloseRequest}
      closeOnOverlayClick={true}
      closeOnEscape={true}
      footer={footer}
    >
      <form>
        <FilterTypeSelectors control={control} filterToEdit={filterToEdit} />
        <FilterValueSelectors control={control} filterToEdit={filterToEdit} />
      </form>
    </Modal>
  );
};

CustomFilterModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onApply: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  filterToEdit: PropTypes.object,
};
