// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.body--WdaHr:only-of-type{width:1000px}div.body--WdaHr:only-of-type .header--TRH63{text-align:center;font-size:32px}div.body--WdaHr:only-of-type .info--lGzhF{text-align:center;width:600px;margin:auto;margin-bottom:28px}div.body--WdaHr:only-of-type .return-to-login--jLpee{margin-top:8px;font-size:14px}", "",{"version":3,"sources":["webpack://./src/components/auth/register/register.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CAEA,4CACE,iBAAA,CACA,cAAA,CAGF,0CACE,iBAAA,CACA,WAAA,CACA,WAAA,CACA,kBAAA,CAGF,qDACE,cAAA,CACA,cAAA","sourcesContent":["div.body:only-of-type {\n  width: 1000px;\n\n  .header {\n    text-align: center;\n    font-size: 32px;\n  }\n\n  .info {\n    text-align: center;\n    width: 600px;\n    margin: auto;\n    margin-bottom: 28px;\n  }\n\n  .return-to-login {\n    margin-top: 8px;\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "body--WdaHr",
	"header": "header--TRH63",
	"info": "info--lGzhF",
	"return-to-login": "return-to-login--jLpee"
};
export default ___CSS_LOADER_EXPORT___;
