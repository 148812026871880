import React from 'react';
import PropTypes from 'prop-types';

import { requiredFieldMessage, VALIDATORS } from '@core/validators';
import { FormSelect, FormTextInput } from '@common/form-inputs';
import { useCountryOptions } from '../hooks';

import styles from './field-column-right.module.scss';

export const FieldColumnRight = ({ control, loading }) => {
  const { countryOptions } = useCountryOptions();

  return (
    <div className={styles['body']}>
      <FormTextInput
        name="phone"
        label="Phone"
        placeholder="Phone"
        control={control}
        disabled={loading}
        maxLength={20}
        rules={{ required: requiredFieldMessage }}
      />
      <FormTextInput
        name="streetAddress"
        label="Street Address"
        placeholder="Street Address"
        control={control}
        disabled={loading}
        maxLength={100}
        rules={{ required: requiredFieldMessage }}
      />
      <FormTextInput
        name="city"
        label="City"
        placeholder="City"
        control={control}
        disabled={loading}
        maxLength={25}
        rules={{ required: requiredFieldMessage }}
      />
      <FormSelect
        control={control}
        name="country"
        label="Country"
        options={countryOptions}
        disabled={loading}
        rules={{ validate: VALIDATORS.getRequiredOptionValidator() }}
      />
      <FormTextInput
        name="state"
        label="State"
        placeholder="State"
        control={control}
        disabled={loading}
        maxLength={25}
        rules={{ required: requiredFieldMessage }}
      />
      <FormTextInput
        name="zip"
        label="Zip Code"
        placeholder="Zip Code"
        control={control}
        disabled={loading}
        maxLength={12}
        rules={{ required: requiredFieldMessage }}
      />
    </div>
  );
};

FieldColumnRight.propTypes = {
  control: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};
