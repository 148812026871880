import * as types from './action-types';

export function cacheSet(cacheKey, data) {
  return {
    type: types.CACHE_SET,
    cacheKey,
    data,
  };
}

export function cacheClean(cacheKey) {
  return {
    type: types.CACHE_CLEAN,
    cacheKey,
  };
}

export function cacheLoadingSet(cacheKey) {
  return {
    type: types.CACHE_LOADING_SET,
    cacheKey,
  };
}

export function cacheLoadingClean(cacheKey) {
  return {
    type: types.CACHE_LOADING_CLEAN,
    cacheKey,
  };
}
