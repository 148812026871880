import React from 'react';

import styles from './page-header.module.scss';

export const PageHeader = ({ title, children }) => {
  return (
    <>
      <div className={styles['title-wrapper']}>
        <h2 className={styles['title']}>{title}</h2>
        <div className={styles['body']}>{children}</div>
      </div>
    </>
  );
};
