import { combineReducers } from 'redux';
import request from './request-reducer';
import cache from './cache-reducer';
import cacheLoading from './cache-loading-reducer';
import params from './params-reducer';
import userData from './user-data-reducer';
import layout from './layout-reducer';

const rootReducer = combineReducers({
  request,
  cache,
  cacheLoading,
  params,
  userData,
  layout,
});

export default rootReducer;
