import React from 'react';
import PropTypes from 'prop-types';
import { BasicTable } from '@common/basic-table';

export const AdvisorInformation = ({ programData }) => {
  return (
    <BasicTable>
      <BasicTable.Title colSpan="2">Advisor Information</BasicTable.Title>

      <BasicTable.Row>
        <BasicTable.Header>Advisor Firm</BasicTable.Header>
        <BasicTable.Cell>{programData.advisor}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Advisor Contact</BasicTable.Header>
        <BasicTable.Cell>{programData.advisorContact}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Advisor Phone</BasicTable.Header>
        <BasicTable.Cell>{programData.advisorContactPhone}</BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};

AdvisorInformation.propTypes = {
  programData: PropTypes.object.isRequired,
};
