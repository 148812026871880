import React from 'react';
import { Button as BsgButton } from '@backstop/react-core';

const Button = ({ children, ...restProps }) => {
  return (
    <BsgButton version="v3" {...restProps}>
      {children}
    </BsgButton>
  );
};

export default Button;
