import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@backstop/react-core';

/**
 * Select Input wrapper around BSGSelect
 * Options are defined as [{label:"", value:""}]
 * @returns
 */
const SelectInput = ({ options, name, ...restProps }) => {
  return <Select version="v3" options={options} name={name} {...restProps} />;
};

SelectInput.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string,
};

SelectInput.defaultProps = {
  options: [],
};

export default SelectInput;
