import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Button, InlineMessageBlock } from '@backstop/react-core';

import { PLACEHOLDER_SELECT_OPTION } from '@core/placeholder-select-option';
import { requiredFieldMessage } from '@core/validators';
import { FormRadioInput } from '@common/form-inputs';

import { FieldColumnLeft } from './field-column-left';
import { FieldColumnRight } from './field-column-right';
import { TermsAndConditions } from './terms-and-conditions';
import { USER_STATUS_OPTIONS } from './constants';
import { useRegistration } from './hooks';

import styles from './register-form.module.scss';

export const RegisterForm = ({ setSuccess }) => {
  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      company: '',
      professionalField: PLACEHOLDER_SELECT_OPTION,
      database: PLACEHOLDER_SELECT_OPTION,
      phone: '',
      streetAddress: '',
      city: '',
      country: PLACEHOLDER_SELECT_OPTION,
      state: '',
      zip: '',
      userStatus: '',
      termsAndConditions: false,
    },
  });

  const userStatusNoneSelected = watch('userStatus') === '0';
  const termsAndConditions = watch('termsAndConditions');

  const { getRegistrationHandler, loading, success, error } =
    useRegistration(handleSubmit);

  useEffect(() => {
    if (success) {
      setSuccess(success);
    }
  }, [success]);

  return (
    <form onSubmit={getRegistrationHandler()}>
      {error && (
        <InlineMessageBlock kind="error">
          {error === 'Error' ? 'Something went wrong!' : error}
        </InlineMessageBlock>
      )}

      <TermsAndConditions control={control} loading={loading} />

      {termsAndConditions && (
        <>
          <div className={styles['form-fields']}>
            <FieldColumnLeft control={control} loading={loading} />
            <FieldColumnRight control={control} loading={loading} />
          </div>

          <FormRadioInput
            control={control}
            name="userStatus"
            label="Select your User Status"
            className={styles['user-status-radio']}
            options={USER_STATUS_OPTIONS}
            disabled={loading}
            rules={{
              required: requiredFieldMessage,
            }}
          />
        </>
      )}

      <Button
        version="v3"
        type={loading || userStatusNoneSelected ? 'button' : 'submit'}
        kind="primary"
        className={styles['button']}
        spinner={loading}
        disabled={loading || userStatusNoneSelected}
        style={{ width: '100%' }}
      >
        <span className={styles['text']}>Register</span>
      </Button>
    </form>
  );
};

RegisterForm.propTypes = {
  setSuccess: PropTypes.func.isRequired,
};
