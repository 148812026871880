import * as types from './action-types';

export function paramsSet(params) {
  return {
    type: types.PARAMS_SET,
    params,
  };
}

export function paramsClean() {
  return {
    type: types.PARAMS_CLEAN,
  };
}

export function formParamsSet(formParams) {
  return {
    type: types.FORM_PARAMS_SET,
    formParams,
  };
}

export function activeColumnsSet(activeCols) {
  return {
    type: types.ACTIVE_COLUMNS_SET,
    activeCols,
  };
}

export function columnOrderSet(columnOrder) {
  return {
    type: types.COLUMN_ORDER_SET,
    columnOrder,
  };
}
