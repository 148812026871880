// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login-button--l28LK{width:100%;margin-top:10px}.login-button--l28LK .text--cnDVk{width:100%}", "",{"version":3,"sources":["webpack://./src/components/auth/login/login-form/login-form.module.scss"],"names":[],"mappings":"AAAA,qBACE,UAAA,CACA,eAAA,CAEA,kCACE,UAAA","sourcesContent":[".login-button {\n  width: 100%;\n  margin-top: 10px;\n\n  .text {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login-button": "login-button--l28LK",
	"text": "text--cnDVk"
};
export default ___CSS_LOADER_EXPORT___;
