import { useLayoutEffect, useState } from 'react';

export const useDropdownState = (onBlur) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const openDropdown = () => setDropdownOpen(true);
  const closeDropdown = () => {
    onBlur();
    setDropdownOpen(false);
  };

  return {
    dropdownOpen,
    openDropdown,
    closeDropdown,
  };
};

export const useDisplayValue = ({ options, value, getDisplayName }) => {
  const [displayValue, setDisplayValue] = useState();
  const [fullValueString, setFullValueString] = useState();

  useLayoutEffect(() => {
    const { displayValue, fullValueString } = getDisplayName(options, value);
    setDisplayValue(displayValue);
    setFullValueString(fullValueString);
  }, [options, value, getDisplayName]);

  return { displayValue, fullValueString };
};
