import { useCallback, useMemo } from 'react';
import { useBaseGet } from './base';

export const useDenominationOptions = () => {
  const endpointUrl = '/select/denomination';
  const { baseGet, data, ...rest } = useBaseGet({
    cacheConfig: {
      cacheKey: 'denomination',
    },
  });

  const getDenominationOptions = useCallback(() => baseGet(endpointUrl), []);

  const denominations = useMemo(() => {
    if (!data) return [];

    return data.data.map((item) => ({
      id: item.value,
      optionLabel: `${item.value} - ${item.label}`,
    }));
  }, [data]);

  return { getDenominationOptions, denominations, ...rest };
};

export const useExchangeListOptions = () => {
  const endpointUrl = '/select/exchangeList';
  const { baseGet, data, ...rest } = useBaseGet({
    cacheConfig: {
      cacheKey: 'exchangeList',
    },
  });

  const getExchangeListOptions = useCallback(() => baseGet(endpointUrl), []);

  const exchangeListOptions = useMemo(() => {
    if (!data) return [];

    const options = data.data.map((item) => ({
      id: item.name,
      optionLabel: item.name,
    }));

    options.sort((a, b) => {
      if (a.id === 'Not Listed' && b.id === 'Other') return 1;
      if (a.id === 'Other' && b.id === 'Not Listed') return -1;
      if (a.id === 'Not Listed' || a.id === 'Other') return 1;
      if (b.id === 'Not Listed' || b.id === 'Other') return -1;
      return 0;
    });

    return options;
  }, [data]);

  return {
    getExchangeListOptions,
    exchangeListOptions,
    ...rest,
  };
};

export const useUsOptions = () => {
  const endpointUrl = '/select/us';
  const { baseGet, data, ...rest } = useBaseGet({
    cacheConfig: {
      cacheKey: 'us',
    },
  });

  const getUsOptions = useCallback(() => baseGet(endpointUrl), []);

  const usOptions = useMemo(() => {
    if (!data) return [];

    const options = data.data.map((item) => ({
      id: item.value,
      optionLabel: item.label || 'No Specified Structure',
    }));

    options.sort((a, b) => {
      if (a.id === '') return 1;
      if (b.id === '') return -1;
      return 0;
    });

    return options;
  }, [data]);

  return {
    getUsOptions,
    usOptions,
    ...rest,
  };
};

export const useProfessionalFieldOptions = () => {
  const endpointUrl = '/select/professional';
  const { baseGet, data, ...rest } = useBaseGet({
    noAuth: true,
    cacheConfig: {
      cacheKey: 'professional',
    },
  });

  const getProfessionalFieldOptions = useCallback(
    () => baseGet(endpointUrl),
    []
  );

  const professionalFieldOptions = useMemo(() => {
    if (!data) return [];

    const options = data.data.map((item) => ({
      value: item.value,
      label: item.label || 'None',
    }));

    return options;
  }, [data]);

  return {
    getProfessionalFieldOptions,
    professionalFieldOptions,
    ...rest,
  };
};

export const useFreeDatabaseSampleOptions = () => {
  const endpointUrl = '/select/dbSample';
  const { baseGet, data, ...rest } = useBaseGet({
    noAuth: true,
    cacheConfig: {
      cacheKey: 'dbSample',
    },
  });

  const getDbSampleOptions = useCallback(() => baseGet(endpointUrl), []);

  const dbSampleOptions = useMemo(() => {
    if (!data) return [];

    const options = data.data.map((item) => ({
      value: item.value,
      label: item.label || 'None',
    }));

    return options;
  }, [data]);

  return {
    getDbSampleOptions,
    dbSampleOptions,
    ...rest,
  };
};

export const useCountryOptions = () => {
  const endpointUrl = '/select/countryList';
  const { baseGet, data, ...rest } = useBaseGet({
    noAuth: true,
    cacheConfig: {
      cacheKey: 'countryList',
    },
  });

  const getCountryOptions = useCallback(() => baseGet(endpointUrl), []);

  const countryOptions = useMemo(() => {
    if (!data) return [];

    const options = data.data.map((item) => ({
      value: item.value,
      label: item.label || 'None',
    }));

    return options;
  }, [data]);

  return {
    getCountryOptions,
    countryOptions,
    ...rest,
  };
};

export const useCategories = () => {
  const endpointUrl = '/select/categories';
  const { baseGet, data, ...rest } = useBaseGet({
    cacheConfig: {
      cacheKey: 'categories',
    },
  });

  const getCategories = useCallback(() => baseGet(endpointUrl), []);

  return {
    getCategories,
    categories: data?.data || [],
    ...rest,
  };
};

export const usePrimaryCategoryConversion = () => {
  const endpointUrl = '/select/primaryCatConvert';
  const { baseGet, data, ...rest } = useBaseGet({
    cacheConfig: {
      cacheKey: 'primaryCategoryConversion',
    },
  });

  const getPrimaryCategoryConversion = useCallback(
    () => baseGet(endpointUrl),
    []
  );

  return {
    getPrimaryCategoryConversion,
    primaryCategoryConversion: data?.data || [],
    ...rest,
  };
};

export const useSubscriptions = () => {
  const endpointUrl = '/select/subscriptions';
  const { baseGet, data, ...rest } = useBaseGet({
    cacheConfig: {
      cacheKey: 'subscriptions',
    },
  });

  const getSubscriptions = useCallback(() => baseGet(endpointUrl), []);

  return {
    getSubscriptions,
    subscriptions: data?.data || [],
    ...rest,
  };
};

export const useRedemptions = () => {
  const endpointUrl = '/select/redemptions';
  const { baseGet, data, ...rest } = useBaseGet({
    cacheConfig: {
      cacheKey: 'redemptions',
    },
  });

  const getRedemptions = useCallback(() => baseGet(endpointUrl), []);

  return {
    getRedemptions,
    redemptions: data?.data || [],
    ...rest,
  };
};

export const useAwardSectorOptions = () => {
  const { baseGet, data, loading, ...rest } = useBaseGet({
    cacheConfig: {
      cacheKey: 'sectors',
    },
  });
  const getAwardSectorOptions = useCallback((isYearly, isBmfr, type, date) => {
    const endpointUrl = `/select/sectorOptions?isYearly=${isYearly}&isBmfr=${isBmfr}&type=${type}&date=${date}`;
    baseGet(endpointUrl);
  }, []);
  return {
    getAwardSectorOptions,
    loading,
    sectors: data?.data || [],
    ...rest,
  };
};
