import React, { useEffect } from 'react';
import { Button, Icon } from '@backstop/react-core';
import { useParams } from 'react-router-dom';
import api from '@api';
import styles from './download-button.module.scss';

export const DownloadAwardPdfButton = ({}) => {
  const fileId = useParams().downloadId;
  const { downloadToken, token } = api.awardsRanks.useAwardFileDownloadToken();

  useEffect(() => {
    if (!token) {
      downloadToken(fileId);
    }
  }, []);

  useEffect(() => {
    if (token) {
      window.open(
        process.env.REACT_APP_BARCLAYHEDGE_API_URL + token.tokenUrl,
        '_blank'
      );
    }
  }, [token]);

  return (
    <div className={styles['body']}>
      <p>
        A new window should have opened with your file download. If not please
        click the button below to start the download
      </p>
      <Button
        version="v3"
        type="submit"
        kind="secondary"
        onClick={() => downloadToken(fileId)}
      >
        <Icon name={'download'} className={styles['icon']} />
        <div>Download File</div>
      </Button>
    </div>
  );
};
