import React from 'react';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import { PLACEHOLDER_SELECT_OPTION } from '@core/placeholder-select-option';
import { VALIDATORS } from '@core/validators';
import { FormNestedRadio, FormSelect } from '@common/form-inputs';
import { BENCHMARK_OPTIONS, FILTER_OPTIONS } from '../filter-options';

import styles from './filter-type-selectors.module.scss';

export const FilterTypeSelectors = ({ control, filterToEdit }) => {
  const filter1 = useWatch({
    control,
    name: 'filter1',
    defaultValue: filterToEdit?.filter1 || undefined,
  });
  const showVs = !!filter1?.vs;

  return (
    <div className={styles['filter-type-selectors']}>
      <FormNestedRadio
        control={control}
        name="filter1"
        defaultValue={filterToEdit?.filter1 || undefined}
        options={FILTER_OPTIONS}
        formControlClassName={styles['form-control']}
        className={styles['input']}
        rules={{
          required: 'Field is required',
        }}
      />

      {showVs && (
        <>
          <span className={styles['vs']}>vs</span>

          <FormSelect
            control={control}
            name="filter2"
            defaultValue={filterToEdit?.filter2 || PLACEHOLDER_SELECT_OPTION}
            options={BENCHMARK_OPTIONS}
            formControlClassName={styles['form-control']}
            className={styles['input']}
            rules={{ validate: VALIDATORS.getRequiredOptionValidator() }}
          />
        </>
      )}
    </div>
  );
};

FilterTypeSelectors.propTypes = {
  control: PropTypes.object.isRequired,
  filterToEdit: PropTypes.object,
};
