import React from 'react';
import PropTypes from 'prop-types';
import { DecimalFormatter } from '@core/formatters';

/**
 * A formatter for use in table results
 * will handle -1,-2 by default unless disabled
 * @param {*} param0
 * @returns
 */
export const DecimalFormatterNegativeHandler = ({
  value,
  disableNegativeHandling,
}) => {
  return (
    <DecimalFormatter
      value={value}
      handleNegativeValues={!disableNegativeHandling}
    />
  );
};

DecimalFormatterNegativeHandler.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disableNegativeHandling: PropTypes.bool,
};
