import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@backstop/react-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logout as logoutAction } from '@redux/actions/login-actions';
import { isKeyEnter } from '@core/keyboard-helpers';
import { useUserData } from '@core/user';
import styles from './profile-actions.module.scss';
import { useLogout } from '~/api/auth';

export const ProfileActions = () => {
  const dispatch = useDispatch();
  const { logout: logoutApi } = useLogout();
  const logout = () => {
    logoutApi();
    dispatch(logoutAction());
  };

  const { firstName, loading } = useUserData();

  const buttonLabel = (
    <span className={styles['button-label']} title={name}>
      {firstName}
    </span>
  );

  const handleKeyDown = (e, logout) => isKeyEnter(e) && logout();

  const buttonRef = useRef(null);
  const [width, setWidth] = useState(0);
  useEffect(() => setWidth(buttonRef?.current?.clientWidth), []);

  const isDataLoading = loading;

  return (
    <Button.DropdownButton
      ref={buttonRef}
      className={styles['profile-button']}
      label={buttonLabel}
      kind="secondary"
      icon={<FontAwesomeIcon icon={'user'} className={styles['icon']} />}
      dropdownProps={{ minWidth: width }}
      spinner={isDataLoading}
    >
      <Button.MenuOption
        key={logout}
        onClick={logout}
        onKeyDown={(e) => handleKeyDown(e, logout)}
      >
        Logout
      </Button.MenuOption>
    </Button.DropdownButton>
  );
};
