import api from '@api';

export const useSaveSearchButton = () => {
  const { saveSearch } = api.saveSearch.useSaveSearch();

  const saveSearchData = (data, name) => {
    saveSearch(data, name);
  };

  return { saveSearchData };
};
