import React from 'react';
import PropTypes from 'prop-types';
import { BasicTable } from '@common/basic-table';
import { DecimalPercentageFormatter } from '@core/formatters';

export const AnnualizedReturns = ({ programData }) => {
  return (
    <BasicTable>
      <BasicTable.Title colSpan="2">Annualized Returns</BasicTable.Title>
      <BasicTable.Row>
        <BasicTable.Header>3 Years</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatter
            value={programData.compoundAnnualReturn3year}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>5 Years</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatter
            value={programData.compoundAnnualReturn5year}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>10 Years</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatter
            value={programData.compoundAnnualReturn10year}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Inception to Date</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatter
            value={programData.compoundAnnualReturn}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};

AnnualizedReturns.propTypes = {
  programData: PropTypes.object.isRequired,
};
