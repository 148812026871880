import React from 'react';
import { ErrorPageBase } from './error-page-base';

export const AifmdRestricted = () => {
  return (
    <ErrorPageBase
      title={'Fund Unavailable Due to AIFMD Regulations'}
      message={
        'The manager of this fund has indicated that it falls under AIFMD compliance policies. You are seeing this notice because your profile places you in an EU geography that is governed by the European Commission and prohibits BarclayHedge from providing the details of this fund. If you believe that you have received this notice in error, please contact your BarclayHedge Data Administrator.'
      }
    />
  );
};
