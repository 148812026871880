// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".body--lm2Cz{display:flex;flex-wrap:wrap;gap:8px;margin-bottom:50px}", "",{"version":3,"sources":["webpack://./src/components/common/search-query-chips/search-query-chips.module.scss"],"names":[],"mappings":"AAAA,aACE,YAAA,CACA,cAAA,CACA,OAAA,CACA,kBAAA","sourcesContent":[".body {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 8px;\n  margin-bottom: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "body--lm2Cz"
};
export default ___CSS_LOADER_EXPORT___;
