import React from 'react';
import PropTypes from 'prop-types';
import { Button, FieldPicker, Modal } from '@backstop/react-core';

import { COLUMN_OPTIONS } from '~/components/search-results/column-options';

import { useModalState, useFieldPickerData } from './hooks';
import { getHidden, getSelected } from './helpers';

import styles from './column-selector.module.scss';

export const ColumnSelector = ({
  hiddenColumnNames,
  onHiddenColumnNamesChange,
}) => {
  const { modalOpen, toggleModalState, closeModal } = useModalState();
  const fieldPickerData = useFieldPickerData(COLUMN_OPTIONS);
  const selected = getSelected(hiddenColumnNames);

  const handleCancel = () => {
    closeModal();
  };

  const handleConfirm = (newSelectedItems) => {
    const newHiddenList = getHidden(newSelectedItems);
    onHiddenColumnNamesChange(newHiddenList);
    closeModal();
  };

  const modalStyles = {
    content: { background: 'transparent', width: '57.5rem' },
    overlay: { overflow: 'auto' },
  };

  return (
    <>
      <Button kind="secondary" version="v3" onClick={toggleModalState}>
        Columns
      </Button>

      <Modal
        version="v3"
        open={modalOpen}
        toggle={closeModal}
        onRequestClose={closeModal}
        closeOnOverlayClick={true}
        closeOnEscape={true}
        footerVisible={false}
        bodyClassName={styles['field-picker-modal__modal-body']}
        style={modalStyles}
      >
        <FieldPicker
          title="Manage Columns"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          data={fieldPickerData}
          selected={selected}
          renderSelectedItem={(item) => item.columnLabel}
          className={styles['field-picker-modal__field-picker']}
        />
      </Modal>
    </>
  );
};

ColumnSelector.defaultProps = {
  availableColumns: [],
  hiddenColumnNames: [],
};

ColumnSelector.propTypes = {
  hiddenColumnNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  onHiddenColumnNamesChange: PropTypes.func.isRequired,
};
