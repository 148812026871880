import React from 'react';
import PropTypes from 'prop-types';

import { REGEX, requiredFieldMessage, VALIDATORS } from '@core/validators';
import { FormSelect, FormTextInput } from '@common/form-inputs';
import { useDbSampleOptions, useProfessionalFieldOptions } from '../hooks';

import styles from './field-column-left.module.scss';

export const FieldColumnLeft = ({ control, loading }) => {
  const { professionalFieldOptions } = useProfessionalFieldOptions();
  const { dbSampleOptions } = useDbSampleOptions();

  return (
    <div className={styles['body']}>
      <FormTextInput
        name="firstName"
        label="First Name"
        placeholder="First Name"
        control={control}
        disabled={loading}
        maxLength={20}
        rules={{ required: requiredFieldMessage }}
      />
      <FormTextInput
        name="lastName"
        label="Last Name"
        placeholder="Last Name"
        control={control}
        disabled={loading}
        maxLength={20}
        rules={{ required: requiredFieldMessage }}
      />
      <FormTextInput
        name="email"
        label="Email Address"
        placeholder="Email Address"
        control={control}
        disabled={loading}
        maxLength={48}
        rules={{
          required: requiredFieldMessage,
          pattern: {
            value: REGEX.email,
            message: 'Please enter a valid email',
          },
        }}
      />
      <FormTextInput
        name="company"
        label="Company"
        placeholder="Company"
        control={control}
        disabled={loading}
        maxLength={30}
        rules={{ required: requiredFieldMessage }}
      />

      <FormSelect
        control={control}
        name="professionalField"
        label="Professional Field"
        options={professionalFieldOptions}
        disabled={loading}
        rules={{ validate: VALIDATORS.getRequiredOptionValidator() }}
      />
      <FormSelect
        control={control}
        name="database"
        label="Free Database Sample"
        options={dbSampleOptions}
        disabled={loading}
        rules={{ validate: VALIDATORS.getRequiredOptionValidator() }}
      />
    </div>
  );
};

FieldColumnLeft.propTypes = {
  control: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};
