import React from 'react';
import PropTypes from 'prop-types';
import styles from './placeholder.module.scss';

export const Placeholder = ({ value }) => {
  return <div className={styles['body']}>{value}</div>;
};

Placeholder.propTypes = {
  value: PropTypes.string.isRequired,
};
