// login
export const LOGOUT = 'LOGOUT';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_PASS = 'LOGIN_PASS';

// Cache
export const CACHE_SET = 'CACHE_SET';
export const CACHE_CLEAN = 'CACHE_CLEAN';

// Cache loading
export const CACHE_LOADING_SET = 'CACHE_LOADING_SET';
export const CACHE_LOADING_CLEAN = 'CACHE_LOADING_CLEAN';

// Search params
export const PARAMS_SET = 'PARAMS_SET';
export const PARAMS_CLEAN = 'PARAMS_CLEAN';
export const FORM_PARAMS_SET = 'FORM_PARAMS_SET';
export const COLUMN_ORDER_SET = 'COLUMN_ORDER_SET';
export const ACTIVE_COLUMNS_SET = 'ACTIVE_COLUMNS_SET';

// User data
export const USER_DATA_SET = 'USER_DATA_SET';
export const USER_DATA_LOADING_SET = 'USER_DATA_LOADING_SET';
export const USER_ROLES_SET = 'USER_ROLES_SET';
export const USER_DATA_CLEAN = 'USER_DATA_CLEAN';

// Layout
export const WINDOW_SIZE_SET = 'WINDOW_SIZE_SET';
export const SIDEBAR_COLLAPSED_SET = 'SIDEBAR_COLLAPSED_SET';
export const SIDEBAR_COLLAPSED_TOGGLE = 'SIDEBAR_COLLAPSED_TOGGLE';

// User save funds
export const USER_FAVORITE_FUNDS_SET = 'USER_FAVORITE_FUNDS_SET';
export const USER_FAVORITE_FUNDS_DELETE = 'USER_FAVORITE_FUNDS_DELETE';
export const USER_FAVORITE_FUNDS_ADD = 'USER_FAVORITE_FUNDS_ADD';

// User save search
export const USER_SAVE_SEARCH_SET = 'USER_SAVE_SEARCH_SET';
export const USER_SAVE_SEARCH_ADD = 'USER_SAVE_SEARCH_ADD';
export const USER_SAVE_SEARCH_DELETE = 'USER_SAVE_SEARCH_DELETE';
export const USER_SAVE_SEARCH_UPDATE = 'USER_SAVE_SEARCH_UPDATE';
