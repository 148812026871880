import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Button } from '@backstop/react-core';
import { isKeyEnter } from '@core/keyboard-helpers';
import { FORM_INCLUSION_OPTIONS } from '@core/select-options';

export const FormInclusion = ({ name, control, defaultValue }) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || 'OR'}
      shouldUnregister={true}
      render={({ field: { onChange, value } }) => {
        const label = FORM_INCLUSION_OPTIONS.find(
          (option) => option.value === value
        ).label;
        const handleKeyDown = (e, option) =>
          isKeyEnter(e) && onChange(option.value);

        return (
          <Button.DropdownButton label={label} kind="secondary" size="small">
            {FORM_INCLUSION_OPTIONS.map((option) => (
              <Button.MenuOption
                key={option.value}
                onClick={() => onChange(option.value)}
                onKeyDown={(e) => handleKeyDown(e, option)}
              >
                {option.label}
              </Button.MenuOption>
            ))}
          </Button.DropdownButton>
        );
      }}
    />
  );
};

FormInclusion.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.string,
};
