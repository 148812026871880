import React from 'react';
import PropTypes from 'prop-types';
import { BasicTable } from '@common/basic-table';

export const ManagerInformation = ({ companyData }) => {
  return (
    <BasicTable widths={['25%', '25%', '25%', '25%']}>
      <BasicTable.Title colSpan="4">Manager Information</BasicTable.Title>
      <BasicTable.Row>
        <BasicTable.Header>Name</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>{companyData.name}</BasicTable.Cell>
        <BasicTable.Header>Email</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>{companyData.email}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Address</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>{companyData.address1}</BasicTable.Cell>
        <BasicTable.Header>Address (cont.)</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>{companyData.address2}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>City</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>{companyData.city}</BasicTable.Cell>
        <BasicTable.Header>State/Province</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>{companyData.state}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Zip Code</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>{companyData.zip}</BasicTable.Cell>
        <BasicTable.Header>Country</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>{companyData.country}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Phone</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>{companyData.phone1}</BasicTable.Cell>
        <BasicTable.Header>Fax</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>{companyData.fax}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Website</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>{companyData.dataUrl}</BasicTable.Cell>
        <BasicTable.Header>SEC/NFA Registration</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>
          {companyData.secNumber}
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Number of Employees</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>
          {companyData.numEmployees}
        </BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};

ManagerInformation.propTypes = {
  companyData: PropTypes.object.isRequired,
};
