export const isItemChecked = (checkedItems, id) => {
  const item = getItemById(checkedItems, id);
  if (!item) return false;

  return item ? !!item.checked : false;
};

export const getCheckedChildren = (checkedItems, id) => {
  const selectedItem = getItemById(checkedItems, id);
  if (!selectedItem) return [];
  return selectedItem.children;
};

export const isParentItem = (item) => item.children && item.children.length > 0;

export const getIsParentChecked = (selected, option) => {
  let selectedCount = 0;
  let partiallySelectedCount = 0;

  selected.forEach((item) => {
    let itemSelected = false;

    if (isParentItem(item)) {
      const parentOption = option.children.find(
        (child) => child.id === item.id
      );

      const parentCheckedValue = getIsParentChecked(
        item.children,
        parentOption
      );

      if (parentCheckedValue === true) itemSelected = true;
      if (parentCheckedValue === 'mixed') partiallySelectedCount++;
    } else {
      itemSelected = !!item.checked;
    }

    if (itemSelected) {
      selectedCount++;
    }
  });
  if (option.children.length === selectedCount) {
    return true;
  }

  return selectedCount > 0 || partiallySelectedCount > 0 ? 'mixed' : false;
};

export const applyChildChange = ({ id, isChecked, selectedItems, items }) => {
  if (!isChecked) {
    return removeById(selectedItems, id);
  }

  const newSelectedItems = [...selectedItems, { id, checked: true }];
  return sortSelectedItems(newSelectedItems, items);
};

export const applyParentUpdate = ({
  parentId,
  checkedChildren,
  selectedItems,
  parentItem,
}) => {
  const atLeastOneChildSelected = checkedChildren && checkedChildren.length > 0;

  if (!atLeastOneChildSelected) {
    return removeById(selectedItems, parentId);
  }

  if (existsWithId(selectedItems, parentId)) {
    const newSelectedItems = updateChildren(
      selectedItems,
      parentId,
      checkedChildren
    );
    return newSelectedItems;
  } else {
    const newSelectedItemsUnsorted = [
      ...selectedItems,
      { id: parentId, children: checkedChildren },
    ];
    const newSelectedItemsSorted = sortSelectedItems(
      newSelectedItemsUnsorted,
      parentItem.children
    );
    return newSelectedItemsSorted;
  }
};

export const checkAllDescendants = (root) => {
  return root.children.map((child) => {
    if (isParentItem(child)) {
      return {
        id: child.id,
        children: checkAllDescendants(child),
      };
    } else {
      return {
        id: child.id,
        checked: true,
      };
    }
  });
};

export const getFlatList = (selectedTree) => {
  const flatList = [];
  collectLeafNodes(selectedTree, flatList);
  return flatList;
};

export const getSelectedLabels = (selectedItems, options) => {
  return selectedItems.reduce((labels, item) => {
    if (isParentItem(item)) {
      const subSptions = options.find(
        (option) => option.id === item.id
      ).children;
      return [...labels, ...getSelectedLabels(item.children, subSptions)];
    } else {
      const label = options.find((option) => option.id === item.id).optionLabel;
      return [...labels, label];
    }
  }, []);
};

export const getSelectedItems = (selectedIds, options) => {
  return options
    .map((option) => {
      if (isParentItem(option)) {
        const subSelected = getSelectedItems(
          selectedIds,
          option.children
        ).filter((item) => !!item);

        if (subSelected.length > 0) {
          return {
            id: option.id,
            children: subSelected,
          };
        } else {
          return null;
        }
      } else {
        if (selectedIds.includes(option.id)) {
          return {
            id: option.id,
            checked: true,
          };
        } else {
          return null;
        }
      }
    })
    .filter((item) => !!item);
};

const sortSelectedItems = (selectedItems, optionTree) => {
  const sortOrder = optionTree.reduce((acc, item, index) => {
    acc[item.id] = index;
    return acc;
  }, {});
  return [...selectedItems].sort((a, b) => sortOrder[a.id] - sortOrder[b.id]);
};

const existsWithId = (items, id) => {
  return items.some((item) => item.id === id);
};

const updateChildren = (items, id, children) => {
  return items.map((item) => {
    if (item.id !== id) return item;

    return {
      ...item,
      children,
    };
  });
};

const collectLeafNodes = (children, flatList) => {
  children.forEach((child) => {
    if (isParentItem(child)) collectLeafNodes(child.children, flatList);
    else flatList.push(child);
  });
};

const getItemById = (items, id) => {
  return items.find((item) => item.id === id);
};

const removeById = (items, id) => {
  return items.filter((item) => item.id !== id);
};
