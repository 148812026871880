import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { FormControl } from '@common/form-control';
import { InputWithTooltip } from '@common/input-with-tooltip';
import { FilterDropdownContainer } from './filter-dropdown-container';
import {
  DropdownSelectFilter,
  getDisplayName,
} from './dropdown-filters/dropdown-select-filter';
import { FormSelectWrapper } from './filters';

export const FormSelect = ({
  name,
  label,
  tooltipText,
  sideTooltipText,
  control,
  defaultValue,
  rules,
  options,
  placeholder = 'Select one',
  showConfirmationModal,
  formControlClassName,
  ...restProps
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={true}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => {
        const errorList = error ? [error.message] : null;
        return (
          <FormControl
            label={label}
            errors={errorList}
            tooltipText={tooltipText}
            className={formControlClassName}
          >
            <InputWithTooltip tooltipText={sideTooltipText}>
              {showConfirmationModal ? (
                <FilterDropdownContainer
                  name={name}
                  label={label}
                  options={options}
                  placeholder={placeholder}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  getDisplayName={getDisplayName}
                  hasError={!!error}
                >
                  <DropdownSelectFilter options={options} {...restProps} />
                </FilterDropdownContainer>
              ) : (
                <FormSelectWrapper
                  version="v3"
                  name={name}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  options={options}
                  hasError={!!error}
                  inputRef={ref}
                  {...restProps}
                />
              )}
            </InputWithTooltip>
          </FormControl>
        );
      }}
    />
  );
};

FormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  tooltipText: PropTypes.string,
  sideTooltipText: PropTypes.string,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.any,
  rules: PropTypes.object,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  showConfirmationModal: PropTypes.bool,
  formControlClassName: PropTypes.string,
};
