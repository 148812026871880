// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".body--xh2tG{display:flex;gap:15px}.body--xh2tG .form-control--pqGUA,.body--xh2tG .input--m7LuC{width:100%}.body--xh2tG .and--gWgdV{margin-top:18px}", "",{"version":3,"sources":["webpack://./src/components/main-search/custom-filters/custom-filter-modal/filter-value-selectors/filter-value-selectors.module.scss"],"names":[],"mappings":"AAAA,aACE,YAAA,CACA,QAAA,CAEA,6DAEE,UAAA,CAGF,yBACE,eAAA","sourcesContent":[".body {\n  display: flex;\n  gap: 15px;\n\n  .form-control,\n  .input {\n    width: 100%;\n  }\n\n  .and {\n    margin-top: 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "body--xh2tG",
	"form-control": "form-control--pqGUA",
	"input": "input--m7LuC",
	"and": "and--gWgdV"
};
export default ___CSS_LOADER_EXPORT___;
