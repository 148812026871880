import React, { useState } from 'react';
import { Button } from '@backstop/react-core';
import { useSaveSearchButton } from './hooks';
import Confirm from '@common/modals/Confirm';
import { TextInput } from '@backstop/react-core';

export const SaveSearchButton = ({ formParams, allCols, hiddenCols }) => {
  const { saveSearchData } = useSaveSearchButton();
  const [showConfirm, setConfirm] = useState(false);
  const [value, setValue] = useState('');

  const onChange = (change) => {
    setValue(change);
  };

  let activeCols = allCols.filter((col) => !hiddenCols.includes(col.name));
  let colsData = [];

  for (const [key, value] of Object.entries(activeCols)) {
    colsData.push(value.name);
  }

  const saveAndClose = (param) => {
    saveSearchData(param, value);
    setConfirm(false);
    setValue('');
  };

  return (
    <>
      <Button kind="secondary" version="v3" onClick={() => setConfirm(true)}>
        Save Search
      </Button>
      <Confirm
        confirmParam={{ formParams: formParams, activeCols: colsData }}
        confirmFunction={saveAndClose}
        isOpen={showConfirm}
        toggleCancel={() => setConfirm(false)}
        header="Save Search"
      >
        <TextInput
          version="v3"
          name="searchName"
          placeholder="Search Name"
          onChange={onChange}
          value={value}
        />
      </Confirm>
    </>
  );
};
