import api from '@api';
import { useEffect } from 'react';

export const useAwardsTab = (progId) => {
  const { getAwardsByProgram, data, loading } =
    api.awardsRanks.useProgramRanks();

  useEffect(() => {
    getAwardsByProgram(progId);
  }, [progId]);

  return { data, loading };
};
