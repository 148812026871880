import { COLUMNS } from './columns';

const BASIC_OPTIONS = [
  COLUMNS.type,
  COLUMNS.compName,
  COLUMNS.cik,
  COLUMNS.compCountry,
  COLUMNS.compState,
  COLUMNS.denomination,
  COLUMNS.denominationMum,
  COLUMNS.hurdle,
  COLUMNS.isin,
  COLUMNS.lockup,
  COLUMNS.primaryBenchmark,
  COLUMNS.secondaryBenchmark,
  COLUMNS.redemptionFees,
  COLUMNS.redemptions,
  COLUMNS.stateDomicile,
  COLUMNS.subscriptions,
  COLUMNS.subsequentInvestmentSize,
  COLUMNS.us,
];

const STRATEGY_OPTIONS = [
  COLUMNS.strategy,
  COLUMNS.proprietaryAccount,
  COLUMNS.managedAccount,
  COLUMNS.primaryCat, //TODO: Need to find a correct mapping to resolve option labels
  COLUMNS.sec13f,
];

const DETAILED_OPTIONS = [
  COLUMNS.open,
  COLUMNS.denominat,
  COLUMNS.exchangeListing,
  COLUMNS.geoFocus,
  COLUMNS.highwater,
  COLUMNS.returnType,
];

const GENERAL_OPTIONS = [
  COLUMNS.annualStandardDev,
  COLUMNS.aum,
  COLUMNS.createDate,
  COLUMNS.inceptionDate,
  COLUMNS.incentiveFee,
  COLUMNS.lastModified,
  COLUMNS.leverage,
  COLUMNS.managerAssets,
  COLUMNS.managementFee,
  COLUMNS.meRatio,
  COLUMNS.monthlyStandardDev,
  COLUMNS.minAccount,
  COLUMNS.minInvest,
  COLUMNS.skewness,
  COLUMNS.sharpeRatio,
  COLUMNS.calmarRatio,
  COLUMNS.sortinoRatio,
  COLUMNS.worstDrawDown,
];

const ANNUALIZED_RETURN_OPTIONS = [
  COLUMNS.compoundAnnualReturn3year,
  COLUMNS.compoundAnnualReturn5year,
  COLUMNS.compoundAnnualReturn7year,
  COLUMNS.compoundAnnualReturn10year,
  COLUMNS.compoundAnnualReturn,
];

const COMPOUNDED_RETURN_OPTIONS = [
  COLUMNS.yearToDate,
  COLUMNS.return1Year,
  COLUMNS.return2Year,
  COLUMNS.return3Year,
  COLUMNS.return4Year,
  COLUMNS.return5Year,
  COLUMNS.totalReturn,
];

const CUSTOM_RETURN_OPTIONS = [
  {
    value: 'annualized',
    label: 'Annualized Return',
    children: ANNUALIZED_RETURN_OPTIONS,
  },
  {
    value: 'compounded',
    label: 'Compounded Return',
    children: COMPOUNDED_RETURN_OPTIONS,
  },
  COLUMNS.averageMonthlyReturn,
];

const ALPHA_VS_OPTIONS = [
  COLUMNS.alphaVsUsTreasury,
  COLUMNS.alphaVsCta,
  COLUMNS.alphaVsEquity,
  COLUMNS.alphaVsFof,
  COLUMNS.alphaVsHf,
  COLUMNS.alphaVsDow,
  COLUMNS.alphaVsFtSe,
  COLUMNS.alphaVsJpMorgan,
  COLUMNS.alphaVsEafe,
  COLUMNS.alphaVsMsciWorld,
  COLUMNS.alphaVsNasdaq,
  COLUMNS.alphaVsNikkei,
  COLUMNS.alphaVsRussel,
  COLUMNS.alphaVsSp500,
];

const BETA_VS_OPTIONS = [
  COLUMNS.betaVsUsTreasury,
  COLUMNS.betaVsCta,
  COLUMNS.betaVsEquity,
  COLUMNS.betaVsFof,
  COLUMNS.betaVsHf,
  COLUMNS.betaVsDow,
  COLUMNS.betaVsFtSe,
  COLUMNS.betaVsJpMorgan,
  COLUMNS.betaVsEafe,
  COLUMNS.betaVsMsciWorld,
  COLUMNS.betaVsNasdaq,
  COLUMNS.betaVsNikkei,
  COLUMNS.betaVsRussel,
  COLUMNS.betaVsSp500,
];

const CORRELATION_VS_OPTIONS = [
  COLUMNS.corrVsUsTreasury,
  COLUMNS.corrVsCta,
  COLUMNS.corrVsEquity,
  COLUMNS.corrVsFof,
  COLUMNS.corrVsHf,
  COLUMNS.corrVsDow,
  COLUMNS.corrVsFtSe,
  COLUMNS.corrVsJpMorgan,
  COLUMNS.corrVsEafe,
  COLUMNS.corrVsMsciWorld,
  COLUMNS.corrVsNasdaq,
  COLUMNS.corrVsNikkei,
  COLUMNS.corrVsRussel,
  COLUMNS.corrVsSp500,
];

const R_SQUARED_VS_OPTIONS = [
  COLUMNS.rSqVsUsTreasury,
  COLUMNS.rSqVsCta,
  COLUMNS.rSqVsEquity,
  COLUMNS.rSqVsFof,
  COLUMNS.rSqVsHf,
  COLUMNS.rSqVsDow,
  COLUMNS.rSqVsFtSe,
  COLUMNS.rSqVsJpMorgan,
  COLUMNS.rSqVsEafe,
  COLUMNS.rSqVsMsciWorld,
  COLUMNS.rSqVsNasdaq,
  COLUMNS.rSqVsNikkei,
  COLUMNS.rSqVsRussel,
  COLUMNS.rSqVsSp500,
];

const UP_CAPTURE_VS_OPTIONS = [
  COLUMNS.upCapVsUsTreasury,
  COLUMNS.upCapVsCta,
  COLUMNS.upCapVsEquity,
  COLUMNS.upCapVsFof,
  COLUMNS.upCapVsHf,
  COLUMNS.upCapVsDow,
  COLUMNS.upCapVsFtSe,
  COLUMNS.upCapVsJpMorgan,
  COLUMNS.upCapVsEafe,
  COLUMNS.upCapVsMsciWorld,
  COLUMNS.upCapVsNasdaq,
  COLUMNS.upCapVsNikkei,
  COLUMNS.upCapVsRussel,
  COLUMNS.upCapVsSp500,
];

const DOWN_CAPTURE_VS_OPTIONS = [
  COLUMNS.downCapVsUsTreasury,
  COLUMNS.downCapVsCta,
  COLUMNS.downCapVsEquity,
  COLUMNS.downCapVsFof,
  COLUMNS.downCapVsHf,
  COLUMNS.downCapVsDow,
  COLUMNS.downCapVsFtSe,
  COLUMNS.downCapVsJpMorgan,
  COLUMNS.downCapVsEafe,
  COLUMNS.downCapVsMsciWorld,
  COLUMNS.downCapVsNasdaq,
  COLUMNS.downCapVsNikkei,
  COLUMNS.downCapVsRussel,
  COLUMNS.downCapVsSp500,
];

const CUSTOM_COMPARISON_OPTIONS = [
  {
    value: 'alpha',
    label: 'Alpha vs',
    children: ALPHA_VS_OPTIONS,
  },
  {
    value: 'beta',
    label: 'Beta vs',
    children: BETA_VS_OPTIONS,
  },
  {
    value: 'correlation',
    label: 'Correlation vs',
    children: CORRELATION_VS_OPTIONS,
  },
  {
    value: 'rSquared',
    label: 'R^2 vs',
    children: R_SQUARED_VS_OPTIONS,
  },
  {
    value: 'upCapture',
    label: 'Up Capture',
    children: UP_CAPTURE_VS_OPTIONS,
  },
  {
    value: 'downCapture',
    label: 'Down Capture',
    children: DOWN_CAPTURE_VS_OPTIONS,
  },
];

export const COLUMN_OPTIONS = [
  {
    value: 'basic',
    label: 'Basic',
    children: BASIC_OPTIONS,
  },
  {
    value: 'strategy',
    label: 'Strategy',
    children: STRATEGY_OPTIONS,
  },
  {
    value: 'detailed',
    label: 'Detailed',
    children: DETAILED_OPTIONS,
  },
  {
    value: 'custom',
    label: 'Custom',
    children: [
      {
        value: 'general',
        label: 'General',
        children: GENERAL_OPTIONS,
      },
      {
        value: 'return',
        label: 'Return',
        children: CUSTOM_RETURN_OPTIONS,
      },
      {
        value: 'comparison',
        label: 'Comparison',
        children: CUSTOM_COMPARISON_OPTIONS,
      },
    ],
  },
];
