import { useEffect } from 'react';
import api from '@api';

export const useDenominationOptions = () => {
  const { getDenominationOptions, denominations } =
    api.select.useDenominationOptions();

  useEffect(() => {
    getDenominationOptions();
  }, []);

  return { denominations };
};
