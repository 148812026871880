import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@backstop/react-core';
import { ButtonWithConfirmation } from '@common/button-with-confirmation';

import styles from './custom-filter.module.scss';

export const CustomFilter = ({ index, field, onEdit, onRemove }) => {
  const { filter1, filter2, comparisonMethod, value1, value2 } = field;
  const filter1Label = filter1.fullLabel || filter1.label;

  const filter2Label = filter2 && (filter2.fullLabel || filter2.label);

  return (
    <div className={styles['body']}>
      <span className={styles['filter']}>
        <strong>{filter1Label}</strong>
        {filter2Label && (
          <>
            &nbsp;vs <strong>{filter2Label}</strong>
          </>
        )}
        &nbsp;is {comparisonMethod.label}&nbsp;
        {value2 ? (
          <>
            <strong>{value1}</strong> and <strong>{value2}</strong>
          </>
        ) : (
          <strong>{value1}</strong>
        )}
      </span>

      <div className={styles['buttons']}>
        <Button.EditIconButton
          type="edit"
          version="v3"
          onClick={() => onEdit(index, field)}
        />
        <ButtonWithConfirmation
          iconButtonType="cancel"
          confirmButtonText="Remove"
          confirmationTitle="Remove custom filter"
          confirmationMessage="Do you really want to remove the filter"
          onConfirm={() => onRemove(index)}
          closeOnConfirm={false}
        />
      </div>
    </div>
  );
};

CustomFilter.propTypes = {
  index: PropTypes.number.isRequired,
  field: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};
