import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useYearlyReturnData } from './hooks';
import { Loader } from '@common/loader';

export const TwelveMonthReturnsChart = ({ programData }) => {
  const { data, loading } = useYearlyReturnData(programData?.id);

  if (loading) {
    return <Loader />;
  }

  if (data?.length == 0) {
    return <p>No returns found for the last 12 months</p>;
  }

  const chartData = [];
  for (let i = 0; i < data?.length; i++) {
    chartData.push([Date.parse(data[i].date), parseFloat(data[i].ror)]);
  }

  const options = {
    title: {
      text: 'Returns For Preceding 12-Mo. Periods',
      align: 'left',
    },
    chart: { type: 'column' },
    yAxis: { title: { text: 'Rate of Return' } },
    xAxis: {
      type: 'datetime',
      labels: {
        formatter: function () {
          return Highcharts.dateFormat('%b %y', this.value);
        },
      },
    },

    credits: { enabled: false },

    series: [{ name: programData.name, data: chartData, pointWidth: 20 }],
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

TwelveMonthReturnsChart.propTypes = {
  programData: PropTypes.object.isRequired,
};
