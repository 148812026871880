import React from 'react';
import PropTypes from 'prop-types';
import { PROGRAM_TYPES, WINDOW_SIZES } from '@core/constants';
import { SEARCH_FIELDS } from '@core/search-fields';
import {
  CTA_STRATEGY_OPTIONS,
  HEDGE_FUND_STRATEGY_OPTIONS,
} from '@core/select-options';
import { useLayoutData } from '@core/layout';
import { FormCheckbox, FormMultiselect, FormSelect } from '@common/form-inputs';
import { FiltersContainer } from '../filters-container';
import { CTA_INCLUSION_OPTIONS, HF_INCLUSION_TOOLTIP_TEXT } from './constants';
import { useHfIclusionOptions } from './hooks';
import styles from './strategy-filters.module.scss';
import classnames from 'classnames';

export const StrategyFilters = ({ control, watch }) => {
  const { windowSize } = useLayoutData();
  const programType = watch(SEARCH_FIELDS.programType.id);

  const showCta = !programType || programType === PROGRAM_TYPES.CTA;
  const showHedgeFund = !programType || programType === PROGRAM_TYPES.HedgeFund;

  const hfInclusion = watch(SEARCH_FIELDS.hfInclusion.id);
  const maxHfStrategyCount = hfInclusion === 'AND' ? 2 : undefined;

  const hfStrategies = watch(SEARCH_FIELDS.hfStrategies.id);
  const hfIclusionOptions = useHfIclusionOptions(hfStrategies?.length);

  const rowStyles = classnames(styles['row'], {
    [styles['row--collapsed']]: windowSize < WINDOW_SIZES.MD,
  });

  return (
    <FiltersContainer title="Strategy Filters">
      <div className={rowStyles}>
        {showCta && (
          <div className={styles['col']}>
            <FormMultiselect
              name={SEARCH_FIELDS.ctaStrategies.id}
              label={SEARCH_FIELDS.ctaStrategies.label}
              control={control}
              options={CTA_STRATEGY_OPTIONS}
              defaultValue={SEARCH_FIELDS.ctaStrategies.defaultValue}
              showConfirmationModal
            />

            <FormSelect
              name={SEARCH_FIELDS.ctaInclusion.id}
              control={control}
              defaultValue={SEARCH_FIELDS.ctaInclusion.defaultValue}
              options={CTA_INCLUSION_OPTIONS}
              showConfirmationModal
            />

            <FormCheckbox
              name={SEARCH_FIELDS.excludeProprietaryTradingAccounts.id}
              label={SEARCH_FIELDS.excludeProprietaryTradingAccounts.label}
              control={control}
              checkboxLabel={
                SEARCH_FIELDS.excludeProprietaryTradingAccounts.checkboxLabel
              }
              defaultValue={
                SEARCH_FIELDS.excludeProprietaryTradingAccounts.defaultValue
              }
              className={styles['checkbox']}
            />
          </div>
        )}

        {showHedgeFund && (
          <div className={styles['col']}>
            <FormMultiselect
              name={SEARCH_FIELDS.hfStrategies.id}
              label={SEARCH_FIELDS.hfStrategies.label}
              control={control}
              options={HEDGE_FUND_STRATEGY_OPTIONS}
              defaultValue={SEARCH_FIELDS.hfStrategies.defaultValue}
              maxOptionsSelected={maxHfStrategyCount}
              showConfirmationModal
            />

            <FormSelect
              name={SEARCH_FIELDS.hfInclusion.id}
              control={control}
              defaultValue={SEARCH_FIELDS.hfInclusion.defaultValue}
              options={hfIclusionOptions}
              sideTooltipText={HF_INCLUSION_TOOLTIP_TEXT}
              showConfirmationModal
            />

            <FormCheckbox
              name={SEARCH_FIELDS.excludeManagedAccounts.id}
              label={SEARCH_FIELDS.excludeManagedAccounts.label}
              control={control}
              checkboxLabel={SEARCH_FIELDS.excludeManagedAccounts.checkboxLabel}
              defaultValue={SEARCH_FIELDS.excludeManagedAccounts.defaultValue}
              className={styles['checkbox']}
            />

            <FormCheckbox
              name={SEARCH_FIELDS.sec13f.id}
              label={SEARCH_FIELDS.sec13f.label}
              control={control}
              checkboxLabel={SEARCH_FIELDS.sec13f.checkboxLabel}
              defaultValue={SEARCH_FIELDS.sec13f.defaultValue}
              className={styles['checkbox']}
            />
          </div>
        )}
      </div>
    </FiltersContainer>
  );
};

StrategyFilters.propTypes = {
  control: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
};
