import React from 'react';
import PropTypes from 'prop-types';
import { BasicTable } from '@common/basic-table';

export const Bio = ({ companyData }) => {
  return (
    <BasicTable>
      <BasicTable.Title>Key Principals - Bio</BasicTable.Title>

      <BasicTable.Row>
        <BasicTable.Cell alignLeft>{companyData.princBio}</BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};

Bio.propTypes = {
  companyData: PropTypes.object.isRequired,
};
