import React from 'react';
import { Outlet } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@backstop/react-core/dist/styles.css';

import { Sidebar } from './components/sidebar';
import { Footer } from './components/footer';
import { AppHeader } from './components/headers/app-header';
import { Tagline } from './components/tagline';

import { useUserData } from './hooks';

import styles from './app.module.scss';

export default function App() {
  useUserData();

  return (
    <div className={styles['body']}>
      <div className={styles['header']}>
        <AppHeader />
      </div>
      <div className={styles['sidebar']}>
        <Sidebar />
      </div>
      <div className={styles['main']}>
        <Tagline />
        <Outlet />
      </div>
      <div className={styles['footer']}>
        <Footer />
      </div>
    </div>
  );
}
