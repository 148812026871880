import React from 'react';
import PropTypes from 'prop-types';
import { BasicTable } from '@common/basic-table';
import { DecimalFormatter } from '@core/formatters';

export const RiskRewardRatios = ({ programData }) => {
  return (
    <BasicTable>
      <BasicTable.Title colSpan="2">Reward/Risk Ratios</BasicTable.Title>
      <BasicTable.Row>
        <BasicTable.Header>Calmar Ratio</BasicTable.Header>
        <BasicTable.Cell>{programData.calmarRatio}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Sortino Ratio</BasicTable.Header>
        <BasicTable.Cell>{programData.sortinoRatio}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Sharpe Ratio</BasicTable.Header>
        <BasicTable.Cell>{programData.sharpeRatio}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Sterling Ratio</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalFormatter value={programData.sterlingRatio} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Efficiency Ratio</BasicTable.Header>
        <BasicTable.Cell>{programData.efficiencyRatio}</BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};

RiskRewardRatios.propTypes = {
  programData: PropTypes.object.isRequired,
};
