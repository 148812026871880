import initialState from './initial-state';
import * as types from '../actions/action-types';

export default function cacheLoadingReducer(
  state = initialState.cacheLoading,
  action
) {
  switch (action.type) {
    case types.CACHE_LOADING_SET:
      return {
        ...state,
        [action.cacheKey]: true,
      };

    case types.CACHE_LOADING_CLEAN:
      return {
        ...state,
        [action.cacheKey]: undefined,
      };

    default:
      return state;
  }
}
