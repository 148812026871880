import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './coming-soon-page.module.scss';

export const ComingSoonPage = ({ message }) => {
  return (
    <div className={styles['body']}>
      <div className={styles['wrapper']}>
        <div className={styles['title']}>Coming soon!</div>
        <FontAwesomeIcon icon={'fa-bullhorn'} className={styles['icon']} />
      </div>
      <div className={styles['message']}>{message}</div>
    </div>
  );
};

ComingSoonPage.propTypes = {
  message: PropTypes.string,
};
