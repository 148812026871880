import React from 'react';
import PropTypes from 'prop-types';
import { Select } from '@backstop/react-core';

import styles from './form-select-wrapper.module.scss';
import classNames from 'classnames';

export const FormSelectWrapper = ({ className, ...rest }) => {
  const classes = classNames(styles['body'], className);
  return <Select className={classes} {...rest} />;
};

FormSelectWrapper.propTypes = {
  className: PropTypes.string,
};
