import PropTypes from 'prop-types';
import { dataUnavailable, toYesNo } from '@core/formatters';

export const BooleanValueFormatter = ({ value }) => {
  return dataUnavailable(toYesNo(value));
};

BooleanValueFormatter.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
};
