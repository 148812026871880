import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { InlineMessageBlock } from '@backstop/react-core';

import { AuthPageBase } from '@auth/auth-page-base';
import { RegisterForm } from './register-form';

import styles from './register.module.scss';

export const Register = () => {
  const [success, setSuccess] = useState(false);

  return (
    <AuthPageBase className={styles['body']}>
      <h1 className={styles['header']}>FREE USER REGISTRATION</h1>
      <p className={styles['info']}>
        Welcome to The BarclayHedge Registration. After you submit this
        registration, a message with an activation link will be sent to the
        email address you entered.
      </p>

      {success ? (
        <InlineMessageBlock kind="success">
          Your account has been created successfully.
          <Link to="/login"> Go to login</Link>
        </InlineMessageBlock>
      ) : (
        <>
          <RegisterForm setSuccess={setSuccess} />

          <div className={styles['return-to-login']}>
            Already have an account? <Link to="/login">Sign in</Link>
          </div>
        </>
      )}
    </AuthPageBase>
  );
};
