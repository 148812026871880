import React from 'react';
import PropTypes from 'prop-types';
import { BasicTable } from '@common/basic-table';

export const PrimeBrokerInformation = ({ programData }) => {
  return (
    <BasicTable>
      <BasicTable.Title colSpan="2">Prime Broker Information</BasicTable.Title>

      <BasicTable.Row>
        <BasicTable.Header>Prime Broker</BasicTable.Header>
        <BasicTable.Cell>{programData.primeBroker}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Prime Broker Contact</BasicTable.Header>
        <BasicTable.Cell>{programData.primeContact}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Prime Broker Phone</BasicTable.Header>
        <BasicTable.Cell>{programData.primeContactPhone}</BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};

PrimeBrokerInformation.propTypes = {
  programData: PropTypes.object.isRequired,
};
