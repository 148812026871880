import React from 'react';
import PropTypes from 'prop-types';
import { DecimalPercentageFormatterNegativeHandler } from '@core/formatters';
import { BasicTable } from '@common/basic-table';

export const PortfolioTimeSeries = ({ programData }) => {
  return (
    <BasicTable>
      <BasicTable.Title colSpan="2">Long/Short Exposure</BasicTable.Title>

      <BasicTable.Row>
        <BasicTable.Header>Long</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.avgLong}
          />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Short</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.avgShort}
          />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Net</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.avgNet}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};

PortfolioTimeSeries.propTypes = {
  programData: PropTypes.object.isRequired,
};
