import React from 'react';
import { DataUnavailableMessage } from '@core/data-unavailable-message';
import { isNumeric, isString, isEmpty } from '@core/validators';

export const toDecimal = (value) =>
  isNumeric(value) ? parseFloat(value)?.toFixed(2) : value;

export const toNumberWithCommas = (value) =>
  isNumeric(value) ? Intl.NumberFormat('en-US').format(value) : value;

export const toNumber = (value, fractionDigits) =>
  isNumeric(value)
    ? Intl.NumberFormat('en-US', {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      }).format(value)
    : value;

export const toPercent = (value) => (!isEmpty(value) ? `${value}%` : value);

export const toMillions = (value) => (!isEmpty(value) ? `${value}M` : value);

export const toThousands = (value) => (!isEmpty(value) ? `${value}K` : value);

export const toCurrency = (value, currency) => {
  if (isEmpty(value) || !currency) return value;

  const symbol = new Intl.NumberFormat('en', {
    style: 'currency',
    currency,
  })
    .formatToParts(value)
    .find((x) => x.type === 'currency');

  if (!symbol) return value;

  return `${symbol.value} ${value}`;
};

export const toDays = (value) =>
  value && isNumeric(value)
    ? value === 1
      ? `${value} day`
      : `${value} days`
    : value;

export const toYesNo = (value) => {
  if (isEmpty(value)) return value;

  if (
    value === 1 ||
    value === true ||
    (isString(value) && value.toLowerCase() === '1') ||
    (isString(value) && value.toLowerCase() === 'true') ||
    (isString(value) && value.toLowerCase() === 'yes')
  ) {
    return 'Yes';
  }

  if (
    value === 0 ||
    value === false ||
    (isString(value) && value.toLowerCase() === '0') ||
    (isString(value) && value.toLowerCase() === 'false') ||
    (isString(value) && value.toLowerCase() === 'no')
  ) {
    return 'No';
  }

  return value;
};

export const dataUnavailable = (value) => {
  if (
    isEmpty(value) ||
    (isString(value) &&
      (value.trim().length === 0 ||
        value.trim().toLowerCase() === 'null' ||
        value.trim().toLowerCase() === 'n/a'))
  ) {
    return <DataUnavailableMessage />;
  } else return value;
};
