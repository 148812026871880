import React from 'react';
import { useParams } from 'react-router-dom';
import { Tabs } from '@backstop/react-core';
import { PROGRAM_TYPE_LABELS_FULL } from '@core/constants';
import { Loader } from '@common/loader';
import {
  SnapshotTab,
  PerformanceTab,
  TradingTab,
  FundInfoTab,
  AdminTab,
  ContactsInfoTab,
  ManagerInfoTab,
  StatisticsRiskTab,
  AwardsTab,
} from './tabs';
import { useProgramData } from './hooks';

import styles from './program.module.scss';
import { FundNotActive } from '../error-pages';

export const Program = () => {
  const programId = useParams().id;

  const {
    data: programData,
    loading,
    isInitialLoad,
  } = useProgramData(programId);

  const hideAdminTab =
    programData?.ctaFund == 1 || programData?.multiadvisor == 1;

  const showProgramName2 = programData?.name2 && `(${programData.name2})`;

  const isDataLoading = isInitialLoad || loading;

  if (isDataLoading) return <Loader />;
  if (
    programData?.active !== '1' ||
    programData?.dataUsage.toUpperCase() !== 'ALL'
  ) {
    return <FundNotActive />;
  }
  return (
    <div className={styles['body']}>
      <h1 className={styles['program-name']}>
        {programData?.name} {showProgramName2}
      </h1>
      <div className={styles['type']}>
        <span className={styles['type-label']}>Type:</span>
        &nbsp;<span>{PROGRAM_TYPE_LABELS_FULL[programData?.type]}</span>
      </div>

      <Tabs className={styles['tabs']}>
        <Tabs.TabList>
          <Tabs.Tab>Snapshot</Tabs.Tab>
          <Tabs.Tab>Performance</Tabs.Tab>
          <Tabs.Tab>Statistics and Risk</Tabs.Tab>
          <Tabs.Tab>Fund Info</Tabs.Tab>
          <Tabs.Tab>Trading</Tabs.Tab>
          {!hideAdminTab && <Tabs.Tab>Admin</Tabs.Tab>}
          <Tabs.Tab>Manager Info</Tabs.Tab>
          <Tabs.Tab>Contacts</Tabs.Tab>
          <Tabs.Tab>Preliminary Rankings</Tabs.Tab>
        </Tabs.TabList>

        <Tabs.TabPanel>
          <SnapshotTab programData={programData} />
        </Tabs.TabPanel>

        <Tabs.TabPanel>
          <PerformanceTab programData={programData} />
        </Tabs.TabPanel>

        <Tabs.TabPanel>
          <StatisticsRiskTab programData={programData} />
        </Tabs.TabPanel>

        <Tabs.TabPanel>
          <FundInfoTab programData={programData} />
        </Tabs.TabPanel>

        <Tabs.TabPanel>
          <TradingTab programData={programData} />
        </Tabs.TabPanel>

        {!hideAdminTab && (
          <Tabs.TabPanel>
            <AdminTab programData={programData} />
          </Tabs.TabPanel>
        )}

        <Tabs.TabPanel>
          <ManagerInfoTab programData={programData} />
        </Tabs.TabPanel>

        <Tabs.TabPanel>
          <ContactsInfoTab programData={programData} />
        </Tabs.TabPanel>

        <Tabs.TabPanel>
          <AwardsTab programData={programData} />
        </Tabs.TabPanel>
      </Tabs>
    </div>
  );
};
