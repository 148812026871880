// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form-fields--Y25ie{display:flex;flex-direction:row;gap:24px}.button--B0y8h{width:100%;margin-top:10px}.button--B0y8h .text--gaWCC{width:100%}.user-status-radio--AUCQi .bsg-Radio__root__radioItem{align-items:start}", "",{"version":3,"sources":["webpack://./src/components/auth/register/register-form/register-form.module.scss"],"names":[],"mappings":"AAAA,oBACE,YAAA,CACA,kBAAA,CACA,QAAA,CAGF,eACE,UAAA,CACA,eAAA,CAEA,4BACE,UAAA,CAMA,sDACE,iBAAA","sourcesContent":[".form-fields {\n  display: flex;\n  flex-direction: row;\n  gap: 24px;\n}\n\n.button {\n  width: 100%;\n  margin-top: 10px;\n\n  .text {\n    width: 100%;\n  }\n}\n\n.user-status-radio {\n  :global {\n    .bsg-Radio__root__radioItem {\n      align-items: start;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form-fields": "form-fields--Y25ie",
	"button": "button--B0y8h",
	"text": "text--gaWCC",
	"user-status-radio": "user-status-radio--AUCQi"
};
export default ___CSS_LOADER_EXPORT___;
