import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from '@common/loader';
import { TabBase } from '../tab-base';
import { RequiresProAccess, hasProAccess } from '../requires-pro-access';
import { ManagerInformation } from './tables/manager-information';
import { Bio } from './tables/bio';
import { useCompanyData } from './hooks';

export const ManagerInfoTab = ({ programData }) => {
  if (!hasProAccess(programData)) return <RequiresProAccess />;

  const {
    data: companyData,
    loading,
    isInitialLoad,
  } = useCompanyData(programData?.compId);

  const isDataLoading = isInitialLoad || loading;

  if (isDataLoading) return <Loader />;

  return (
    <TabBase>
      <ManagerInformation companyData={companyData} />
      <Bio companyData={companyData} />
    </TabBase>
  );
};

ManagerInfoTab.propTypes = {
  programData: PropTypes.object.isRequired,
};
