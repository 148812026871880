import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from '@core/validators';
import { SEARCH_FIELDS, SEARCH_FIELD_GROUPS } from '@core/search-fields';
import { Chip } from './chip/chip';
import styles from './search-query-chips.module.scss';

const ignoreList = [
  SEARCH_FIELDS.all.id,
  SEARCH_FIELDS.basicFiltersInclusion.id,
  SEARCH_FIELDS.detailedFiltersInclusion.id,
  SEARCH_FIELDS.customFiltersInclusion.id,
  SEARCH_FIELDS.ctaInclusion.id,
  SEARCH_FIELDS.hfInclusion.id,
  SEARCH_FIELDS.customFilters.id,
];

export const SearchQueryChips = ({
  params,
  onChipRemove,
  onCustomFilterChipRemove,
  onRemoveAll,
}) => {
  const filterKeys = Object.keys(params).filter(
    (key) => params[key] && !isEmpty(params[key]) && !ignoreList.includes(key)
  );

  const basicFilterCount = filterKeys.filter(
    (key) => SEARCH_FIELDS[key].group === SEARCH_FIELD_GROUPS.BASIC
  ).length;

  const detailedFilterCount = filterKeys.filter(
    (key) => SEARCH_FIELDS[key].group === SEARCH_FIELD_GROUPS.DETAILED
  ).length;

  const inclusionFilters = [];
  if (basicFilterCount >= 2)
    inclusionFilters.push(SEARCH_FIELDS.basicFiltersInclusion.id);

  if (detailedFilterCount >= 2)
    inclusionFilters.push(SEARCH_FIELDS.detailedFiltersInclusion.id);

  if (!isEmpty(params.customFilters) && params.customFilters.length >= 2)
    inclusionFilters.push(SEARCH_FIELDS.customFiltersInclusion.id);

  const totalFilterCount =
    filterKeys.length + (params?.customFilters?.length || 0);

  return (
    <div className={styles['body']}>
      {totalFilterCount > 0 && (
        <Chip
          fieldKey={SEARCH_FIELDS.all.id}
          params={{}}
          onRemove={onRemoveAll}
        />
      )}

      {filterKeys.map((key) => (
        <Chip
          key={key}
          fieldKey={key}
          params={params}
          onRemove={onChipRemove}
        />
      ))}

      {!isEmpty(params.customFilters) &&
        params.customFilters.map((customFilter, index) => (
          <Chip
            key={customFilter.id}
            index={index}
            fieldKey={SEARCH_FIELDS.customFilters.id}
            params={customFilter}
            onRemove={onCustomFilterChipRemove}
          />
        ))}

      {inclusionFilters.map((key) => (
        <Chip
          key={key}
          fieldKey={key}
          params={params}
          onRemove={onChipRemove}
        />
      ))}
    </div>
  );
};

SearchQueryChips.propTypes = {
  params: PropTypes.object.isRequired,
  onChipRemove: PropTypes.func.isRequired,
  onCustomFilterChipRemove: PropTypes.func.isRequired,
  onRemoveAll: PropTypes.func.isRequired,
};
