import React from 'react';
import PropTypes from 'prop-types';
import styles from './single-radio-input.module.scss';

export const SingleRadioButton = ({ name, option, value, onChange }) => {
  return (
    <label className={styles['body']}>
      <input
        type="radio"
        className={styles['input']}
        name={name}
        onChange={() => onChange(option)}
        value={option.value}
        checked={option.value === value?.value}
      />
      {option.label}
    </label>
  );
};

const optionPropTypes = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.array,
});

SingleRadioButton.propTypes = {
  name: PropTypes.string,
  option: optionPropTypes.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};
