import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Button, InlineMessageBlock } from '@backstop/react-core';

import api from '@api';
import { REGEX } from '@core/validators';
import { FormTextInput } from '@common/form-inputs';

import styles from './forgot-password-form.module.scss';

export const ForgotPasswordForm = ({ setEmail }) => {
  const { requestPasswordReset, loading, sentTo, error } =
    api.auth.useRequestPasswordReset();
  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const getLoginHandler = () => {
    const handleLogin = handleSubmit((data) => requestPasswordReset(data));

    return (e) => {
      if (loading) {
        e.preventDefault();
        return;
      } else {
        handleLogin(e);
      }
    };
  };

  useEffect(() => {
    if (sentTo) {
      setEmail(sentTo);
    }
  }, [sentTo]);

  return (
    <form onSubmit={getLoginHandler()}>
      {error && (
        <InlineMessageBlock kind="error">
          {error === 'Error'
            ? 'Something went wrong! Please try again later.'
            : error}
        </InlineMessageBlock>
      )}

      <h1 className={styles['header']}>Forgot password?</h1>
      <p>
        Enter the email address associated with your account and we'll send you
        a link to reset your password.
      </p>

      <FormTextInput
        name="email"
        placeholder="Enter email"
        control={control}
        rules={{
          required: 'Email is required',
          pattern: {
            value: REGEX.email,
            message: 'Please enter a valid email',
          },
        }}
        disabled={loading}
        maxLength={48}
      />

      <Button
        version="v3"
        type={loading ? 'button' : 'submit'}
        kind="primary"
        className={styles['button']}
        spinner={loading}
        disabled={loading}
        style={{ width: '100%' }}
      >
        <span className={styles['text']}>Send reset link</span>
      </Button>
    </form>
  );
};

ForgotPasswordForm.propTypes = {
  setEmail: PropTypes.func.isRequired,
};
