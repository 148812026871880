import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, InlineMessageBlock } from '@backstop/react-core';

import api from '@api';
import { FormTextInput } from '@common/form-inputs';
import { PasswordRequirements } from '@auth/password-requirements';

import styles from './reset-password-form.module.scss';

export const ResetPasswordForm = ({ setSuccess }) => {
  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const passwordValue = watch('password');
  const confirmPassword = watch('confirmPassword');

  const [passwordHasErrors, setPasswordHasErrors] = useState(true);

  const params = useParams();
  const { resetPassword, loading, success, error } =
    api.auth.useResetPassword();

  const getResetPasswordHandler = () => {
    const handleResetPassword = handleSubmit((data) =>
      resetPassword({
        token: params.token,
        newPassword: data.password,
      })
    );

    return (e) => {
      if (passwordHasErrors || loading) {
        e.preventDefault();
        return;
      } else {
        handleResetPassword(e);
      }
    };
  };

  useEffect(() => {
    if (success) {
      setSuccess(success);
    }
  }, [success]);

  return (
    <form onSubmit={getResetPasswordHandler()}>
      {error && (
        <InlineMessageBlock kind="error">
          {error === 'Error' ? 'Something went wrong!' : error}
        </InlineMessageBlock>
      )}

      <span>Password must have</span>
      <PasswordRequirements
        password={passwordValue}
        confirmPassword={confirmPassword}
        setPasswordHasErrors={setPasswordHasErrors}
      />

      <FormTextInput
        name="password"
        label="Password"
        placeholder="Enter Password"
        control={control}
        type="password"
        disabled={loading}
        maxLength={64}
      />

      <FormTextInput
        name="confirmPassword"
        label="Confirm New Password"
        placeholder="Confirm New Password"
        control={control}
        type="password"
        rules={{
          required: 'Confirm Password is required',
        }}
        disabled={loading}
        maxLength={64}
      />

      <Button
        version="v3"
        type={loading || passwordHasErrors ? 'button' : 'submit'}
        kind="primary"
        className={styles['button']}
        spinner={loading}
        disabled={passwordHasErrors || loading}
        style={{ width: '100%' }}
      >
        <span className={styles['text']}>Reset my password</span>
      </Button>
    </form>
  );
};

ResetPasswordForm.propTypes = {
  setSuccess: PropTypes.func.isRequired,
};
