import { useEffect } from 'react';
import api from '@api';

export const useProducts = () => {
  const { getProducts, data, loading, isInitialLoad, error } =
    api.products.useProducts();

  useEffect(() => {
    getProducts();
  }, []);

  return { productsData: data, loading, isInitialLoad, error };
};
