import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LineChart } from '@core/charts/line';
import { useVamiChart } from './hooks';
import { Loader } from '@common/loader';
import { getDateObj } from '@core/date-helper';
import { PROGRAM_TYPES } from '@core/constants';

export const VamiChart = ({ programData }) => {
  const { data, loading } = useVamiChart(programData.id);

  const type = programData.type === PROGRAM_TYPES.HedgeFund ? 'HF' : 'CTA';

  const [sp500Data, setSpData] = useState({});

  const [indexData, setIndexData] = useState({});

  const [fundData, setFundData] = useState({});

  useEffect(() => {
    if (!data) return;
    let tempProgData = [];
    let startDate = getDateObj(data.progData[0].epochTime);
    for (let i = 0; i < data.progData.length; i++) {
      tempProgData.push([
        data.progData[i].epochTime,
        parseFloat(data.progData[i].vami),
      ]);
    }
    setFundData({ data: tempProgData, name: programData.name });

    let tempIndexData = [];
    for (let i = 0; i < data.barclayHedgeIndexData.length; i++) {
      let dateObj = getDateObj(data.barclayHedgeIndexData[i].epochTime);
      if (dateObj >= startDate) {
        tempIndexData.push([
          data.barclayHedgeIndexData[i].epochTime,
          parseFloat(data.barclayHedgeIndexData[i].vami),
        ]);
      }
    }
    setIndexData({ data: tempIndexData, name: `Barclay ${type} Index` });

    let tempSpData = [];
    for (let i = 0; i < data.SP500IndexData.length; i++) {
      let dateObj = getDateObj(data.SP500IndexData[i].epochTime);
      if (dateObj >= startDate) {
        tempSpData.push([
          data.SP500IndexData[i].epochTime,
          parseFloat(data.SP500IndexData[i].vami),
        ]);
      }
    }
    setSpData({ data: tempSpData, name: 'S&P 500' });
  }, [data]);

  if (loading) return <Loader />;

  let chartTitle = '';

  if (programData.browseName) {
    chartTitle = `${programData.browseName} vs Barclay ${type} Index vs S&P 500`;
  } else {
    const name2 = programData.name2 ? ` (${programData.name2})` : '';
    chartTitle = `${programData.name + name2}`;
  }

  return (
    <LineChart
      title={chartTitle}
      dataPoints={[indexData, fundData, sp500Data]}
    />
  );
};

VamiChart.propTypes = {
  programData: PropTypes.object.isRequired,
};
