import { useCallback } from 'react';
import { useBaseGet } from './base';

export const useCompanyData = () => {
  const { baseGet, data, ...rest } = useBaseGet();

  const getCompanyData = useCallback((id) => {
    if (!id) return;
    baseGet(`/company/${id}`);
  }, []);

  return {
    getCompanyData,
    data: data?.data,
    ...rest,
  };
};

export const useCompanyContacts = () => {
  const { baseGet, data, ...rest } = useBaseGet();

  const getCompanyContactsData = useCallback((id) => {
    if (!id) return;
    baseGet(`/company/${id}/contacts`);
  }, []);

  return {
    getCompanyContactsData,
    data: data?.data,
    ...rest,
  };
};
