import React from 'react';
import PropTypes from 'prop-types';
import { PROGRAM_TYPES } from '@core/constants';
import {
  BasicNumberFormatterNegativeHandler,
  BooleanValueFormatter,
  DaysFormatter,
  DecimalFormatterNegativeHandler,
  DecimalPercentageFormatter,
  DecimalPercentageFormatterNegativeHandler,
  MoneyThousandsFormatter,
  PayoutFormatter,
} from '@core/formatters';
import { BasicTable } from '@common/basic-table';

export const AccountInformation = ({ programData }) => {
  return (
    <BasicTable widths={['25%', '25%', '25%', '25%']}>
      <BasicTable.Title colSpan="4">Account Information</BasicTable.Title>

      <BasicTable.Row>
        <BasicTable.Header>Management Fee</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>
          <DecimalPercentageFormatter value={programData.managementFee} />
        </BasicTable.Cell>
        <BasicTable.Header>Leverage</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>
          <DecimalFormatterNegativeHandler value={programData.leverage} />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Incentive Fee</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>
          <DecimalPercentageFormatter value={programData.incentiveFee} />
        </BasicTable.Cell>
        <BasicTable.Header>Accepting New Investors</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>{programData.open}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Minimum Account</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>
          <MoneyThousandsFormatter
            value={programData.minAccount}
            row={programData}
          />
        </BasicTable.Cell>
        <BasicTable.Header>High Water Mark</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>
          <DecimalFormatterNegativeHandler value={programData.highwater} />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Other Fees</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>
          {programData.otherFees}
        </BasicTable.Cell>
        <BasicTable.Header>Redemptions Fees</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>
          {programData.redemptionFees}
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Is K1 Issued</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>
          <BooleanValueFormatter value={programData.k1} />
        </BasicTable.Cell>
        <BasicTable.Header>K1 Month</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>{programData.k1Month}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Subscriptions</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>
          {programData.subscriptions}
        </BasicTable.Cell>
        <BasicTable.Header>Hurdle Rate</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>{programData.hurdle}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Redemptions</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>
          {programData.redemptions}
        </BasicTable.Cell>
        <BasicTable.Header>Lockup</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>
          <DaysFormatter value={programData.lockup} />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Payout</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>
          <PayoutFormatter row={programData} />
        </BasicTable.Cell>
        <BasicTable.Header>Advanced Notice</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>
          <DaysFormatter value={programData.advance} />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Return Type</BasicTable.Header>
        <BasicTable.Cell hasBorderRight>
          {programData.returnType}
        </BasicTable.Cell>
        {programData.type === 'CTA' ? (
          <>
            <BasicTable.Header>Rt/yr/$Million</BasicTable.Header>
            <BasicTable.Cell hasBorderRight>
              <BasicNumberFormatterNegativeHandler
                value={programData.rtYrMil}
              />
            </BasicTable.Cell>
          </>
        ) : null}
      </BasicTable.Row>

      {programData.type === PROGRAM_TYPES.CTA && (
        <>
          <BasicTable.Row>
            <BasicTable.Header>ME Ratio</BasicTable.Header>
            <BasicTable.Cell hasBorderRight>
              <DecimalPercentageFormatterNegativeHandler
                value={programData.meRatio}
              />
            </BasicTable.Cell>
            <BasicTable.Header>Options</BasicTable.Header>
            <BasicTable.Cell hasBorderRight>
              <DecimalPercentageFormatterNegativeHandler
                value={programData.options}
              />
            </BasicTable.Cell>
          </BasicTable.Row>

          <BasicTable.Row>
            <BasicTable.Header>Interbank</BasicTable.Header>
            <BasicTable.Cell hasBorderRight>
              <DecimalPercentageFormatterNegativeHandler
                value={programData.interbank}
              />
            </BasicTable.Cell>
            <BasicTable.Header>Discretion</BasicTable.Header>
            <BasicTable.Cell hasBorderRight>
              <DecimalPercentageFormatterNegativeHandler
                value={programData.discretion}
              />
            </BasicTable.Cell>
          </BasicTable.Row>
        </>
      )}
    </BasicTable>
  );
};

AccountInformation.propTypes = {
  programData: PropTypes.object.isRequired,
};
