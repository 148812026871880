import { useEffect } from 'react';
import api from '@api';

export const useProgramData = (id) => {
  const { getProgramData, data, loading, isInitialLoad, error } =
    api.program.useProgramData();

  useEffect(() => {
    getProgramData(id);
  }, [id]);

  return { data, loading, isInitialLoad, error };
};
