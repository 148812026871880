import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
  TableHeaderRow,
  TableColumnVisibility,
  TableColumnReordering,
  DragDropProvider,
  Table as BsgTable,
  DataTypeProvider,
} from '@backstop/grid-view';
import { GridLoader, Tags } from '@backstop/react-core';

import { SORT_DIRECTIONS } from '@core/constants';

import Delete from '@common/modals/Delete';
import { SaveSearchButton } from '@common/table/save-search/save-search-button';

import { COLUMNS } from '~/components/search-results/columns';
import { DATA_FORMATTERS } from '~/components/search-results/data-formatters';

import { Paginator } from './paginator';
import { CustomTableHeader } from './custom-table-header';
import { ColumnSelector } from './column-selector';
import { Toolbar } from './toolbar';
import { useHiddenColumns } from './hooks';

import styles from './table.module.scss';
import { useSelector } from 'react-redux';
import { getHidden } from './column-selector/helpers';

export const Table = (props) => {
  const {
    rows,
    formParams,
    cellComponent: Cell,
    totalElements,
    isLoading,
    allowSortingFor,
    sortedCols,
    setColumnSorting,
    showPagination,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    deleteFunction,
    showColumnSelector,
    columnOrder,
    setColumnOrder,
  } = props;

  const activeColumns = useSelector((state) => state.params.activeCols);

  useEffect(() => {
    if (activeColumns) {
      let temp = [];
      for (const [key, value] of Object.entries(activeColumns)) {
        temp.push({ value: value });
      }
      setHiddenColumns(getHidden(temp));
    }
  }, [activeColumns]);

  const cols = Object.keys(COLUMNS).map((col) => ({
    name: COLUMNS[col].value,
    label: COLUMNS[col].columnLabel,
  }));

  const { hiddenColumns, setHiddenColumns } = useHiddenColumns();

  function hasSorting() {
    return setColumnSorting !== undefined;
  }

  function doesDelete() {
    return deleteFunction !== undefined;
  }

  return (
    <div className={styles['body']}>
      <div className={styles['toolbar-wrapper']}>
        <Toolbar totalElements={totalElements}>
          {showColumnSelector && (
            <ColumnSelector
              hiddenColumnNames={hiddenColumns}
              onHiddenColumnNamesChange={setHiddenColumns}
            />
          )}
          <SaveSearchButton
            formParams={formParams}
            allCols={cols}
            hiddenCols={hiddenColumns}
          />
        </Toolbar>
      </div>
      <div>
        {isLoading ? (
          <GridLoader show={true} />
        ) : (
          <Grid rows={rows} columns={cols} gutterWidth={200}>
            {Cell ? (
              <BsgTable cellComponent={(props) => <Cell {...props} />} />
            ) : (
              <BsgTable
                cellComponent={(props) => <BsgTable.Cell {...props} />}
              />
            )}

            <TableColumnVisibility
              defaultHiddenColumnNames={hiddenColumns}
              hiddenColumnNames={hiddenColumns}
            />

            <DragDropProvider
              columnComponent={({ column }) => (
                <Tags.Tag color={'light-blue'} label={column.label} />
              )}
            />

            <TableColumnReordering
              defaultOrder={Object.keys(COLUMNS)}
              order={columnOrder}
              onOrderChange={setColumnOrder}
            />

            {DATA_FORMATTERS &&
              DATA_FORMATTERS.map((formatter) => (
                <DataTypeProvider key={formatter.id} {...formatter} />
              ))}

            {hasSorting() ? (
              <TableHeaderRow
                contentComponent={(props) => (
                  <CustomTableHeader
                    sortedCols={sortedCols}
                    setColumnSorting={setColumnSorting}
                    allowSortingFor={allowSortingFor}
                    {...props}
                  />
                )}
              />
            ) : (
              <TableHeaderRow />
            )}
            {doesDelete() ? <Delete {...props} /> : null}
          </Grid>
        )}
      </div>

      {showPagination && (
        <Paginator
          pageSize={pageSize}
          setPageSize={setPageSize}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalElements={totalElements}
          isTableLoading={isLoading}
        />
      )}
    </div>
  );
};

Table.defaultProps = {
  rows: [],
  cols: [],
  visibleByDefault: [],
  alwaysVisible: [],
};

Table.propTypes = {
  rows: PropTypes.array,
  cols: PropTypes.array,
  totalElements: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,

  allowSortingFor: PropTypes.arrayOf(PropTypes.string),
  sortedCols: PropTypes.arrayOf(
    PropTypes.shape({
      columnName: PropTypes.string.isRequired,
      direction: PropTypes.oneOf([SORT_DIRECTIONS.ASC, SORT_DIRECTIONS.DESC])
        .isRequired,
    })
  ),
  setColumnSorting: PropTypes.func,
  showPagination: PropTypes.bool,
  pageSize: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
  setPageSize: PropTypes.func,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,

  deleteFunction: PropTypes.func,
  elementToDelete: PropTypes.object,
  isOpen: PropTypes.bool,
  toggleDelete: PropTypes.func,
  deleteHeader: PropTypes.string,
  deleteMessage: PropTypes.string,

  showColumnSelector: PropTypes.bool,
  columnOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  setColumnOrder: PropTypes.func.isRequired,
};
