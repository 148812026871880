import React from 'react';
import PropTypes from 'prop-types';
import { DecimalFormatterNegativeHandler } from '@core/formatters';
import { BasicTable } from '@common/basic-table';

export const CorrelationAnalysis = ({ programData }) => {
  return (
    <BasicTable>
      <BasicTable.Title colSpan="2">Correlation analysis</BasicTable.Title>

      <BasicTable.Row>
        <BasicTable.Header>vs. Barclay CTA Index</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalFormatterNegativeHandler value={programData.corrVsCta} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>vs. Barclay HF Index</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalFormatterNegativeHandler value={programData.corrVsHf} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>vs. Barclay FoF Index</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalFormatterNegativeHandler value={programData.corrVsFof} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>vs. S&P500</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalFormatterNegativeHandler value={programData.corrVsSp500} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>vs. NASDAQ</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalFormatterNegativeHandler value={programData.corrVsNasdaq} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>vs. Dow Jones Industrial</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalFormatterNegativeHandler value={programData.corrVsDow} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>vs. Russell 2000</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalFormatterNegativeHandler value={programData.corrVsRussel} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>vs. FTSE 100</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalFormatterNegativeHandler value={programData.corrVsFtSe} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>vs. JP Morgan</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalFormatterNegativeHandler value={programData.corrVsJpMorgan} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>vs. MSCI World</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalFormatterNegativeHandler
            value={programData.corrVsMsciWorld}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};

CorrelationAnalysis.propTypes = {
  programData: PropTypes.object.isRequired,
};
