import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, prefixIt } from '@backstop/react-core';
import { CheckBoxParent } from './checkbox-parent';
import { MultiselectControls } from './multiselect-controls';
import { SelectedCountContext } from './selected-count-context';
import {
  applyChildChange,
  applyParentUpdate,
  checkAllDescendants,
  getCheckedChildren,
  isItemChecked,
  isParentItem,
  getFlatList,
} from './helpers';
import styles from './checkbox-list.module.scss';
import classnames from 'classnames';

export const CheckBoxList = ({
  items,
  selectedItems,
  onChange,
  showMultiselectControls = false,
  maxOptionsSelected,
}) => {
  const [selectedCount, setSelectedCount] = useState(0);

  const [limitReached, setLimitReached] = useState(
    selectedItems?.length >= maxOptionsSelected
  );

  const totalOptionsCount = useMemo(() => getFlatList(items).length, [items]);

  useEffect(() => {
    const count = getFlatList(selectedItems).length;
    setSelectedCount(count);
    setLimitReached(count >= maxOptionsSelected);
  }, [selectedItems]);

  const selectAll = () => {
    const selected = checkAllDescendants({
      children: items,
    });
    onChange(selected);
  };

  const clearAll = () => {
    onChange([]);
  };

  const handleParentChange = (id, checkedChildren) => {
    const parentItem = items.find((item) => item.id === id);
    const newSelectedItems = applyParentUpdate({
      parentId: id,
      checkedChildren,
      selectedItems,
      parentItem,
    });

    onChange(newSelectedItems);
  };

  const handleChildChange = (e, item) => {
    const isChecked = e.target.checked;
    const newSelectedItems = applyChildChange({
      id: item.id,
      isChecked,
      selectedItems,
      items,
    });

    onChange(newSelectedItems);
  };

  const isSingleLevel = !items.some(
    (item) => item.children && item.children.length > 0
  );
  const itemClasses = classnames(styles['item'], {
    [styles['item--single-level']]: isSingleLevel,
  });

  const contextValue = useMemo(() => {
    return {
      selectedCount,
      limitReached,
      maxOptionsSelected,
    };
  }, [maxOptionsSelected, selectedCount, limitReached]);

  return (
    <SelectedCountContext.Provider value={contextValue}>
      <div className={styles['body']}>
        {showMultiselectControls && (
          <MultiselectControls
            selectAll={selectAll}
            clearAll={clearAll}
            selectedCount={selectedCount}
            totalOptionsCount={totalOptionsCount}
            maxOptionsSelected={maxOptionsSelected}
          />
        )}

        {items.map((item) => (
          <div key={item.id} className={prefixIt('flex flex-column')}>
            {isParentItem(item) ? (
              <CheckBoxParent
                item={item}
                selectedItemsScoped={getCheckedChildren(selectedItems, item.id)}
                onChange={handleParentChange}
                limitReached={limitReached}
              />
            ) : (
              <Checkbox
                label={item.optionLabel}
                version="v3"
                checked={isItemChecked(selectedItems, item.id)}
                onChange={(e) => handleChildChange(e, item)}
                className={itemClasses}
                disabled={
                  limitReached && !isItemChecked(selectedItems, item.id)
                }
              />
            )}
          </div>
        ))}
      </div>
    </SelectedCountContext.Provider>
  );
};

const itemsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    optionLabel: PropTypes.string.isRequired,
    children: PropTypes.array,
  })
);

const selectedItemsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    children: PropTypes.array,
  })
);

CheckBoxList.propTypes = {
  items: itemsPropTypes,
  selectedItems: selectedItemsPropTypes,
  onChange: PropTypes.func,
  showMultiselectControls: PropTypes.bool,
  maxOptionsSelected: PropTypes.number,
};
