import React from 'react';
import { ProgressSpinner } from '@backstop/react-core';
import { useSearchParams } from '@redux/search-params';
import { useSearchData } from '~/api/search';
import { WINDOW_SIZES } from '@core/constants';
import { useLayoutData } from '@core/layout';
import classNames from 'classnames';
import styles from './programs-count.module.scss';

export const ProgramsCount = () => {
  const { formParams } = useSearchParams();
  const { windowSize } = useLayoutData();

  const { totalElements, loading, error } = useSearchData({
    formParams,
    pageSize: 1,
    currentPage: 1,
  });

  const textClasses = classNames([styles['text']], {
    [styles['text--is-collapsed']]: windowSize < WINDOW_SIZES.SM,
  });

  const countClasses = classNames([styles['count']], {
    [styles['count--is-collapsed']]: windowSize < WINDOW_SIZES.SM,
  });

  return (
    <div className={styles['body']}>
      <div className={styles['program-count']}>
        <span className={textClasses}>Programs matching search criteria:</span>
        {loading ? (
          <ProgressSpinner className={styles['spinner']} size="xs" />
        ) : error ? (
          <span className={styles['error']}>
            Something went wrong! Please try to remove the last filter.
          </span>
        ) : (
          <strong className={countClasses}>{totalElements}</strong>
        )}
      </div>
    </div>
  );
};
