import PropTypes from 'prop-types';
import { dataUnavailable, toMillions, toNumber } from '@core/formatters';
import { isEmpty, isNumeric } from '@core/validators';

export const AwardsFumFormatter = ({ value }) => {
  if (isEmpty(value) || !isNumeric(value)) return dataUnavailable(value);

  let valueInMillions = parseFloat(value) / 1000;

  if (valueInMillions < 1) {
    valueInMillions = '<1';
  } else {
    valueInMillions = toNumber(valueInMillions, 0);
  }

  return dataUnavailable(`$${toMillions(valueInMillions)}`);
};

AwardsFumFormatter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
