import React from 'react';
import PropTypes from 'prop-types';
import {
  DecimalFormatterNegativeHandler,
  DecimalPercentageFormatter,
  DecimalPercentageFormatterNegativeHandler,
} from '@core/formatters';
import { BasicTable } from '@common/basic-table';

export const PerformanceAnalysis = ({ programData }) => {
  return (
    <BasicTable>
      <BasicTable.Title colSpan="2">Performance analysis</BasicTable.Title>

      <BasicTable.Row>
        <BasicTable.Header>Total Return</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatter value={programData.totalReturn} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Compound Annual Return</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.compoundAnnualReturn}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Worst Drawdown</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatter value={programData.worstDrawDown} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Monthly Standard Deviation</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatter value={programData.monthlyStandardDev} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Sharpe Ratio</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalFormatterNegativeHandler value={programData.sharpeRatio} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Alpha vs. S&P 500</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalFormatterNegativeHandler value={programData.alphaVsSp500} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Beta vs. S&P 500</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalFormatterNegativeHandler value={programData.betaVsSp500} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>R2 vs. S&P 500</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalFormatterNegativeHandler value={programData.rSqVsSp500} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Up Capture vs. S&P 500</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalFormatterNegativeHandler value={programData.upCapVsSp500} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Down Capture vs. S&P 500</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalFormatterNegativeHandler value={programData.downCapVsSp500} />
        </BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};

PerformanceAnalysis.propTypes = {
  programData: PropTypes.object.isRequired,
};
