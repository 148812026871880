import React from 'react';
import { BasicTable } from '@common/basic-table';
import { Loader } from '@common/loader';
import { useUserData } from '@core/user';
import { dataUnavailable } from '@core/formatters';
import { useProducts } from './hooks';
import { DownloadButton } from './download-button';
import styles from './subscriber.module.scss';

export const Subscriber = () => {
  const { productsData, loadingProducts, isInitialLoadProducts } =
    useProducts();

  const {
    subscriberFirstName,
    subscriberLastName,
    subscriberCompany,
    subscriberEmail,
    subscriberPhone,
    loading: dataLoading,
  } = useUserData();

  const isProductsLoaded = loadingProducts || isInitialLoadProducts;

  if (dataLoading || isProductsLoaded) return <Loader />;

  return (
    <div className={styles['body']}>
      <BasicTable>
        <BasicTable.Title colSpan="2">Subscriber</BasicTable.Title>
        <BasicTable.Row>
          <BasicTable.Header>Name</BasicTable.Header>
          <BasicTable.Cell>
            {subscriberFirstName} {subscriberLastName}
          </BasicTable.Cell>
        </BasicTable.Row>
        <BasicTable.Row>
          <BasicTable.Header>Company</BasicTable.Header>
          <BasicTable.Cell>{subscriberCompany}</BasicTable.Cell>
        </BasicTable.Row>
        <BasicTable.Row>
          <BasicTable.Header>Phone</BasicTable.Header>
          <BasicTable.Cell>{subscriberPhone}</BasicTable.Cell>
        </BasicTable.Row>
        <BasicTable.Row>
          <BasicTable.Header>Email</BasicTable.Header>
          <BasicTable.Cell>{subscriberEmail}</BasicTable.Cell>
        </BasicTable.Row>
      </BasicTable>

      <BasicTable>
        <BasicTable.Title colSpan="4">Products</BasicTable.Title>
        <BasicTable.Row>
          <BasicTable.Header hasBorderRight>Product</BasicTable.Header>
          <BasicTable.Header hasBorderRight>File</BasicTable.Header>
          <BasicTable.Header hasBorderRight>Release Date</BasicTable.Header>
          <BasicTable.Header hasBorderRight>Notes</BasicTable.Header>
        </BasicTable.Row>
        {productsData?.map((product) => (
          <BasicTable.Row key={product.id}>
            <BasicTable.Cell hasBorderRight alignLeft>
              {product.productCode}
            </BasicTable.Cell>
            <BasicTable.Cell hasBorderRight alignLeft>
              <DownloadButton product={product} />
            </BasicTable.Cell>
            <BasicTable.Cell hasBorderRight alignLeft>
              {product.dateSent}
            </BasicTable.Cell>
            <BasicTable.Cell hasBorderRight alignLeft>
              {dataUnavailable(product.adminNotes)}
            </BasicTable.Cell>
          </BasicTable.Row>
        ))}
      </BasicTable>
    </div>
  );
};
