import React from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from '@core/validators';
import { PROGRAM_TYPES } from '@core/constants';

import { Loader } from '@common/loader';

import { TabBase } from '../tab-base';
import { RequiresProAccess, hasProAccess } from '../requires-pro-access';
import { AccountInformation } from './tables/account-information';
import { GeographicAllocation } from './tables/geographic-allocation';
import { PortfolioComposition } from './tables/portfolio-composition';
import { PortfolioTimeSeries } from './tables/portfolio-time-series';
import { PortfolioTimeFrames } from './tables/portfolio-time-frames';
import { Benchmarks } from './tables/benchmarks';
import { Instruments } from './tables/instruments';
import { Holdings } from './tables/holdings';
import { CompositionAllocationPieChart } from './charts';

import { useTabData } from './hooks';

import styles from './trading-tab.module.scss';
import { useLayoutData } from '@core/layout';
import { WINDOW_SIZES } from '@core/constants';
import classnames from 'classnames';

export const TradingTab = ({ programData }) => {
  const { windowSize } = useLayoutData();

  const rowStyles = classnames(styles['row'], {
    [styles['row--collapsed']]: windowSize < WINDOW_SIZES.MD,
  });

  if (!hasProAccess(programData)) return <RequiresProAccess />;

  const { instruments, holdings, isDataLoading } = useTabData(programData);
  if (isDataLoading) return <Loader />;

  const isHedgeFund = programData.type === PROGRAM_TYPES.HedgeFund;
  const isCTA = programData.type === PROGRAM_TYPES.CTA;
  const isHfFundOfFunds = isHedgeFund && programData.fundFund === 1;
  const isCtaMultiManagerFund = isCTA && programData.multiadvisor === 1;

  const showHoldings =
    !isEmpty(holdings) && (isHfFundOfFunds || isCtaMultiManagerFund);

  const showInstruments = !isEmpty(instruments);

  return (
    <TabBase>
      {/* <CompositionAllocationPieChart programData={programData} /> */}
      <AccountInformation programData={programData} />
      <div className={rowStyles}>
        <div className={styles['col']}>
          {isHedgeFund && <GeographicAllocation programData={programData} />}
          {isCTA && <PortfolioComposition programData={programData} />}
        </div>
        <div className={styles['col']}>
          {/* //if we have instruments, show them and don't render the rest */}
          {showInstruments && <Instruments instruments={instruments} />}
          {/* //if we don't have instruments, render these instead */}
          {!showInstruments && isHedgeFund && (
            <PortfolioTimeSeries programData={programData} />
          )}
          {!showInstruments && isCTA && (
            <PortfolioTimeFrames programData={programData} />
          )}
          {!showInstruments && <Benchmarks programData={programData} />}
        </div>
      </div>
      {/* //if we do have instruments, render this as normal */}
      {showInstruments && (
        <div className={rowStyles}>
          <div className={styles['col']}>
            {isHedgeFund && <PortfolioTimeSeries programData={programData} />}
            {isCTA && <PortfolioTimeFrames programData={programData} />}
          </div>
          <div className={styles['col']}>
            <Benchmarks programData={programData} />
          </div>
        </div>
      )}
      {showHoldings && <Holdings holdings={holdings} />}
    </TabBase>
  );
};

TradingTab.propTypes = {
  programData: PropTypes.object.isRequired,
};
