import { useEffect, useState } from 'react';

import api from '@api';
import { useAwardSelectOptions } from './select-options';

export const useAwardSectorOptions = (getRanks, setRanks) => {
  const { getAwardSectorOptions, sectors, loading } =
    api.select.useAwardSectorOptions();

  const { getDefaultYearValue } = useAwardSelectOptions();

  const [submitError, setSubmitError] = useState(false);
  const [entityVal, setEntityVal] = useState();
  const [yearVal, setYearVal] = useState(getDefaultYearValue());
  const [monthVal, setMonthVal] = useState();
  //setting this makes the select input fully 'controlled'
  const [sectorVal, setSectorVal] = useState(undefined);

  useEffect(() => {
    setRanks([]);
    setSectorVal({});
    setSubmitError(false);
    let month = '12';
    if (monthVal?.value !== 'NA') {
      month = String(monthVal?.value).padStart(2, '0');
    }
    if (month == 'undefined') {
      return;
    }
    getAwardSectorOptions(
      monthVal?.value === 'NA' ? 1 : 0,
      entityVal?.value === 'bmfr' ? 1 : 0,
      entityVal?.value,
      `${yearVal?.value}-${month}-01`
    );
  }, [monthVal, entityVal, yearVal]);

  const submitSearch = () => {
    if (sectorVal === undefined || entityVal === undefined) {
      setSubmitError(true);
      return;
    }
    getRanks(sectorVal?.value, yearVal?.value, monthVal?.value);
  };

  return {
    //clear out sectors if the other 3 values aren't set. this prevents users from seeing old sector options when they are changing form values
    sectors: monthVal && yearVal && entityVal ? sectors : [],
    entityVal,
    yearVal,
    monthVal,
    sectorVal,
    setEntityVal,
    setYearVal,
    setMonthVal,
    setSectorVal,
    submitSearch,
    loading,
    submitError,
  };
};

export const searchRanks = () => {
  const { getRanks, data, loading } = api.awardsRanks.useAwardsAndRanks();

  return { ranks: data, getRanks, loading };
};
