// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".icon--DgQyM{margin-top:10px;margin-right:8px}.body--SxVzT{display:flex;flex-direction:column;align-items:center}", "",{"version":3,"sources":["webpack://./src/components/awardsDownloads/download-button.module.scss"],"names":[],"mappings":"AAAA,aACI,eAAA,CACA,gBAAA,CAGJ,aACI,YAAA,CACA,qBAAA,CACA,kBAAA","sourcesContent":[".icon {\n    margin-top: 10px;\n    margin-right: 8px;\n}\n\n.body{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "icon--DgQyM",
	"body": "body--SxVzT"
};
export default ___CSS_LOADER_EXPORT___;
