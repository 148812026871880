import initialState from './initial-state';
import * as types from '../actions/action-types';

export default function cacheReducer(state = initialState.cache, action) {
  switch (action.type) {
    case types.CACHE_SET:
      return {
        ...state,
        [action.cacheKey]: action.data,
      };

    case types.CACHE_CLEAN:
      return {
        ...state,
        [action.cacheKey]: undefined,
      };

    default:
      return state;
  }
}
