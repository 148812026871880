// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".body--nTsj0{margin-left:-16px;margin-right:-16px;overflow-x:auto}.body--nTsj0 .toolbar-wrapper--v7Vrx{margin-left:16px;margin-right:16px}.body--nTsj0 thead th{border-bottom:1px solid #111;border-top:1px solid #edf1f2}", "",{"version":3,"sources":["webpack://./src/components/common/table/table.module.scss"],"names":[],"mappings":"AAEA,aACE,iBAAA,CACA,kBAAA,CACA,eAAA,CAEA,qCACE,gBAAA,CACA,iBAAA,CAGF,sBACE,4BAAA,CACA,4BAAA","sourcesContent":["@import './src/theme/_variables';\n\n.body {\n  margin-left: -16px;\n  margin-right: -16px;\n  overflow-x: auto;\n\n  .toolbar-wrapper {\n    margin-left: 16px;\n    margin-right: 16px;\n  }\n\n  thead th {\n    border-bottom: 1px solid $color-table-border-bottom;\n    border-top: 1px solid $color-table-border-top;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "body--nTsj0",
	"toolbar-wrapper": "toolbar-wrapper--v7Vrx"
};
export default ___CSS_LOADER_EXPORT___;
