import React from 'react';
import { ErrorPageBase } from './error-page-base';

export const FundNotActive = () => {
  return (
    <ErrorPageBase
      title={'Sorry. This fund is no longer active'}
      message={'The fund you are trying to access is no longer active'}
    />
  );
};
