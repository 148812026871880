import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { FormControl } from '@common/form-control';
import {
  getFlatList,
  getSelectedItems,
  MultiselectDropdown,
} from '@common/multiselect-dropdown';
import { FilterDropdownContainer } from './filter-dropdown-container';
import {
  DropdownMultiselectFilter,
  getDisplayName,
} from './dropdown-filters/dropdown-multiselect-filter';

export const FormMultiselect = ({
  name,
  label,
  tooltipText,
  control,
  defaultValue,
  rules,
  options,
  placeholder = 'None selected',
  showConfirmationModal,
  formControlClassName,
  ...restProps
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={true}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        const selected = useMemo(() => {
          return getSelectedItems(value, options);
        }, [value, options]);

        const handleChange = useCallback((newSelected) => {
          const newValue = getFlatList(newSelected).map((item) => item.id);
          onChange(newValue);
        }, []);

        const errorList = error ? [error.message] : null;
        return (
          <FormControl
            label={label}
            errors={errorList}
            tooltipText={tooltipText}
            className={formControlClassName}
          >
            {showConfirmationModal ? (
              <FilterDropdownContainer
                name={name}
                label={label}
                options={options}
                placeholder={placeholder}
                value={selected}
                onChange={handleChange}
                onBlur={onBlur}
                getDisplayName={getDisplayName}
                hasError={!!error}
              >
                <DropdownMultiselectFilter
                  options={options}
                  showMultiselectControls={true}
                  {...restProps}
                />
              </FilterDropdownContainer>
            ) : (
              <MultiselectDropdown
                name={name}
                selectedItems={selected}
                onChange={handleChange}
                onBlur={onBlur}
                options={options}
                showMultiselectControls={true}
                {...restProps}
              />
            )}
          </FormControl>
        );
      }}
    />
  );
};

FormMultiselect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  tooltipText: PropTypes.string,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.any,
  rules: PropTypes.object,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  showConfirmationModal: PropTypes.bool,
  formControlClassName: PropTypes.string,
};
