import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { USER_ROLES } from './index';

export const useUserData = () => {
  const userDataState = useSelector((state) => state.userData);
  const hasRole = useCallback(
    (role) => userDataState.roles.some((userRole) => userRole === role),
    [userDataState]
  );
  const userData = useMemo(
    () => ({
      ...userDataState,
      isSubscriber: hasRole(USER_ROLES.subscriptionUser),
      isFreeUser: hasRole(USER_ROLES.freeUser),
      hasRole,
    }),
    [userDataState]
  );

  return userData;
};
