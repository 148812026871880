import { useCallback, useEffect } from 'react';
import { useBaseDelete, useBaseGet, useBasePost } from './base';
import {
  userFavoriteFundsSet,
  userFavoriteFundsDelete,
  userFavoriteFundsAdd,
} from '@redux/actions/user-data-actions';
import { useDispatch } from 'react-redux';

export const useSaveFund = () => {
  const { baseGet, data, loading, ...rest } = useBaseGet();

  const dispatch = useDispatch();

  const getSavedFunds = useCallback(() => {
    baseGet(`/favorite/fund`);
  }, []);

  useEffect(() => {
    if (data?.data.length > 0) {
      dispatch(userFavoriteFundsSet(data?.data));
    }
  }, [data]);

  const { basePost, data: postData } = useBasePost();

  const saveFund = (params) => {
    dispatch(userFavoriteFundsAdd(params));
    basePost(`/favorite/fund`, { data: { fundId: params.id } });
  };

  const { baseDelete, data: deleteData } = useBaseDelete();

  const deleteSavedFund = (itemId) => {
    dispatch(userFavoriteFundsDelete(itemId));
    baseDelete(`/favorite/fund/${itemId}`);
  };

  return {
    getSavedFunds,
    saveFund,
    deleteSavedFund,
    data: data?.data,
    loading,
    ...rest,
  };
};
