import React from 'react';
import PropTypes from 'prop-types';
import { BasicTable } from '@common/basic-table';

export const AdministratorInformation = ({ programData }) => {
  return (
    <BasicTable>
      <BasicTable.Title colSpan="2">Administrator Information</BasicTable.Title>

      <BasicTable.Row>
        <BasicTable.Header>Administrator Firm</BasicTable.Header>
        <BasicTable.Cell>{programData.adminFirm}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Administrator Contact</BasicTable.Header>
        <BasicTable.Cell>{programData.adminContact}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Administrator Phone</BasicTable.Header>
        <BasicTable.Cell>{programData.adminContactPhone}</BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};

AdministratorInformation.propTypes = {
  programData: PropTypes.object.isRequired,
};
