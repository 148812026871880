// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".row--F6B5d{width:100%;display:flex;gap:12px}.row--collapsed--RcTSX{display:flex;flex-direction:column;width:100%;gap:0;min-width:0}.col--GHF4Z{display:flex;flex-direction:column;width:100%;gap:0;min-width:0}", "",{"version":3,"sources":["webpack://./src/components/main-search/detailed-filters/detailed-filters.module.scss","webpack://./src/theme/_mixins.scss"],"names":[],"mappings":"AAEA,YCoCE,UAAA,CACA,YAAA,CACA,QAHe,CDhCf,uBCuCA,YAAA,CACA,qBAAA,CACA,UAAA,CACA,KAJe,CAKf,WAAA,CDtCF,YCkCE,YAAA,CACA,qBAAA,CACA,UAAA,CACA,KAJe,CAKf,WAAA","sourcesContent":["@import './src/theme/_mixins';\n\n.row {\n  @include row;\n\n  &--collapsed {\n    @include col;\n  }\n}\n\n.col {\n  @include col;\n}\n","@mixin input-label {\n  :global {\n    .bsg-FormControl__labelWrapper {\n      label {\n        margin-bottom: 4px;\n        margin-top: 2px;\n      }\n    }\n  }\n}\n\n@mixin center-children-flex {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n@mixin border-radius-top($value) {\n  border-top-left-radius: $value;\n  border-top-right-radius: $value;\n}\n\n@mixin border-radius-bottom($value) {\n  border-bottom-left-radius: $value;\n  border-bottom-right-radius: $value;\n}\n\n@mixin border-radius-left($value) {\n  border-top-left-radius: $value;\n  border-bottom-left-radius: $value;\n}\n\n@mixin border-radius-right($value) {\n  border-top-right-radius: $value;\n  border-bottom-right-radius: $value;\n}\n\n@mixin row($gap: 12px) {\n  width: 100%;\n  display: flex;\n  gap: $gap;\n}\n\n@mixin col($gap: 0) {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  gap: $gap;\n  min-width: 0;\n}\n\n@mixin ellipsis($max-width) {\n  display: inline-block;\n  max-width: $max-width;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "row--F6B5d",
	"row--collapsed": "row--collapsed--RcTSX",
	"col": "col--GHF4Z"
};
export default ___CSS_LOADER_EXPORT___;
