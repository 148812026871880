import React from 'react';
import { useSaveFund } from '~/api/saveFund';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarReg } from '@fortawesome/free-regular-svg-icons';
import { useSelector } from 'react-redux';

export const SaveFundButtonFormatter = ({ row }) => {
  const { saveFund, deleteSavedFund } = useSaveFund();
  const userDataState = useSelector((state) => state.userData.savedFunds);

  const calculateIsSaved = (savedFundsList = []) => {
    return savedFundsList?.filter((s) => s.fundId == row.id)?.length > 0;
  };

  return calculateIsSaved(userDataState) ? (
    <FontAwesomeIcon
      style={{ cursor: 'pointer' }}
      icon={'fa-star'}
      onClick={() => {
        deleteSavedFund(row.id);
      }}
    />
  ) : (
    <FontAwesomeIcon
      style={{ cursor: 'pointer' }}
      icon={faStarReg}
      onClick={() => {
        saveFund(row);
      }}
    />
  );
};
