import React from 'react';
import PropTypes from 'prop-types';
import { BasicTable } from '@common/basic-table';

export const TradingMethodStrategy = ({ programData }) => {
  return (
    <BasicTable>
      <BasicTable.Title>Trading Method Strategy</BasicTable.Title>

      <BasicTable.Row>
        <BasicTable.Cell alignLeft>{programData.tradingMethod}</BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};

TradingMethodStrategy.propTypes = {
  programData: PropTypes.object.isRequired,
};
