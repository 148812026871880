import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { DropdownMenu, Icon } from '@backstop/react-core';

import { SingleRadioButton } from './single-radio-input';
import { RadioGroup } from './radio-group';
import { isParentItem } from './helpers';
import { useDropdownState } from './hooks';

import styles from './nested-radio.module.scss';
import classNames from 'classnames';

const Placeholder = () => {
  return <div className={styles['placeholder']}>Select one</div>;
};

const ArrowIcon = () => {
  return (
    <div className={styles['arrow']}>
      <Icon name="caret-down" size="0.625rem" version="v3" />
    </div>
  );
};

export const NestedRadio = (props) => {
  const { name, options, value, onChange, className, hasError } = props;
  const buttonRef = useRef(null);
  const buttonClasses = classNames(
    styles['button'],
    {
      [styles['button--has-error']]: hasError,
    },
    className
  );

  const { dropdownOpen, toggleDropdownState, closeDropdown } =
    useDropdownState();

  const handleChange = (value) => {
    closeDropdown();
    onChange(value);
  };

  const getDropdownMinWidth = () => {
    if (buttonRef.current) return buttonRef.current.clientWidth;
    else return 'unset';
  };

  return (
    <>
      <button
        version="v3"
        type="button"
        name={name}
        ref={buttonRef}
        aria-haspopup={true}
        aria-expanded={dropdownOpen}
        onClick={toggleDropdownState}
        className={buttonClasses}
      >
        {value?.fullLabel || value?.label || <Placeholder />}
        <ArrowIcon />
      </button>
      <DropdownMenu
        version="v3"
        open={dropdownOpen}
        target={buttonRef}
        onClose={closeDropdown}
        minWidth={getDropdownMinWidth()}
      >
        {options.map((option) =>
          isParentItem(option) ? (
            <RadioGroup
              key={option.value}
              name={name}
              option={option}
              value={value}
              onChange={handleChange}
            />
          ) : (
            <SingleRadioButton
              key={option.value}
              name={name}
              option={option}
              value={value}
              onChange={handleChange}
            />
          )
        )}
      </DropdownMenu>
    </>
  );
};

const optionsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    children: PropTypes.array,
  })
);

NestedRadio.propTypes = {
  name: PropTypes.string,
  options: optionsPropTypes.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};
