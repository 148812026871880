import { useCallback } from 'react';
import { useBaseGet } from './base';

export const useProgramData = () => {
  const endpointUrl = '/program';

  const { baseGet, data, ...rest } = useBaseGet();

  const getProgramData = useCallback((id) => {
    baseGet(`${endpointUrl}/${id}`);
  }, []);

  return {
    getProgramData,
    data: data?.data,
    ...rest,
  };
};

export const useInstruments = () => {
  const endpointUrl = '/program';

  const { baseGet, data, ...rest } = useBaseGet();

  const getInstruments = useCallback((id) => {
    if (!id) return;
    baseGet(`${endpointUrl}/${id}/instrument`);
  }, []);

  return {
    getInstruments,
    data: data?.data,
    ...rest,
  };
};

export const useHoldings = () => {
  const endpointUrl = '/program';

  const { baseGet, data, ...rest } = useBaseGet();

  const getHoldings = useCallback((id) => {
    if (!id) return;
    baseGet(`${endpointUrl}/${id}/holding`);
  }, []);

  return {
    getHoldings,
    data: data?.data,
    ...rest,
  };
};

export const useChartData = () => {
  const { baseGet, data, ...rest } = useBaseGet();

  const getVamiChartData = useCallback((id) => {
    if (!id) return;
    baseGet(`/program/${id}/trackRecord/chart`);
  }, []);

  const getLifetimeReturnChartData = useCallback((id) => {
    if (!id) return;

    baseGet(`/program/${id}/trackRecord`.concat(encodeURI(`?page[size]=5000`)));
  }, []);

  const getYearlyReturnChartData = useCallback((id) => {
    if (!id) return;
    baseGet(`/program/${id}/trackRecord/last12Month`);
  }, []);

  return {
    getVamiChartData,
    getLifetimeReturnChartData,
    getYearlyReturnChartData,
    data: data?.data,
    ...rest,
  };
};
