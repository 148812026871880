import PropTypes from 'prop-types';
import { dataUnavailable } from '@core/formatters';
import { hasValue } from '@core/validators';
import { useUsOptions } from '@core/select-options';
import { getFlatList } from '@common/multiselect-dropdown';

const getMultiselectLabel = (value, options) => {
  const flatList = getFlatList(options);

  const option = flatList.find((option) => option.id === value);
  return option ? option.optionLabel : value;
};

export const StructModifiersFormatter = ({ value }) => {
  const { usOptions } = useUsOptions();

  if (!hasValue(value)) return dataUnavailable();

  return getMultiselectLabel(value, usOptions);
};

StructModifiersFormatter.propTypes = {
  value: PropTypes.string,
};
