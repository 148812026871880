import React from 'react';
import PropTypes from 'prop-types';
import { PROGRAM_TYPES } from '@core/constants';
import { SEARCH_FIELDS } from '@core/search-fields';
import { FormRadioInput } from '@common/form-inputs';
import { FiltersContainer } from '../filters-container';

const options = [
  {
    label: 'Both Hedge Funds and CTAs',
    value: '',
  },
  {
    label: 'Hedge Funds Only',
    value: PROGRAM_TYPES.HedgeFund,
  },
  {
    label: 'CTAs Only',
    value: PROGRAM_TYPES.CTA,
  },
];

export const TypeFilter = ({ control }) => {
  return (
    <FiltersContainer title={SEARCH_FIELDS.programType.label}>
      <FormRadioInput
        name={SEARCH_FIELDS.programType.id}
        control={control}
        options={options}
        defaultValue={SEARCH_FIELDS.programType.defaultValue}
      />
    </FiltersContainer>
  );
};

TypeFilter.propTypes = {
  control: PropTypes.object.isRequired,
};
