import React from 'react';
import PropTypes from 'prop-types';
import { SEARCH_FIELDS } from '@core/search-fields';
import { FormTextInput } from '@common/form-inputs';
import { FiltersContainer } from '../filters-container';
import styles from './name-filter.module.scss';

export const NameFilter = ({ control }) => {
  return (
    <FiltersContainer title={SEARCH_FIELDS.searchQuery.label}>
      <div className={styles['row']}>
        <div className={styles['col']}>
          <FormTextInput
            name={SEARCH_FIELDS.searchQuery.id}
            control={control}
            defaultValue={SEARCH_FIELDS.searchQuery.defaultValue}
            placeholder="Input fund or company"
            showConfirmationModal
            maxLength={128}
          />

          <FormTextInput
            label={SEARCH_FIELDS.isin.label}
            name={SEARCH_FIELDS.isin.id}
            control={control}
            defaultValue={SEARCH_FIELDS.isin.defaultValue}
            placeholder={SEARCH_FIELDS.isin.label}
            showConfirmationModal
            maxLength={128}
          />
        </div>
      </div>
    </FiltersContainer>
  );
};

NameFilter.propTypes = {
  control: PropTypes.object.isRequired,
};
