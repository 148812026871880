import PropTypes from 'prop-types';
import { dataUnavailable } from '@core/formatters';

export const DataUnavailableFormatter = ({ value }) => {
  return dataUnavailable(value);
};

DataUnavailableFormatter.propTypes = {
  value: PropTypes.node,
};
