import { COMPARISON_OPTIONS } from './comparisons';
import { GENERAL_FILTER_OPTIONS } from './general';
import { RETURN_OPTIONS } from './returns';

export const FILTER_OPTIONS = [
  {
    value: 'general',
    label: 'General',
    children: GENERAL_FILTER_OPTIONS,
  },
  {
    value: 'returns',
    label: 'Returns',
    children: RETURN_OPTIONS,
  },
  {
    value: 'comparisons',
    label: 'Comparisons',
    children: COMPARISON_OPTIONS,
  },
];

export { BENCHMARK_OPTIONS } from './benchmarks';
