import PropTypes from 'prop-types';
import { PROGRAM_TYPE_LABELS_SHORT } from '@core/constants';

export const ProgramTypeFormatter = ({ value }) => {
  return PROGRAM_TYPE_LABELS_SHORT[value];
};

ProgramTypeFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};
