import { useMemo, useState, useEffect } from 'react';
import {
  alwaysVisible,
  visibleByDefault,
  COLUMNS,
} from '~/components/search-results/columns';

import { useSaveFund } from './save-fund/hooks';
import { userFavoriteFundsSet } from '@redux/actions/user-data-actions';
import { useDispatch } from 'react-redux';

export const useHiddenColumns = () => {
  const cols = Object.keys(COLUMNS).map((col) => ({
    name: COLUMNS[col].value,
    label: COLUMNS[col].columnLabel,
  }));
  const dispatch = useDispatch();
  const hiddenByDefault = useMemo(() => {
    const visibleCols = [...visibleByDefault, ...alwaysVisible];
    return cols
      .filter((col) => !visibleCols.includes(col.name))
      .map((col) => col.name);
  }, [cols, visibleByDefault, alwaysVisible]);

  const [hiddenColumns, setHiddenColumns] = useState(hiddenByDefault);

  const { savedFundsData } = useSaveFund();

  useEffect(() => {
    //this is so we don't have to call for a refresh in each table row, this updates redux with the saved funds data and each row can pull from it
    if (savedFundsData?.length > 0) {
      dispatch(userFavoriteFundsSet(savedFundsData));
    }
  }, [savedFundsData]);

  return { hiddenColumns, setHiddenColumns };
};
