import { getDefaultValues } from '@core/search-fields';
import {
  getSessionStorage,
  removeSessionStorage,
  setSessionStorage,
} from './browser-storage';

const FORM_PARAMS = 'form-params';

export const setParams = (value) =>
  setSessionStorage(FORM_PARAMS, JSON.stringify(value));

export const removeParams = () => removeSessionStorage(FORM_PARAMS);

export const getParams = () => {
  const formParamsJson = getSessionStorage(FORM_PARAMS);
  return (
    (formParamsJson && JSON.parse(formParamsJson)) || {
      formParams: getDefaultValues(),
    }
  );
};
