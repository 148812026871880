import { FILTERS } from './search-filters';
import { FORMATTER_HOOKS } from './formatter-hooks';

export const SEARCH_FIELD_GROUPS = {
  BASIC: 'BASIC',
  DETAILED: 'DETAILED',
  CUSTOM: 'CUSTOM',
};

export const getDefaultValues = () => {
  const defaultValues = {};
  Object.keys(SEARCH_FIELDS)
    .filter((key) => key !== SEARCH_FIELDS.all.id)
    .forEach((key) => (defaultValues[key] = SEARCH_FIELDS[key].defaultValue));
  return defaultValues;
};

export const SEARCH_FIELDS = {
  all: {
    id: 'all',
    useFormatter: FORMATTER_HOOKS.all,
  },

  basicFiltersInclusion: {
    id: 'basicFiltersInclusion',
    label: 'Basic Filters',
    defaultValue: 'OR',
    chipDeleteDisabled: true,
    getFilter: FILTERS.basicFiltersInclusion,
    useFormatter: FORMATTER_HOOKS.basicFiltersInclusion,
  },

  detailedFiltersInclusion: {
    id: 'detailedFiltersInclusion',
    label: 'Detailed Filters',
    defaultValue: 'OR',
    chipDeleteDisabled: true,
    getFilter: FILTERS.detailedFiltersInclusion,
    useFormatter: FORMATTER_HOOKS.detailedFiltersInclusion,
  },

  customFiltersInclusion: {
    id: 'customFiltersInclusion',
    label: 'Custom Filters',
    defaultValue: 'OR',
    chipDeleteDisabled: true,
    getFilter: FILTERS.customFiltersInclusion,
    useFormatter: FORMATTER_HOOKS.customFiltersInclusion,
  },

  // Search by Type

  programType: {
    id: 'programType',
    label: 'Search by Type',
    defaultValue: '',
    getFilter: FILTERS.programType,
    useFormatter: FORMATTER_HOOKS.programType,
  },

  // Search by Name

  searchQuery: {
    id: 'searchQuery',
    label: 'Search by Name',
    defaultValue: '',
    getFilter: FILTERS.searchQuery,
    useFormatter: FORMATTER_HOOKS.searchQuery,
  },

  isin: {
    id: 'isin',
    label: 'ISIN #',
    defaultValue: '',
    getFilter: FILTERS.isin,
    useFormatter: FORMATTER_HOOKS.isin,
  },

  // Basic Filters

  compState: {
    id: 'compState',
    label: 'Management Company Location',
    defaultValue: [],
    getFilter: FILTERS.compState,
    useFormatter: FORMATTER_HOOKS.compState,
    group: SEARCH_FIELD_GROUPS.BASIC,
  },

  us: {
    id: 'us',
    label: 'Fund Structures Modifiers',
    defaultValue: [],
    getFilter: FILTERS.us,
    useFormatter: FORMATTER_HOOKS.us,
    group: SEARCH_FIELD_GROUPS.BASIC,
  },

  // Strategy Filters

  ctaStrategies: {
    id: 'ctaStrategies',
    label: 'CTA/Managed Futures Fund',
    defaultValue: [],
    getFilter: FILTERS.ctaStrategies,
    useFormatter: FORMATTER_HOOKS.ctaStrategies,
  },

  ctaInclusion: {
    id: 'ctaInclusion',
    defaultValue: 'OR',
    getFilter: FILTERS.ctaInclusion,
  },

  excludeProprietaryTradingAccounts: {
    id: 'excludeProprietaryTradingAccounts',
    label: 'Proprietary Trading Accounts',
    checkboxLabel: 'Exclude Proprietary Trading Accounts',
    defaultValue: false,
    getFilter: FILTERS.excludeProprietaryTradingAccounts,
    useFormatter: FORMATTER_HOOKS.excludeProprietaryTradingAccounts,
  },

  hfStrategies: {
    id: 'hfStrategies',
    label: 'Hedge Fund',
    defaultValue: [],
    getFilter: FILTERS.hfStrategies,
    useFormatter: FORMATTER_HOOKS.hfStrategies,
  },

  hfInclusion: {
    id: 'hfInclusion',
    defaultValue: 'OR',
    getFilter: FILTERS.hfInclusion,
  },

  excludeManagedAccounts: {
    id: 'excludeManagedAccounts',
    label: 'Managed Accounts',
    checkboxLabel: 'Exclude Managed Accounts',
    defaultValue: false,
    getFilter: FILTERS.excludeManagedAccounts,
    useFormatter: FORMATTER_HOOKS.excludeManagedAccounts,
  },

  sec13f: {
    id: 'sec13f',
    label: 'SEC 13F',
    checkboxLabel: 'Filed SEC 13F Form',
    defaultValue: false,
    getFilter: FILTERS.sec13f,
    useFormatter: FORMATTER_HOOKS.sec13f,
  },

  // Detailed Filters

  openClosed: {
    id: 'openClosed',
    label: 'Open/Closed Status',
    defaultValue: '',
    getFilter: FILTERS.openClosed,
    useFormatter: FORMATTER_HOOKS.openClosed,
    group: SEARCH_FIELD_GROUPS.DETAILED,
  },

  listedOnExchange: {
    id: 'listedOnExchange',
    label: 'Listed on Exchange',
    defaultValue: [],
    getFilter: FILTERS.listedOnExchange,
    useFormatter: FORMATTER_HOOKS.listedOnExchange,
    group: SEARCH_FIELD_GROUPS.DETAILED,
  },

  highwater: {
    id: 'highwater',
    label: 'High Water Mark',
    defaultValue: '',
    getFilter: FILTERS.highwater,
    useFormatter: FORMATTER_HOOKS.highwater,
    group: SEARCH_FIELD_GROUPS.DETAILED,
  },

  denomination: {
    id: 'denomination',
    label: 'Fund/Program Denomination',
    defaultValue: [],
    getFilter: FILTERS.denomination,
    useFormatter: FORMATTER_HOOKS.denomination,
    group: SEARCH_FIELD_GROUPS.DETAILED,
  },

  geoFocus: {
    id: 'geoFocus',
    label: 'Geographical Focus',
    defaultValue: [],
    getFilter: FILTERS.geoFocus,
    useFormatter: FORMATTER_HOOKS.geoFocus,
    group: SEARCH_FIELD_GROUPS.DETAILED,
  },

  reportingBasis: {
    id: 'reportingBasis',
    label: 'Reporting Basis',
    defaultValue: [],
    getFilter: FILTERS.reportingBasis,
    useFormatter: FORMATTER_HOOKS.reportingBasis,
    group: SEARCH_FIELD_GROUPS.DETAILED,
  },

  // Custom filters

  customFilters: {
    id: 'customFilters',
    label: '',
    defaultValue: [],
    getFilter: FILTERS.customFilters,
    useFormatter: FORMATTER_HOOKS.customFilters,
    group: SEARCH_FIELD_GROUPS.CUSTOM,
  },
};
