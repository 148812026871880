import { useCallback, useEffect } from 'react';
import { useBaseDelete, useBaseGet, useBasePost, useBasePut } from './base';
import {
  userSavedSearchesAdd,
  userSavedSearchesDelete,
  userSavedSearchesSet,
  userSavedSearchUpdate,
} from '@redux/actions/user-data-actions';
import { useDispatch } from 'react-redux';

export const useSaveSearch = () => {
  const { baseGet, data, loading, ...rest } = useBaseGet();

  const dispatch = useDispatch();

  const getSavedSearches = useCallback(() => {
    baseGet(`/favorite/search`);
  }, []);

  useEffect(() => {
    if (data?.data.length > 0) {
      dispatch(userSavedSearchesSet(data?.data));
    }
  }, [data]);

  const { basePost } = useBasePost();

  const saveSearch = useCallback((searchParams, saveName) => {
    dispatch(userSavedSearchesAdd({ name: saveName, params: searchParams }));
    basePost('/favorite/search', {
      data: { searchQuery: searchParams, searchName: saveName },
    });
  }, []);

  const { baseDelete } = useBaseDelete();

  const deleteSearch = useCallback((itemId) => {
    dispatch(userSavedSearchesDelete(itemId));
    baseDelete('/favorite/search/' + itemId);
  }, []);

  const { basePut } = useBasePut();

  const updateSearch = useCallback((params) => {
    dispatch(userSavedSearchUpdate(params.id, params.data));
    basePut('/favorite/search/' + params.id, { data: params.data });
  }, []);

  return {
    getSavedSearches,
    saveSearch,
    deleteSearch,
    updateSearch,
    loading,
    data: data?.data,
    ...rest,
  };
};
