import React from 'react';
import PropTypes from 'prop-types';
import { BasicTable } from '@common/basic-table';

export const TransferAgentInformation = ({ programData }) => {
  return (
    <BasicTable>
      <BasicTable.Title colSpan="2">
        Transfer Agent Information
      </BasicTable.Title>

      <BasicTable.Row>
        <BasicTable.Header>Firm</BasicTable.Header>
        <BasicTable.Cell>{programData.trAgentFirm}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Contact</BasicTable.Header>
        <BasicTable.Cell>{programData.trAgentContact}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Phone</BasicTable.Header>
        <BasicTable.Cell>{programData.trAgentPhone}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Fax</BasicTable.Header>
        <BasicTable.Cell>{programData.trAgentFax}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Address 1</BasicTable.Header>
        <BasicTable.Cell>{programData.trAgentAddress1}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Address 2</BasicTable.Header>
        <BasicTable.Cell>{programData.trAgentAddress2}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>City</BasicTable.Header>
        <BasicTable.Cell>{programData.trAgentCity}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>State/Province</BasicTable.Header>
        <BasicTable.Cell>{programData.trAgentState}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Zip Code</BasicTable.Header>
        <BasicTable.Cell>{programData.trAgentZip}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Country</BasicTable.Header>
        <BasicTable.Cell>{programData.trAgentCountry}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Email</BasicTable.Header>
        <BasicTable.Cell>{programData.trAgentEmail}</BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};

TransferAgentInformation.propTypes = {
  programData: PropTypes.object.isRequired,
};
