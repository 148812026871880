import React from 'react';
import PropTypes from 'prop-types';
import { TabBase } from '../tab-base';
import { RequiresProAccess, hasProAccess } from '../requires-pro-access';
import { AccountInformation } from './tables/account-information';
import { StrategiesFormatter } from '@core/formatters';

export const FundInfoTab = ({ programData }) => {
  if (!hasProAccess(programData)) return <RequiresProAccess />;

  return (
    <TabBase>
      <StrategiesFormatter row={programData} />
      <AccountInformation programData={programData} />
    </TabBase>
  );
};

FundInfoTab.propTypes = {
  programData: PropTypes.object.isRequired,
};
