import axios from 'axios';
import { logout } from '@redux/actions/login-actions';
import { SEARCH_FIELDS } from '@core/search-fields';
import { FILTER_TYPES, SORT_DIRECTIONS } from '@core/constants';
import { isEmpty } from '@core/validators';

// In a real app, would likely call an error logging service.
export function handleError(error, dispatch, navigate) {
  if (axios.isCancel(error)) throw error;
  const response = error.response;

  if (!response) {
    handleNoResponse(error, dispatch, navigate);
  } else if (response.status >= 300 && response.status < 400) {
    return response;
  } else if (response.status >= 400 && response.status < 500) {
    handle4XX(error, dispatch, navigate);
  } else {
    navigate('/500');
  }
}

const handleNoResponse = (error, dispatch, navigate) => {
  if (error.status && error.status === 401) {
    dispatch(logout());
    throw error;
  } else navigate('/500');
};

const handle4XX = (error, dispatch, navigate) => {
  const response = error.response;
  if (response.status === 401) {
    dispatch(logout());
    throw { status: 401, message: 'Unauthorized' };
  }
  if (response.status === 403 && response?.data?.errors[0] === 'AIFMD') {
    navigate('/aifmd');
    return;
  }
  if (response.status === 404 || response.status === 403) {
    navigate('/404');
    return;
  }

  throw {
    status: action.status,
    message: action.message,
  };
};

export const getSearchParams = (params = {}) => {
  const pageNum = params.pageNumber || 1;
  const pageSize = params.pageSize || 10;

  const sortString = getSortingString(params.sortedCols);

  const filter = getFilters(params);

  filter.dataUsage = { [FILTER_TYPES.equal]: 'ALL' };
  filter.active = { [FILTER_TYPES.equal]: '1' };
  filter.NAME = { JOIN: 'OR' };

  if (filter.aum) {
    for (const [key, value] of Object.entries(filter.aum)) {
      let val = value;
      filter.aum[key] = String(val * 1000000);
    }
  }

  return {
    filter,
    page: { number: pageNum, size: pageSize },
    sort: sortString,
  };
};

const getSortingString = (sortedCols) => {
  if (isEmpty(sortedCols)) return undefined;

  return sortedCols
    .map((col) =>
      col.direction === SORT_DIRECTIONS.ASC
        ? col.columnName
        : `-${col.columnName}`
    )
    .join(',');
};

const getFilters = (params) => {
  let filters = {};

  Object.keys(SEARCH_FIELDS).forEach((fieldId) => {
    if (!params[fieldId]) return;

    if (!SEARCH_FIELDS[fieldId].getFilter) return;

    filters = {
      ...filters,
      ...SEARCH_FIELDS[fieldId].getFilter(params),
    };
  });

  return filters;
};
