import React from 'react';
import PropTypes from 'prop-types';
import { DecimalPercentageFormatter } from '@core/formatters';
import { BasicTable } from '@common/basic-table';
import { getDateObj } from '@core/date-helper';

export const YearAnnualReturnsFum = ({ programData }) => {
  let currentYear = undefined;
  if (programData.endDate) {
    let convertedDate = getDateObj(programData.endDate);
    currentYear = convertedDate.getFullYear();
  } else {
    currentYear = new Date().getFullYear();
  }
  return (
    <BasicTable>
      <BasicTable.Title colSpan="2">Yearly Returns</BasicTable.Title>

      <BasicTable.Row>
        <BasicTable.Header>Year</BasicTable.Header>
        <BasicTable.Header>Annual Returns</BasicTable.Header>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Cell alignLeft>{currentYear}</BasicTable.Cell>
        <BasicTable.Cell alignLeft>
          <DecimalPercentageFormatter
            handleNegVals
            onlyNegHundred
            value={programData.yearToDate}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Cell alignLeft>{currentYear - 1}</BasicTable.Cell>
        <BasicTable.Cell alignLeft>
          <DecimalPercentageFormatter
            handleNegVals
            onlyNegHundred
            value={programData.return1Year}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Cell alignLeft>{currentYear - 2}</BasicTable.Cell>
        <BasicTable.Cell alignLeft>
          <DecimalPercentageFormatter
            handleNegVals
            onlyNegHundred
            value={programData.return2Year}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Cell alignLeft>{currentYear - 3}</BasicTable.Cell>
        <BasicTable.Cell alignLeft>
          <DecimalPercentageFormatter
            handleNegVals
            onlyNegHundred
            value={programData.return3Year}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Cell alignLeft>{currentYear - 4}</BasicTable.Cell>
        <BasicTable.Cell alignLeft>
          <DecimalPercentageFormatter
            handleNegVals
            onlyNegHundred
            value={programData.return4Year}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Cell alignLeft>{currentYear - 5}</BasicTable.Cell>
        <BasicTable.Cell alignLeft>
          <DecimalPercentageFormatter
            handleNegVals
            onlyNegHundred
            value={programData.return5Year}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};

YearAnnualReturnsFum.propTypes = {
  programData: PropTypes.object.isRequired,
};
