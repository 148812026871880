import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { windowSizeSet } from '@redux/actions/layout-actions';
import { getWindowSize } from '@core/layout';

const useWindowSizeTracking = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const dispatch = useDispatch();

  function handleResize() {
    setWindowSize(getWindowSize());
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => dispatch(windowSizeSet(windowSize)), [windowSize]);
};

export const WindowSizeTracker = () => {
  useWindowSizeTracking();
  return null;
};
