import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from '@backstop/react-core';

import styles from './confirmation-modal.module.scss';

export const ConfirmationModal = ({
  open,
  toggle,
  onConfirm,
  text,
  title,
  confirmButtonText = 'Confirm',
}) => {
  const handleConfirm = () => {
    onConfirm();
    toggle();
  };

  const footer = (
    <>
      <Button kind="primary" version="v3" onClick={handleConfirm}>
        {confirmButtonText}
      </Button>

      <Button
        kind="cancel"
        version="v3"
        onClick={toggle}
        className={styles['cancel']}
      >
        Cancel
      </Button>
    </>
  );

  return (
    <Modal
      version="v3"
      title={title}
      open={open}
      toggle={toggle}
      onRequestClose={toggle}
      closeOnOverlayClick={true}
      closeOnEscape={true}
      footer={footer}
    >
      {text}
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  text: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  confirmButtonText: PropTypes.string,
};
