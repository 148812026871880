import React from 'react';
import styles from './toolbar.module.scss';

export const Toolbar = ({ children, totalElements }) => {
  return (
    <div className={styles['body']}>
      <div className={styles['actions-wrapper']}>{children}</div>
      <div className={styles['total']}>
        <span className={styles['label']}>Total</span>
        <span>{totalElements}</span>
      </div>
    </div>
  );
};
