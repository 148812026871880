import React from 'react';
import PropTypes from 'prop-types';
import { DecimalPercentageFormatterNegativeHandler } from '@core/formatters';
import { BasicTable } from '@common/basic-table';

export const PortfolioTimeFrames = ({ programData }) => {
  return (
    <BasicTable>
      <BasicTable.Title colSpan="2">Portfolio Time Frames</BasicTable.Title>

      <BasicTable.Row>
        <BasicTable.Header>Short Time Frame</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.tfShort}
          />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Medium Time Frame</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.tfMedium}
          />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Long Time Frame</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.tfLong}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};

PortfolioTimeFrames.propTypes = {
  programData: PropTypes.object.isRequired,
};
