import { BasicTable } from '@common/basic-table';
import React from 'react';
import {
  DecimalPercentageFormatter,
  AwardsFumFormatter,
  ProgramNameFormatter,
} from '@core/formatters';

export const AwardsTable = ({ ranks, type }) => {
  const totalCols = 3;
  const widths = Array(totalCols).fill(`${100 / totalCols}%`);

  return (
    <>
      <BasicTable widths={widths}>
        <BasicTable.Row>
          <BasicTable.Header>Preliminary Rank</BasicTable.Header>
          <BasicTable.Header>Fund</BasicTable.Header>
          <BasicTable.Header>AUM</BasicTable.Header>
          <BasicTable.Header>ROR</BasicTable.Header>
        </BasicTable.Row>
        {ranks?.map((rank, index) => (
          <BasicTable.Row key={index}>
            <BasicTable.Cell alignLeft>{rank.rank}</BasicTable.Cell>

            <BasicTable.Cell alignLeft>
              <ProgramNameFormatter
                value={rank.name}
                row={{ id: rank.prog_id, ...rank }}
              />
            </BasicTable.Cell>

            <BasicTable.Cell alignLeft>
              <AwardsFumFormatter value={rank.fum} />
            </BasicTable.Cell>

            <BasicTable.Cell alignLeft>
              {type === 'bmfr' ? (
                <DecimalPercentageFormatter value={rank.return_for_period} />
              ) : (
                <DecimalPercentageFormatter value={rank.ror} />
              )}
            </BasicTable.Cell>
          </BasicTable.Row>
        ))}
      </BasicTable>
      <p>
        &copy; 2023 by Backstop Solutions Group, LLC - BarclayHedge
        (&ldquo;BarclayHedge&rdquo;). &nbsp;All Rights Reserved.
        &nbsp;BarclayHedge bases its ratings on information that: (1) is
        reported by content providers, without any independent verification by
        BarclayHedge; (2) includes unaudited and estimated performance returns
        and valuations; and (3) is not warranted to be accurate, complete or
        timely information. &nbsp;BarclayHedge does not sponsor, endorse, sell
        or promote any financial product, and BarclayHedge is not responsible
        for any losses arising from any use of this information. &nbsp;PAST
        PERFORMANCE IS NO GUARANTEE OF FUTURE RESULTS.
      </p>
    </>
  );
};
