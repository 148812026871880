import PropTypes from 'prop-types';
import {
  useCategories,
  usePrimaryCategoryConversion,
} from '@core/select-options';
import { dataUnavailable } from '@core/formatters';

export const CategoryFormatter = ({ value }) => {
  const { categories } = useCategories();
  const { primaryCategoryConversion } = usePrimaryCategoryConversion();
  let label = categories?.find((cat) => cat.value === value)?.label;
  if (label === undefined) {
    label = primaryCategoryConversion?.find(
      (cat) => cat.value.toLowerCase() === value
    )?.label;
  }
  return dataUnavailable(label || value);
};

CategoryFormatter.propTypes = {
  value: PropTypes.string,
};
