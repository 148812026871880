export const useAwardSelectOptions = () => {
  const getMonthOptions = (yearVal, typeVal) => {
    let months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    let quarters = [
      'First Quarter',
      'Second Quarter',
      'Third Quarter',
      'Fourth Quarter',
    ];
    let moReturn = [];
    let currYear = new Date().getFullYear();
    let currMonth = new Date().getMonth() + 1;
    if (typeVal === 'bmfr') {
      let j = 0;
      for (let i = 3; i <= 12; i += 3) {
        moReturn.push({ label: quarters[j], value: i, key: j });
        j++;
      }
    } else {
      //if we are the current year dont show the curr month or the prev month or months after current month)
      if (yearVal === currYear) {
        for (let i = 1; i <= currMonth - 2; i++) {
          moReturn.push({ label: months[i - 1], value: i, key: i });
        }
      } else {
        for (let i = 1; i <= 12; i++) {
          moReturn.push({ label: months[i - 1], value: i, key: i });
        }
      }
      moReturn.push({ label: 'Annual', value: 'NA' });
    }

    return moReturn;
  };

  const getYearOptions = () => {
    let years = [];
    for (let i = 2000; i <= new Date().getFullYear(); i++) {
      years.push({ label: String(i), value: i, key: i });
    }
    return years.reverse();
  };

  const getEntityOptions = () => {
    return [
      { label: 'Hedge Fund', value: 'hf' },
      { label: 'CTA', value: 'cta' },
      { label: 'BMFR', value: 'bmfr' },
    ];
  };

  const getDefaultYearValue = () => {
    const curr = new Date().getFullYear();
    return getYearOptions().filter((yr) => yr.value === curr)[0];
  };

  return {
    getMonthOptions,
    getYearOptions,
    getEntityOptions,
    getDefaultYearValue,
  };
};
