import { useEffect } from 'react';
import api from '@api';

export const usePrimaryCategoryConversion = () => {
  const { getPrimaryCategoryConversion, primaryCategoryConversion } =
    api.select.usePrimaryCategoryConversion();

  useEffect(() => {
    getPrimaryCategoryConversion();
  }, []);

  return { primaryCategoryConversion };
};
