import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './row.module.scss';

export const Row = ({ children, className }) => {
  const classes = classNames(styles['body'], className);

  return <tr className={classes}>{children}</tr>;
};

Row.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
