import React from 'react';
import { useLayoutData } from '@core/layout';
import { AdminToolbar } from './toolbar';
import styles from './app-header.module.scss';

export const AppHeader = () => {
  const { sidebarCollapsed, toggleSidebar } = useLayoutData();

  return (
    <header className={styles['body']}>
      <AdminToolbar
        onClick={toggleSidebar}
        isSidebarCollapsed={sidebarCollapsed}
      />
    </header>
  );
};
