export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const removeLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const getLocalStorage = (key) => {
  const value = localStorage.getItem(key);

  if (
    value === null ||
    value === undefined ||
    value === 'null' ||
    value === 'undefined'
  ) {
    return '';
  }
  return value;
};

export const setSessionStorage = (key, value) => {
  sessionStorage.setItem(key, value);
};

export const removeSessionStorage = (key) => {
  sessionStorage.removeItem(key);
};

export const getSessionStorage = (key) => {
  const value = sessionStorage.getItem(key);

  if (
    value === null ||
    value === undefined ||
    value === 'null' ||
    value === 'undefined'
  ) {
    return '';
  }
  return value;
};
