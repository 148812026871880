import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

/**
 * Generic chart. Give it two funds returns data and you get back a chart comparing the two as a col chart with overlaps and date formatted labels
 * @param {} param0
 * @returns
 */
export const CaptureChart = ({
  fundDataA,
  fundDataB,
  fundNameA,
  fundNameB,
  chartTitle,
}) => {
  let aChart = [];
  let dateCols = [];

  for (let i = 0; i < fundDataA.length; i++) {
    aChart.push([i, parseFloat(fundDataA[i].ror)]);
    dateCols.push(Date.parse(fundDataA[i].date));
  }

  let bChart = [];

  for (let i = 0; i < fundDataB.length; i++) {
    bChart.push([i, parseFloat(fundDataB[i].ror)]);
  }

  const options = {
    chart: {
      type: 'column',
    },
    title: {
      text: chartTitle,
      align: 'left',
    },
    xAxis: {
      categories: dateCols,
      labels: {
        formatter: function () {
          return Highcharts.dateFormat("%b '%y", this.value);
        },
      },
    },
    yAxis: [
      {
        title: {
          text: 'Rate of Return',
        },
      },
    ],
    tooltip: {
      formatter: function () {
        return `${this.y}`;
      },
    },
    legend: {
      shadow: false,
    },
    credits: { enabled: false },
    plotOptions: {
      column: {
        grouping: false,
        shadow: false,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: fundNameA,
        data: aChart,
        pointPadding: 0.1,
        pointPlacement: -0.2,
      },
      {
        name: fundNameB,
        data: bChart,
        pointPadding: 0.2,
        pointPlacement: -0.2,
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

CaptureChart.propTypes = {
  fundDataA: PropTypes.array.isRequired,
  fundNameA: PropTypes.string.isRequired,
  fundDataB: PropTypes.array.isRequired,
  fundNameB: PropTypes.string.isRequired,
  chartTitle: PropTypes.string.isRequired,
};
