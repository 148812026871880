import React from 'react';
import PropTypes from 'prop-types';
import { isNumeric } from '@core/validators';
import { FormDatePicker, FormTextInput } from '@common/form-inputs';

import styles from './filter-value-selectors.module.scss';

export const FilterValueInput = ({ control, name, defaultValue, isDate }) => {
  if (isDate) {
    return (
      <FormDatePicker
        control={control}
        name={name}
        defaultValue={defaultValue}
        max={new Date()}
        style={{ width: '100%' }}
        formControlClassName={styles['form-control']}
        defaultView={'decade'}
        views={['month', 'year', 'decade', 'century']}
        rules={{
          required: 'Field is required',
        }}
      />
    );
  } else {
    return (
      <FormTextInput
        control={control}
        name={name}
        defaultValue={defaultValue}
        placeholder="Input number"
        formControlClassName={styles['form-control']}
        className={styles['input']}
        maxLength={25}
        rules={{
          required: 'Field is required',
          validate: (value) => (!isNumeric(value) ? 'Must be a number' : null),
        }}
      />
    );
  }
};

FilterValueInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isDate: PropTypes.bool,
};
