import React from 'react';
import PropTypes from 'prop-types';
import { DecimalPercentageFormatterNegativeHandler } from '@core/formatters';
import { BasicTable } from '@common/basic-table';

export const PortfolioComposition = ({ programData }) => {
  return (
    <BasicTable>
      <BasicTable.Title colSpan="2">Portfolio Composition</BasicTable.Title>

      <BasicTable.Row>
        <BasicTable.Header>Base Metals</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.pcBasemet}
          />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Currency</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.pcCurrency}
          />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Energy</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.pcEnergy}
          />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Equity</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.pcEquity}
          />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Futures</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.pcFutures}
          />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Grains</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.pcGrains}
          />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Interest Rates</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.pcIntRate}
          />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Meats</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.pcMeats}
          />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Other (User Provided)</BasicTable.Header>
        <BasicTable.Cell>{programData.pcOtherSt}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Other %</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.pcOther}
          />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Precious Metals</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.pcPrecmet}
          />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Softs</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.pcSofts}
          />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Stock</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.pcSsf}
          />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Single Stock</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.pcStock}
          />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>VIX</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.pcVix}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};

PortfolioComposition.propTypes = {
  programData: PropTypes.object.isRequired,
};
