import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@backstop/react-core';
import styles from './button-controls.module.scss';

export const ButtonControls = ({ handleApply, handleCancel }) => {
  return (
    <div className={styles['body']}>
      <Button
        kind="primary"
        className={styles['apply-button']}
        version="v3"
        onClick={handleApply}
      >
        Apply
      </Button>
      <Button
        className={styles['cancel-button']}
        kind="secondary"
        onClick={handleCancel}
        version="v3"
      >
        Cancel
      </Button>
    </div>
  );
};

ButtonControls.propTypes = {
  handleApply: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};
