import React from 'react';
import PropTypes from 'prop-types';
import { BasicTable } from '@common/basic-table';
import {
  DateFormatter,
  DecimalFormatter,
  DecimalPercentageFormatter,
} from '@core/formatters';

export const PerformanceAnalysis = ({ programData }) => {
  return (
    <BasicTable>
      <BasicTable.Title colSpan="5">Performance Analysis</BasicTable.Title>
      <BasicTable.Row>
        <BasicTable.Header>Start Date</BasicTable.Header>
        <BasicTable.Cell>
          <DateFormatter value={programData.inceptionDate} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Total Return Since Inception</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatter value={programData.totalReturn} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Compound Annual Return</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatter
            value={programData.compoundAnnualReturn}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Skewness</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalFormatter value={programData.skewness} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Average Monthly RoR</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatter
            value={programData.averageMonthlyReturn}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Std. Deviation Monthly RoR</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatter value={programData.monthlyStandardDev} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Winning Months</BasicTable.Header>
        <BasicTable.Cell>{programData.winMonths}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Losing Months</BasicTable.Header>
        <BasicTable.Cell>{programData.loseMonths}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Avg. Gain</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatter value={programData.avgGain} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Avg. Loss</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatter value={programData.avgLoss} />
        </BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};

PerformanceAnalysis.propTypes = {
  programData: PropTypes.object.isRequired,
};
