import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Icon } from '@backstop/react-core';
import styles from './input-with-tooltip.module.scss';

export const InputWithTooltip = ({ children, tooltipText }) => {
  const targetRef = useRef();

  return (
    <div className={styles['body']}>
      {children}

      {tooltipText && (
        <>
          <div ref={targetRef} className={styles['tooltip-icon']}>
            <Icon version="v3" name="help-sign" size="0.875rem" />
          </div>
          <Tooltip
            version="v3"
            target={() => targetRef.current}
            triggers="hover focus click"
            orientation="left"
          >
            {tooltipText}
          </Tooltip>
        </>
      )}
    </div>
  );
};

InputWithTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  tooltipText: PropTypes.string,
};
