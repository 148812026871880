import { useMemo } from 'react';
import { HF_INCLUSION_OPTIONS } from './constants';

export const useHfIclusionOptions = (hfStrategyCount) => {
  return useMemo(
    () =>
      HF_INCLUSION_OPTIONS.map((option) =>
        option.value === 'AND'
          ? { ...option, disabled: hfStrategyCount > 2 }
          : option
      ),
    [hfStrategyCount]
  );
};
