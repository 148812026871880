import React from 'react';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import { PLACEHOLDER_SELECT_OPTION } from '@core/placeholder-select-option';
import { VALIDATORS } from '@core/validators';
import { FormSelect } from '@common/form-inputs';
import { COMPARISON_METHOD_OPTIONS, DATATYPES } from '../constants';
import { FilterValueInput } from './filter-value-input';

import styles from './filter-value-selectors.module.scss';

export const FilterValueSelectors = ({ control, filterToEdit }) => {
  const filter1 = useWatch({
    control,
    name: 'filter1',
    defaultValue: filterToEdit?.filter1 || undefined,
  });
  const isDate = filter1?.datatype === DATATYPES.date;

  const comparisonMethod = useWatch({
    control,
    name: 'comparisonMethod',
    defaultValue: filterToEdit?.comparisonMethod || undefined,
  });
  const isBetweenSelected = comparisonMethod?.value === 'between';

  return (
    <div className={styles['body']}>
      <FormSelect
        control={control}
        name="comparisonMethod"
        defaultValue={
          filterToEdit?.comparisonMethod || PLACEHOLDER_SELECT_OPTION
        }
        formControlClassName={styles['form-control']}
        className={styles['input']}
        options={COMPARISON_METHOD_OPTIONS}
        rules={{ validate: VALIDATORS.getRequiredOptionValidator() }}
      />

      <FilterValueInput
        control={control}
        name="value1"
        defaultValue={filterToEdit?.value1 || ''}
        isDate={isDate}
      />

      {isBetweenSelected && (
        <>
          <span className={styles['and']}>and</span>

          <FilterValueInput
            control={control}
            name="value2"
            defaultValue={filterToEdit?.value2 || ''}
            isDate={isDate}
          />
        </>
      )}
    </div>
  );
};

FilterValueSelectors.propTypes = {
  control: PropTypes.object.isRequired,
  filterToEdit: PropTypes.object,
};
