import PropTypes from 'prop-types';
import { useRedemptions } from '@core/select-options';
import { dataUnavailable } from '@core/formatters';

export const RedemptionsFormatter = ({ value }) => {
  const { redemptions } = useRedemptions();
  const label = redemptions?.find((option) => option.value === value)?.label;
  return dataUnavailable(label || value);
};

RedemptionsFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};
