import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

export const SidebarLink = ({ isOutsideLink, to, children, ...rest }) => {
  if (isOutsideLink) {
    return (
      <a href={to} {...rest}>
        {children}
      </a>
    );
  } else {
    return (
      <Link to={to} {...rest}>
        {children}
      </Link>
    );
  }
};

SidebarLink.propTypes = {
  isOutsideLink: PropTypes.bool.isRequired,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
