import PropTypes from 'prop-types';
import { dataUnavailable } from '@core/formatters';
import { isDate, isEmpty } from '@core/validators';

export const DateFormatter = ({ value }) => {
  if (isEmpty(value)) return dataUnavailable(value);

  if (!isDate(value)) return value;

  let badJsDate = new Date(value);

  return new Date(
    badJsDate.getTime() - badJsDate.getTimezoneOffset() * -60000
  ).toLocaleDateString('en-us', {
    month: 'long',
    year: 'numeric',
  });
};

DateFormatter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
