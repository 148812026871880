import React from 'react';
import PropTypes from 'prop-types';
import { TabBase } from '../tab-base';
import { RequiresProAccess, hasProAccess } from '../requires-pro-access';
import { AwardsImage } from './awards-image';
import styles from './awards-tab.module.scss';
import { useAwardsTab } from './hooks';
import { Loader } from '@common/loader';

export const AwardsTab = ({ programData }) => {
  if (!hasProAccess(programData)) return <RequiresProAccess />;

  const { data, loading } = useAwardsTab(programData.id);

  if (loading) {
    return <Loader />;
  }

  return (
    <TabBase>
      <div className={styles['awards-container']}>
        {data?.map((item, i) => (
          <AwardsImage
            key={i}
            rank={item.rank}
            size={'md'}
            name={programData.name}
            date={item.date}
            sector={item.sectorName}
          />
        ))}
      </div>
      <b>
        {programData.type === 'CTA'
          ? "This fund was ranked based on the data in BarclayHedge's Managed Futures database"
          : "This fund was ranked based on the data in BarclayHedge's Hedge Fund database"}
      </b>
    </TabBase>
  );
};

AwardsTab.propTypes = {
  programData: PropTypes.object.isRequired,
};
