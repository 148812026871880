import React from 'react';
import PropTypes from 'prop-types';
import { CheckBoxList, getSelectedLabels } from '@common/checkbox-list';
import { useFilterDropdownContext } from '@common/form-inputs/filter-dropdown-container';
import { useApplyOnEnter } from '../hooks';
import styles from './dropdown-multiselect-filter.module.scss';

export const getDisplayName = (options, value) => {
  const selectedLabels = getSelectedLabels(value, options);
  if (!selectedLabels || selectedLabels.length === 0)
    return {
      displayValue: undefined,
      fullValueString: undefined,
    };

  const count = selectedLabels.length;
  const joinedList = selectedLabels.join(', ');
  const displayValue = (
    <div className={styles['value-display']}>
      <div className={styles['value-list']}>{joinedList}</div>
      <div className={styles['count']}>({count})</div>
    </div>
  );

  return {
    displayValue,
    fullValueString: joinedList,
  };
};

export const DropdownMultiselectFilter = ({ options, ...restProps }) => {
  const { localValue, setLocalValue } = useFilterDropdownContext();
  useApplyOnEnter();

  return (
    <CheckBoxList
      items={options}
      selectedItems={localValue}
      onChange={setLocalValue}
      {...restProps}
    />
  );
};

DropdownMultiselectFilter.propTypes = {
  options: PropTypes.array.isRequired,
};
