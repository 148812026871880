import { DATATYPES } from '../constants';

export const GENERAL_FILTER_OPTIONS = [
  {
    value: 'annualStandardDev',
    label: 'Annual STD',
    datatype: DATATYPES.decimal,
  },
  {
    value: 'aum',
    label: 'Fund AUM',
    datatype: DATATYPES.money,
    tooltip:
      'This section allows you to filter matching Funds based on a range of acceptable AUMs. Note that you will want to specify whether your search is "inclusive" of your selected Minimum and Maximum values or "exclusive". Data is displayed in 1,000,000 units of currency (1 = 1M)',
  },
  {
    value: 'inceptionDate',
    label: 'Fund Inception Date',
    datatype: DATATYPES.date,
    tooltip:
      'This section allows you to filter matching Funds on the date of their inception. The interface allows you to specify a range of time denoting acceptable inception dates. Note that you will want to specify whether your search is "inclusive" of your selected Minimum and Maximum Dates or "exclusive"',
  },
  {
    value: 'incentiveFee',
    label: 'Incentive Fee',
    datatype: DATATYPES.percentage,
  },
  {
    value: 'leverage',
    label: 'Leverage',
    datatype: DATATYPES.decimal,
  },
  {
    value: 'managerAssets',
    label: 'Manager Assets',
    datatype: DATATYPES.money,
    tooltip:
      'This section allows you to filter matching Funds based on the total amount of money their management company manages. Note that you will want to specify whether your search is "inclusive" of your selected Minimum and Maximum values or "exclusive". Data is displayed in 1,000,000 units of currency (1 = 1M)',
  },
  {
    value: 'managementFee',
    label: 'Management Fee',
    datatype: DATATYPES.percentage,
  },
  {
    value: 'monthlyStandardDev',
    label: 'Monthly STD',
    datatype: DATATYPES.decimal,
  },
  {
    value: 'minAccount',
    label: 'Minimum Account',
    datatype: DATATYPES.money,
    tooltip:
      'This section allows you to filter matching Funds based on a range of acceptable "Minimum Account Values". Note that you will want to specify whether your search is "inclusive" of your selected Minimum and Maximum values or "exclusive". Data is displayed in 1000 units of currency (1 = 1K)',
  },
  {
    value: 'skewness',
    label: 'Skewness',
    datatype: DATATYPES.decimal,
  },
  {
    value: 'sharpeRatio',
    label: 'Sharpe Ratio',
    datatype: DATATYPES.decimal,
  },
  {
    value: 'calmarRatio',
    label: 'Calmar Ratio',
    datatype: DATATYPES.decimal,
  },
  {
    value: 'sortinoRatio',
    label: 'Sortino Ratio',
    datatype: DATATYPES.decimal,
  },
  {
    value: 'worstDrawDown',
    label: 'Worst Drawdown',
    datatype: DATATYPES.percentage,
  },
];
