import PropTypes from 'prop-types';
import { useSubscriptions } from '@core/select-options';
import { dataUnavailable } from '@core/formatters';

export const SubscriptionsFormatter = ({ value }) => {
  const { subscriptions } = useSubscriptions();
  const label = subscriptions?.find((option) => option.value === value)?.label;
  return dataUnavailable(label || value);
};

SubscriptionsFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};
