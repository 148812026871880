import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@backstop/react-core';
import api from '@api';
import styles from './download-button.module.scss';

export const DownloadButton = ({ product }) => {
  const { downloadToken, token } = api.products.useFileDownloadToken();

  useEffect(() => {
    if (token) {
      window.open(
        process.env.REACT_APP_BARCLAYHEDGE_API_URL + token.tokenUrl,
        '_blank'
      );
    }
  }, [token]);

  return (
    <Button
      version="v3"
      type="submit"
      kind="secondary"
      onClick={() => downloadToken(product.releaseName)}
    >
      <Icon name={'download'} className={styles['icon']} />
      <div>{product.productShortName}</div>
    </Button>
  );
};

DownloadButton.propTypes = {
  product: PropTypes.object.isRequired,
};
