import api from '@api';
import { useEffect } from 'react';

/**
 * This is the hook file for the save fund dropdown
 * The useeffect runs each time the dropdown is opened so it stays up to date
 * @returns functions and data related to saving funds
 */
export const useSaveFund = () => {
  const { saveFund, deleteSavedFund, getSavedFunds, data, loading } =
    api.saveFund.useSaveFund();

  useEffect(() => {
    getSavedFunds();
  }, []);

  return {
    saveFund,
    deleteSavedFund,
    savedFundsData: data,
    loading,
    getSavedFunds,
  };
};
