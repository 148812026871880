import { alwaysVisible, COLUMNS } from '~/components/search-results/columns';

export const getSelected = (hiddenColumnNames) => {
  const hiddenColumnNamesMap = hiddenColumnNames.reduce(
    (map, name) => (map[name] = true) && map,
    {}
  );

  return Object.keys(COLUMNS)
    .filter(
      (colName) =>
        !hiddenColumnNamesMap[colName] && !alwaysVisible.includes(colName)
    )
    .map((colName) => COLUMNS[colName]);
};

/**
 * Given the params, return a list of hidden table cols
 * @param {*} visible
 * @returns
 */
export const getHidden = (visible) => {
  const visibleById = visible.reduce(
    (map, item) => (map[item.value] = true) && map,
    {}
  );

  return Object.keys(COLUMNS).filter(
    (columnName) =>
      !visibleById[columnName] && !alwaysVisible.includes(columnName)
  );
};
