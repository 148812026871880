import React from 'react';
import PropTypes from 'prop-types';
import { FormControl as BsgFormControl } from '@backstop/react-core';

import styles from './form-control.module.scss';
import classNames from 'classnames';

export const FormControl = ({ children, className, ...rest }) => {
  const classes = classNames(styles['label'], className);
  return (
    <BsgFormControl className={classes} {...rest}>
      {children}
    </BsgFormControl>
  );
};

FormControl.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
