import React from 'react';
import PropTypes from 'prop-types';
import {
  DecimalPercentageFormatter,
  MoneyThousandsFormatter,
  BasicNumberFormatter,
  StrategiesFormatter,
  MoneyMillionsFormatter,
} from '@core/formatters';
import { BasicTable } from '@common/basic-table';

export const AccountVehicleInformation = ({ programData }) => {
  return (
    <BasicTable>
      <BasicTable.Title colSpan="2">
        Account/Vehicle Information
      </BasicTable.Title>
      <BasicTable.Row>
        <BasicTable.Header>Fund AUM</BasicTable.Header>
        <BasicTable.Cell>
          <MoneyMillionsFormatter value={programData.aum} row={programData} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Strategy</BasicTable.Header>
        <BasicTable.Cell>
          <StrategiesFormatter row={programData} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Management Fee</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatter value={programData.managementFee} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Incentive Fee</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatter value={programData.incentiveFee} />
        </BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>Minimum Account</BasicTable.Header>
        <BasicTable.Cell>
          <MoneyThousandsFormatter
            value={programData.minAccount}
            row={programData}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
      {programData.type === 'CTA' ? (
        <BasicTable.Row>
          <BasicTable.Header>Round Turns/Year/Million</BasicTable.Header>
          <BasicTable.Cell>
            <BasicNumberFormatter value={programData.rtYrMil} handleNegVals />
          </BasicTable.Cell>
        </BasicTable.Row>
      ) : null}
      <BasicTable.Row>
        <BasicTable.Header>Accepting New Investors</BasicTable.Header>
        <BasicTable.Cell>{programData.open}</BasicTable.Cell>
      </BasicTable.Row>
      <BasicTable.Row>
        <BasicTable.Header>High Water Mark</BasicTable.Header>
        <BasicTable.Cell>{programData.highwater}</BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};

AccountVehicleInformation.propTypes = {
  programData: PropTypes.object.isRequired,
};
