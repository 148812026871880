import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@backstop/react-core';

import styles from './register-now.module.scss';

export const RegisterNow = () => {
  const navigate = useNavigate();

  const navigateToRegister = () => {
    navigate('/register');
  };

  return (
    <>
      <span className={styles['not-a-member']}>Not a member yet?</span>
      <Button version="v3" kind="primary" onClick={navigateToRegister}>
        Register for Free Now!
      </Button>
    </>
  );
};
