import React from 'react';
import PropTypes from 'prop-types';
import { RequiresProAccess, hasProAccess } from '../requires-pro-access';
import { TabBase } from '../tab-base';
import { AnnualizedReturns } from './tables/annualized-returns';
import { CalendarYearReturns } from './tables/calendar-year-returns';
import { CumulativeReturns } from './tables/cumulative-returns';
import styles from './performance-tab.module.scss';
import { LifeTimeReturnsChart } from './charts/lifetime-returns-chart';
import { TwelveMonthReturnsChart } from './charts/twelve-month-returns-chart';

export const PerformanceTab = ({ programData }) => {
  if (!hasProAccess(programData)) return <RequiresProAccess />;

  return (
    <TabBase>
      <LifeTimeReturnsChart programData={programData} />
      <TwelveMonthReturnsChart programData={programData} />
      <CalendarYearReturns programData={programData} />
      <div className={styles['row']}>
        <CumulativeReturns programData={programData} />
        <AnnualizedReturns programData={programData} />
      </div>
    </TabBase>
  );
};

PerformanceTab.propTypes = {
  programData: PropTypes.object.isRequired,
};
