import { useCallback, useEffect } from 'react';
import { getSearchParams } from './apiUtils';
import { useBasePost } from './base';

export const usePragramSearch = () => {
  const endpointUrl = '/program/search';

  const { basePost, data, ...rest } = useBasePost();

  const getPrograms = useCallback((params) => {
    const { formParams, ...restParams } = params;
    const searchParams = {
      ...restParams,
      ...formParams,
    };
    const body = getSearchParams(searchParams);
    basePost(endpointUrl, body);
  }, []);

  return {
    getPrograms,
    data: data?.data,
    totalElements: data?.meta.totalElements || 0,
    ...rest,
  };
};

export const useSearchData = ({
  formParams,
  pageSize,
  currentPage,
  sortedCols,
}) => {
  const { getPrograms, data, totalElements, loading, error } =
    usePragramSearch();

  useEffect(() => {
    getPrograms({
      sortedCols,
      pageSize,
      pageNumber: currentPage,
      formParams,
    });
  }, [sortedCols, pageSize, currentPage, formParams]);

  return { data, totalElements, loading, error };
};
