// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button--C4suZ{width:100%;margin-top:10px}.button--C4suZ .text--x8MX0{width:100%}", "",{"version":3,"sources":["webpack://./src/components/auth/reset-password/reset-password-form/reset-password-form.module.scss"],"names":[],"mappings":"AAAA,eACE,UAAA,CACA,eAAA,CAEA,4BACE,UAAA","sourcesContent":[".button {\n  width: 100%;\n  margin-top: 10px;\n\n  .text {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "button--C4suZ",
	"text": "text--x8MX0"
};
export default ___CSS_LOADER_EXPORT___;
