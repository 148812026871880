// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".body--KDjt0{font-size:14px;padding:8px 18px;text-align:right}.body--border-right--C4Gdx{border-right:1px solid #ddd}.body--align-left--I0mTe{text-align:left}.body--align-center--Iitkk{text-align:center}.body--align-right--FeujW{text-align:right}", "",{"version":3,"sources":["webpack://./src/components/common/basic-table/cell/cell.module.scss"],"names":[],"mappings":"AAEA,aACE,cAAA,CACA,gBAAA,CACA,gBAAA,CAEA,2BACE,2BAAA,CAGF,yBACE,eAAA,CAGF,2BACE,iBAAA,CAGF,0BACE,gBAAA","sourcesContent":["@import './src/theme/_variables';\n\n.body {\n  font-size: 14px;\n  padding: 8px 18px;\n  text-align: right;\n\n  &--border-right {\n    border-right: 1px solid $color-border-basic-table;\n  }\n\n  &--align-left {\n    text-align: left;\n  }\n\n  &--align-center {\n    text-align: center;\n  }\n\n  &--align-right {\n    text-align: right;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "body--KDjt0",
	"body--border-right": "body--border-right--C4Gdx",
	"body--align-left": "body--align-left--I0mTe",
	"body--align-center": "body--align-center--Iitkk",
	"body--align-right": "body--align-right--FeujW"
};
export default ___CSS_LOADER_EXPORT___;
