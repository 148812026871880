import React from 'react';
import PropTypes from 'prop-types';
import { BasicTable } from '@common/basic-table';
import { DecimalPercentageFormatter } from '@core/formatters';

export const TimeWindows = ({ programData }) => {
  return (
    <BasicTable>
      <BasicTable.Title colSpan="4">
        Best & Worst Performing Months
      </BasicTable.Title>
      <BasicTable.Row>
        <BasicTable.Header>Trailing Period (Mos.)</BasicTable.Header>
        <BasicTable.Header>Best</BasicTable.Header>
        <BasicTable.Header>Worst</BasicTable.Header>
        <BasicTable.Header>Average</BasicTable.Header>
      </BasicTable.Row>
      <TimeWindowXMonth months={3} programData={programData} />
      <TimeWindowXMonth months={6} programData={programData} />
      <TimeWindowXMonth months={9} programData={programData} />
      <TimeWindowXMonth months={12} programData={programData} />
      <TimeWindowXMonth months={18} programData={programData} />
      <TimeWindowXMonth months={24} programData={programData} />
    </BasicTable>
  );
};

const TimeWindowXMonth = ({ months, programData }) => {
  return (
    <BasicTable.Row>
      <BasicTable.Cell alignLeft>{months}</BasicTable.Cell>
      <BasicTable.Cell alignLeft>
        <DecimalPercentageFormatter
          value={programData['bestCompoundReturn' + months + 'Months']}
        />
      </BasicTable.Cell>
      <BasicTable.Cell alignLeft>
        <DecimalPercentageFormatter
          value={programData['worstCompoundReturn' + months + 'Months']}
        />
      </BasicTable.Cell>
      <BasicTable.Cell alignLeft>
        <DecimalPercentageFormatter
          value={programData['avgCompoundReturn' + months + 'Months']}
        />
      </BasicTable.Cell>
    </BasicTable.Row>
  );
};

TimeWindows.propTypes = {
  programData: PropTypes.object.isRequired,
};
