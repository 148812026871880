import * as types from './action-types';

export function windowSizeSet(value) {
  return {
    type: types.WINDOW_SIZE_SET,
    value,
  };
}

export function sidebarCollapsedSet(value) {
  return {
    type: types.SIDEBAR_COLLAPSED_SET,
    value,
  };
}

export function sidebarCollapsedToggle() {
  return {
    type: types.SIDEBAR_COLLAPSED_TOGGLE,
  };
}
