// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".body--rjLUh{width:100%}.body--rjLUh .toolbar--wGduK{flex:1;width:100px;height:67px}", "",{"version":3,"sources":["webpack://./src/components/headers/app-header/app-header.module.scss"],"names":[],"mappings":"AAEA,aACE,UAAA,CAEA,6BACE,MAAA,CACA,WAAA,CACA,WAAA","sourcesContent":["@import './src/theme/_variables';\n\n.body {\n  width: 100%;\n\n  .toolbar {\n    flex: 1;\n    width: 100px;\n    height: 67px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "body--rjLUh",
	"toolbar": "toolbar--wGduK"
};
export default ___CSS_LOADER_EXPORT___;
