import { WINDOW_SIZES, WINDOW_SIZE_BREAKPOINTS } from '@core/constants';

export const getWindowSize = () => {
  if (window.innerWidth >= WINDOW_SIZE_BREAKPOINTS[WINDOW_SIZES.LG])
    return WINDOW_SIZES.LG;
  if (window.innerWidth >= WINDOW_SIZE_BREAKPOINTS[WINDOW_SIZES.MD])
    return WINDOW_SIZES.MD;
  if (window.innerWidth >= WINDOW_SIZE_BREAKPOINTS[WINDOW_SIZES.SM])
    return WINDOW_SIZES.SM;
  else return WINDOW_SIZES.XS;
};
