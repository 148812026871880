import React from 'react';
import PropTypes from 'prop-types';
import { DecimalPercentageFormatterNegativeHandler } from '@core/formatters';
import { BasicTable } from '@common/basic-table';

export const GeographicAllocation = ({ programData }) => {
  return (
    <BasicTable>
      <BasicTable.Title colSpan="2">Geographic Allocation</BasicTable.Title>

      <BasicTable.Row>
        <BasicTable.Header>Geographical Focus</BasicTable.Header>
        <BasicTable.Cell>{programData.geoFocus}</BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>North America</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.amerPa}
          />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Western Europe</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.weurPa}
          />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Eastern Europe</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.eeurPa}
          />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Pacific Rim</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.pacifPa}
          />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Latin America</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.latamPa}
          />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>MENA</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.menaPa}
          />
        </BasicTable.Cell>
      </BasicTable.Row>

      <BasicTable.Row>
        <BasicTable.Header>Other</BasicTable.Header>
        <BasicTable.Cell>
          <DecimalPercentageFormatterNegativeHandler
            value={programData.otherPa}
          />
        </BasicTable.Cell>
      </BasicTable.Row>
    </BasicTable>
  );
};

GeographicAllocation.propTypes = {
  programData: PropTypes.object.isRequired,
};
