import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './header.module.scss';

export const Header = ({
  children,
  hasBorderRight,
  alignLeft,
  alignCenter,
  alignRight,
  className,
  ...restProps
}) => {
  const classes = classNames(
    styles['body'],
    {
      [styles['body--border-right']]: hasBorderRight,
      [styles['body--align-left']]: alignLeft,
      [styles['body--align-center']]: alignCenter,
      [styles['body--align-right']]: alignRight,
    },
    className
  );

  return (
    <th {...restProps} className={classes}>
      {children}
    </th>
  );
};

Header.propTypes = {
  children: PropTypes.node.isRequired,
  hasBorderRight: PropTypes.bool,
  alignLeft: PropTypes.bool,
  alignCenter: PropTypes.bool,
  alignRight: PropTypes.bool,
  className: PropTypes.string,
};
