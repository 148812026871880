import PropTypes from 'prop-types';
import { dataUnavailable, toPercent, toDecimal } from '@core/formatters';

/**
 *
 * @param {} value the value to be translated
 * @param {} handleNegVals should we convert -1, -2 to No,Yes
 * @returns
 */
export const DecimalPercentageFormatter = ({
  value,
  handleNegVals,
  onlyNegHundred,
}) => {
  if (value == 'Yes' || value == 'No') {
    return value;
  }

  if (handleNegVals) {
    if (!onlyNegHundred) {
      if (parseInt(value, 10) === -1) {
        return 'No';
      }
      if (parseInt(value, 10) === -2) {
        return 'Yes';
      }
    }
    if (parseInt(value, 10) === -100) {
      return 'N/A';
    }
    if (parseInt(value, 10) === -101) {
      return 'N/A';
    }
  }

  return dataUnavailable(toPercent(toDecimal(value)));
};

DecimalPercentageFormatter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleNegVals: PropTypes.bool,
  onlyNegHundred: PropTypes.bool,
};
