import initialState from './initial-state';
import * as types from '../actions/action-types';

export default function userDataReducer(state = initialState.userData, action) {
  switch (action.type) {
    case types.USER_FAVORITE_FUNDS_SET:
      return {
        ...state,
        savedFunds: action.data,
      };

    case types.USER_FAVORITE_FUNDS_DELETE:
      let temp = state.savedFunds.filter(
        (item) => String(item.fundId) !== String(action.deleteId)
      );
      return {
        ...state,
        savedFunds: temp,
      };

    case types.USER_FAVORITE_FUNDS_ADD:
      let tempAdd = [
        ...state.savedFunds,
        { ...action.data, fundId: action.data.id },
      ];
      return {
        ...state,
        savedFunds: tempAdd,
      };

    case types.USER_SAVE_SEARCH_SET:
      return {
        ...state,
        savedSearches: action.data,
      };

    case types.USER_SAVE_SEARCH_DELETE:
      let tempSaveSearch = state.savedSearches.filter(
        (item) => String(item.id) !== String(action.searchId)
      );
      return {
        ...state,
        savedSearches: tempSaveSearch,
      };

    case types.USER_SAVE_SEARCH_ADD:
      let tempAddSearch = [...state.savedSearches, { ...action.data }];
      return {
        ...state,
        savedSearches: tempAddSearch,
      };

    case types.USER_SAVE_SEARCH_UPDATE:
      let tempUpdate = state.savedSearches.filter(
        (item) => String(item.id) !== String(action.id)
      );
      return {
        ...state,
        savedSearches: [...tempUpdate, { id: action.id, ...action.data }],
      };

    case types.USER_DATA_SET:
      return {
        ...state,
        ...action.data,
      };

    case types.USER_DATA_LOADING_SET:
      return {
        ...state,
        loading: action.loading,
      };

    case types.USER_ROLES_SET:
      return {
        ...state,
        roles: action.roles,
      };

    case types.USER_DATA_CLEAN:
      return {
        ...initialState.userData,
      };

    default:
      return state;
  }
}
