export const HF_INCLUSION_TOOLTIP_TEXT =
  'Each fund has at most two strategies, so only two strategies may be selected when matching all selected strategies';

export const HF_INCLUSION_OPTIONS = [
  {
    value: 'OR',
    label: 'Match any selected Hedge Fund strategy',
  },
  {
    value: 'AND',
    label: 'Match all selected Hedge Fund strategies',
  },
  {
    value: 'PRIMARY',
    label: 'Only Search Primary Strategy',
  },
];

export const CTA_INCLUSION_OPTIONS = [
  {
    value: 'OR',
    label: 'Match any selected CTA/Managed Futures Fund strategies',
  },
  {
    value: 'AND',
    label: 'Match all selected CTA/Managed Futures Fund strategies',
  },
];
