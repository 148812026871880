import PropTypes from 'prop-types';
import {
  toCurrency,
  dataUnavailable,
  toMillions,
  toNumber,
} from '@core/formatters';
import { isEmpty, isNumeric } from '@core/validators';

export const MoneyMillionsFormatter = ({ value, row }) => {
  if (isEmpty(value) || !isNumeric(value)) return dataUnavailable(value);

  if (parseFloat(value) === 0) return toCurrency(value, row.denominat);

  let valueInMillions = parseFloat(value) / 1000000;

  if (valueInMillions < 1) {
    valueInMillions = '<1';
  } else {
    valueInMillions = toNumber(valueInMillions, 0);
  }

  return dataUnavailable(
    toMillions(toCurrency(valueInMillions, row.denominat))
  );
};

MoneyMillionsFormatter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  row: PropTypes.object.isRequired,
};
